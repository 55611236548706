import React, { useState } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const BestCryptoExchange = (props) => {

  const t = useTranslation("best_crypto");

  return (
    <>
      <div className="best-crypto-wrapped sm-padding">
        <Container fluid>
          <Row>
          <Col md={6} lg={6} xl={5}>
              <div className="best-crypto-step-title ">
              <h2 data-aos="fade-zoom-in">How Does <span>HeraldEX Work?</span></h2>
              <p>Sign up, complete KYC, deposit USD, and start buying, selling, swapping, and trading crypto securely within your HeraldEX wallet.</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">
              <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-one" >
                <div className="num-bold" ><span>01</span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/signup-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Sign Up</h4>
                </div>
                <p>Register with your email, create a secure password, and verify your email ID. Enable two-factor authentication for extra protection.</p>
              </div>
              </div>
            </Col>

            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-five">
                <div className="num-bold"><span> 02 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/complete-kyc.svg"
                    }
                    type="image/png"
                  />
                <h4>Complete KYC</h4>
                </div>
                <p>Initiate onboarding by submitting the required documents. Verify your identity to unlock full platform access.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">

            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-two">
                <div className="num-bold"> <span> 03 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/deposit-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Deposit Funds</h4>
                </div>
                <p>Add USD to your HeraldEX wallet using supported payment methods. Your funds are ready to use instantly.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-three">
                <div className="num-bold"><span> 04 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/buy-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Buy Crypto</h4>
                </div>
                <p>Purchase cryptocurrencies using your USD balance. The crypto is securely deposited into your wallet.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-four">
                <div className="num-bold"><span> 05 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/sell-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Sell Crypto</h4>
                </div>
                <p>Sell your crypto and convert it into local currencies across 49 countries. The funds are deposited into your wallet, which you can then withdraw to your bank account.</p>
              </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-five">
                <div className="num-bold"><span> 05 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/swap-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Swap Cryptocurrencies</h4>
                </div>
                <p>Looking to switch between different cryptocurrencies? Use our swap feature to instantly exchange one crypto for another. Just pick the currencies you want to swap, confirm the amount, and you’re good to go.</p>
              </div>
              </div>
            </Col> */}
            {/* <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-six">
                <div className="num-bold"><span> 06 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/trade-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Trade Crypto</h4>
                </div>
                <p>For those who want to take their crypto journey to the next level, explore our trading platform. Buy, sell, and manage your crypto assets in real time with advanced trading tools and competitive fees.</p>
              </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BestCryptoExchange;
