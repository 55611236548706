import React, { useState } from "react";
import { Container, Button, Row, Form, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactUsPageIndex = (props) => {
  return (
    <>
      <div className="contact-us-sec sm-padding">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={12} xl={6}>
              <div className="contact-us-form-box">
                <h3>Contact Us</h3>
                <Form className="contact-us-form" method="post">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                  <div className="contact-us-submit-btn-sec">
                    <Button className="new-default-primary-btn w-100">
                      Submit
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="#171717" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"></path></svg>
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="contact-us-img-sec">
                <Image
                  className="contact-us-img"
                  src={
                    window.location.origin +
                    "/img/gif/contact.gif"
                  }
                  type="image/png"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUsPageIndex;
