import React from "react";
import { Container } from "react-bootstrap";
import KycManual from "./KycManual";

const BusinessIndex = () => {

  return (
    <>
      <div className="business-wrapped">
        <Container fluid>
          <KycManual />
        </Container>
      </div>
    </>
  );
};

export default BusinessIndex;
