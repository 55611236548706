import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-multi-lang';
import { tokenSwapStart } from "../../store/actions/BuyAction";
import { fetchWalletDetailsStart } from '../../store/actions/WalletAction';
import CommonCenterLoader from "../helper/CommonCenterLoader";
import NoDataFound from "../helper/NoDataFound";

const SwapModal = (props) => {

  const t = useTranslation("swap_modal")
  const dispatch = useDispatch();
  const tokenSwap = useSelector((state) => state.buy.tokenSwap);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [skipRender, setSkipRender] = useState(true);
  const [filteredData, setFilteredData] = useState({});
  const [walletData, setWalletData] = useState({});

  const validationSchema = Yup.object().shape({
    value: Yup.number()
      .required(t("amount.required"))
      .min(1, t("amount.invalid")),
    to_wallet_id: Yup.string().required(t("to_wallet.required")),
  });

  const handleSubmit = (values) => {
    dispatch(tokenSwapStart({
      ...values,
      from_wallet_id: props.user_wallet_id,
      user_wallet_id: props.user_wallet_id,
    }))
  };

  useEffect(() => {
    dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    if (!skipRender &&
      !tokenSwap.loading &&
      Object.keys(tokenSwap.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [tokenSwap]);


  useEffect(() => {
    if (!skipRender && !wallet.loading && Object.keys(wallet.data).length > 0) {
      setWalletData(wallet.data.user_wallets.filter((item) => item.id == props.user_wallet_id)[0])
    }
    setSkipRender(false);
  }, [wallet]);

  return (
    <>

<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>   {t("headng")}</h4>
        {wallet.loading ?
          <CommonCenterLoader />
          : Object.keys(wallet.data).length > 0 &&
            wallet.data.user_wallets.length > 0
            ?
            <>
              <Formik
                initialValues={{
                  value: "",
                  to_wallet_id: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, setFieldValue, values }) => (
                  <FORM className="new-feature-form">
                      <div className="auth-form-box">
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("amount.label")} {walletData.currency_code}<span>*</span>
                          </Form.Label>
                          <Field
                            type="number"
                            name="value"
                            placeholder={t("amount.placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="value"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("token_name")}
                          </Form.Label>
                          <Field
                            className="form-control text-capitalize"
                            type="text"
                            name="from_wallet_id"
                            value={walletData.currency_type}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("to_wallet.label")}
                            <span>*</span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="to_wallet_id"
                            onChange={(e) => {
                              setFieldValue("to_wallet_id", e.target.value)
                              setFilteredData(wallet.data.user_wallets.find((item) => item.id == e.target.value))
                            }}
                          >
                            <option selected disabled>{t("to_wallet.placeholder")}</option>
                            {wallet.data.user_wallets.map(
                              (wallet, i) => (
                                wallet.id != props.user_wallet_id &&
                                <option key={i} value={wallet.id}>
                                  {wallet.currency_code}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <ErrorMessage
                            component={"div"}
                            name="to_wallet_id"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("exchange_value")} {filteredData.currency_code ?
                              <span>({filteredData.currency_code})</span> : null}
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="exchange_value"
                            placeholder="0"
                            value={filteredData.exchange_rate}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("comission")} {filteredData.currency_code ?
                              <span>({filteredData.currency_code})</span> : null}
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="number"
                            name="comission_amount"
                            placeholder="0"
                            value={values.value * (1 / walletData.exchange_rate) * filteredData.exchange_rate}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("user_receive")} {filteredData.currency_code ?
                              <span>({filteredData.currency_code})</span> : null}
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="number"
                            name="comission_amount"
                            placeholder="0"
                            value={values.value - (values.value *
                              filteredData.exchange_rate *
                              filteredData.swap_commission / 100)}
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="new-action-frame layout-element">
                      <Button
                        className="new-overlay-btn"
                        onClick={props.onHide}
                        disabled={tokenSwap.buttonDisable}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="new-primary-btn"
                        disabled={tokenSwap.buttonDisable}
                      >
                        {tokenSwap.buttonDisable ? t("loading") : t("swap")}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </>
            :
            <NoDataFound/>
        }
      </Modal.Body>
    </Modal>
      
    </>
  );
};

export default SwapModal;
