import React, { useState, useEffect } from "react";
import { Image, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { setTokenBuyData } from "../store/actions/BuyAction";
import { getCurrenciesListStart } from "../store/actions/BuyAction";
import { getErrorNotificationMessage } from "../components/helper/ToastNotification";

const BuyCryptoForm = ({ redirect = false }) => {
  const dispatch = useDispatch();
  const t = useTranslation("buy_crypto_form");
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const currencies = useSelector((state) => state.buy.currencies);

  const [skipRender, setSkipRender] = useState(true);
  const updateTokenBuyData = (data) => dispatch(setTokenBuyData(data));
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [forexOptions, setForexOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    dispatch(getCurrenciesListStart({user_id:profile.data?.user_id?profile.data?.user_id:""}))
  }, []);
  
  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find(
      (crypto) => crypto.id == selectedOption.value
    );
    updateTokenBuyData({
      ...tokenBuyData,
      crypto_exchange_rate: crypto.exchange_rate,
      crypto_source_currency: crypto.source_currency,
      token_type: selectedOption.label,
    });
    setSelectedCrypto(selectedOption);
  };

  const onCryptoTokenChange = (value) => {
    if (Number(value) == value) {
      updateTokenBuyData({
        ...tokenBuyData,
        tokens: value,
      });
    }
  };

  const onFiatChange = (selectedOption) => {
    const forex = currencies.data.forex_currencies.find(
      (forex) => forex.id == selectedOption.value
    );
    updateTokenBuyData({
      ...tokenBuyData,
      forex_exchange_rate: forex.exchange_rate,
      forex_source_currency: forex.source_currency,
      buy_via_currency: selectedOption.label,
    });
    setSelectedForex(selectedOption);
  };

  const onComplete = () => {
    if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      // setStep(2);
    } else if (redirect && !tokenBuyData.tokens) {
      if (tokenBuyData.step == 6) {
        updateTokenBuyData({
          ...tokenBuyData,
          tokens: tokenBuyData.tokens,
          token_type: tokenBuyData.token_type,
          exchangeValue: tokenBuyData.exchangeValue,
          buy_via_currency: tokenBuyData.buy_via_currency,
        });
      }
      // setStep(3);
    } else if (redirect && tokenBuyData.tokens) {
      updateTokenBuyData({ ...tokenBuyData, step: 2 });
      // setStep(3);
    } else {
      tokenBuyData.tokens > 0
        ? updateTokenBuyData({ ...tokenBuyData, step: tokenBuyData.step + 1 })
        : getErrorNotificationMessage("Please enter valid amount");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item.currency_code,
        value: item.id,
      }));
      setCategoriesOptions(categories);

      const selected_crypto = tokenBuyData.token_type
        ? currencies.data.crypto_currencies.find(
            (category) => category.currency_code == tokenBuyData.token_type
          )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenBuyData.token_type
          ? categories.find(
              (category) => category.label == tokenBuyData.token_type
            )
          : categories[0]
      );

      const forexCurrency = currencies.data.forex_currencies.map((forex) => ({
        label: forex.currency_code,
        value: forex.id,
      }));
      setForexOptions(forexCurrency);

      const selected_forex = tokenBuyData.buy_via_currency
        ? currencies.data.forex_currencies.find(
            (forex) => forex.currency_code == tokenBuyData.buy_via_currency
          )
        : currencies.data.forex_currencies[0];

      setSelectedForex(
        tokenBuyData.buy_via_currency
          ? forexCurrency.find(
              (forex) => forex.label == tokenBuyData.buy_via_currency
            )
          : forexCurrency[0]
      );

      updateTokenBuyData({
        ...tokenBuyData,
        token_type: selected_crypto?.currency_code,
        crypto_exchange_rate: selected_crypto?.exchange_rate,
        crypto_source_currency: selected_crypto?.source_currency,
        buy_via_currency: selected_forex?.currency_code,
        forex_exchange_rate: selected_forex?.exchange_rate,
        forex_source_currency: selected_forex?.source_currency,
      });
    }
    setSkipRender(false);
  }, [currencies]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "40px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#298bff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  return (
    <>
      {currencies.loading ? (
        <Skeleton className="mt-5" borderRadius={25} count={1} height={600} />
      ) : Object.keys(currencies.data).length > 0 ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              {currencies.data.crypto_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <Image
                      src={
                        tokenBuyData.token_type
                          ? currencies.data.crypto_currencies.find(
                              (item) =>
                                item.currency_code == tokenBuyData.token_type
                            ).picture
                          : currencies.data.crypto_currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenBuyData.token_type
                        ? currencies.data.crypto_currencies.find(
                            (item) =>
                              item.currency_code == tokenBuyData.token_type
                          ).name
                        : currencies.data.crypto_currencies[0].name}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop"
                    isSearchable={false}
                    options={categoriesOptions}
                    styles={customStyles}
                    value={selectedCrypto}
                    onChange={(selectedOption) =>
                      onCryptoChange(selectedOption)
                    }
                  />
                </div>
              )}
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("send")}</div>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenBuyData.tokens}
                      onChange={(e) => onCryptoTokenChange(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="efi-exchange-fields-card">
              {currencies.data.forex_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <Image
                      src={
                        tokenBuyData.buy_via_currency
                          ? currencies.data.forex_currencies.find(
                              (item) =>
                                item.currency_code ==
                                tokenBuyData.buy_via_currency
                            ).picture
                          : currencies.data.forex_currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenBuyData.buy_via_currency
                        ? currencies.data.forex_currencies.find(
                            (item) =>
                              item.currency_code ==
                              tokenBuyData.buy_via_currency
                          ).name
                        : currencies.data.forex_currencies[0].name}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop"
                    options={forexOptions}
                    isSearchable={false}
                    styles={customStyles}
                    // menuIsOpen={true}
                    value={selectedForex}
                    onChange={(selectedOption) => onFiatChange(selectedOption)}
                  />
                </div>
              )}
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("recieve")}</div>
                <div className="efi-receive-text">
                  {(
                    tokenBuyData.tokens *
                    (1 / tokenBuyData.crypto_exchange_rate) *
                    tokenBuyData.forex_exchange_rate
                  ).toFixed(4)}
                </div>
              </div>
            </div>
            <div className="efi-swap-icons">
              <Image
                src={window.location.origin + "/img/icon/swap.svg"}
                className="swap-icon"
              />
            </div>
          </div>
          <div className="efi-swap-action">
            <Button className="default-swap-btn" onClick={onComplete}>
              {redirect ? t("exchange") : t("continue")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#fff"
                  d="M256 0C114.61 0 0 114.61 0 256s114.61 256 256 256c141.38 0 256-114.61 256-256S397.39 0 256 0zM150.85 110.96c76.7-54.95 181.89-41 241.9 29.67l37.1-26.57-8.57 116.84-113.38-29.49 35.85-25.67c-40.49-43.84-108.21-51.67-157.97-16.02-23.54 16.85-39.16 40.66-46.07 66.62l-58.09-14.95c10.29-39.13 33.77-75.03 69.23-100.42zm210.3 290.08c-76.7 54.95-181.89 41-241.9-29.67l-37.1 26.57 8.57-116.84 113.38 29.49-35.85 25.67c40.49 43.84 108.21 51.67 157.97 16.02 23.54-16.85 39.16-40.66 46.07-66.62l58.09 14.95c-10.29 39.13-33.77 75.03-69.23 100.42z"
                  data-name="162"
                  data-original="#000000"
                ></path>
              </svg>
            </Button>
          </div>
          <div className="efi-swap-exchange-info">
            <p>
              {t("estimated_rate")}: 1 {""}
              {tokenBuyData.token_type
                ? currencies.data.crypto_currencies.find(
                    (item) => item.currency_code == tokenBuyData.token_type
                  ).currency_code
                : currencies.data.crypto_currencies[0].currency_code}{" "}
              = {""}
              {tokenBuyData.buy_via_currency
                ? currencies.data.forex_currencies.find(
                    (item) =>
                      item.currency_code == tokenBuyData.buy_via_currency
                  ).exchange_rate
                : currencies.data.forex_currencies[0].exchange_rate}
              {""}{" "}
              {tokenBuyData.buy_via_currency
                ? currencies.data.forex_currencies.find(
                    (item) =>
                      item.currency_code == tokenBuyData.buy_via_currency
                  ).currency_code
                : currencies.data.forex_currencies[0].currency_code}
            </p>
            <p>{t("no_extra_fees")}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BuyCryptoForm;
