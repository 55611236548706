import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  ADD_BENEFICIARIES_START,
  DELETE_BENEFICIARIES_START,
  BENEFICIARIES_LIST_START,
  SEND_IBAN_TRANSFER_START,
  IBAN_TRANSFER_LIST_START,
  MORE_TRANSFER_IBAN_LIST_START,
  IBAN_TRANSFER_VIEW_START,
  GET_IBAN_CURRENCIES_LIST_START,
  GET_IBAN_WALLETS_LIST_START
} from "../actions/ActionConstant";
import {
  addBeneficiariesSuccess,
  addBeneficiariesFailure,
  deleteBeneficiariesSuccess,
  deleteBeneficiariesFailure,
  beneficiariesListSuccess,
  beneficiariesListFailure,
  sendIbanTransferSuccess,
  sendIbanTransferFailure,
  transferIbanListSuccess,
  transferIbanListFailure,
  transferIbanViewSuccess,
  transferIbanViewFailure,
  getIbanCurrenciesSuccess,
  getIbanCurrenciesFailure,
  getIbanWalletsListSuccess,
  getIbanWalletsListFailure
} from "../actions/TransferAction";

function* addBeneficiariesAPI(action) {
  const beneficiariesListData = yield select(state => state.transfer.beneficiariesList.data);
  yield getCommonSaga({
    apiUrl: "beneficiaries_save",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(addBeneficiariesSuccess(data))
      if (Object.keys(beneficiariesListData).length > 0) {
        store.dispatch(beneficiariesListSuccess({
          ...beneficiariesListData,
          beneficiaries: [data.beneficiary, ...beneficiariesListData.beneficiaries],
          total: beneficiariesListData.total + 1,
        }))
      }
    },
    failure: addBeneficiariesFailure,
  });
}

function* deleteBeneficiariesAPI(action) {
  const beneficiariesListData = yield select(state => state.transfer.beneficiariesList.data);
  yield getCommonSaga({
    apiUrl: "beneficiaries_delete",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(deleteBeneficiariesSuccess(data))
      if (Object.keys(beneficiariesListData).length > 0)
        store.dispatch(beneficiariesListSuccess({
          beneficiaries: beneficiariesListData.beneficiaries.filter(product =>
            (product.beneficiary_id != action.data.beneficiary_id)),
          total: data.total,
        }));
    },
    failure: deleteBeneficiariesFailure,
  });
}

function* beneficiariesListAPI(action) {
  yield getCommonSaga({
    apiUrl: "beneficiaries_list",
    payload: action.data,
    success: beneficiariesListSuccess,
    failure: beneficiariesListFailure,
  });
}

function* sendIbanTransferAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban_transfer",
    payload: action.data,
    success: sendIbanTransferSuccess,
    failure: sendIbanTransferFailure,
  });
}

function* ibanTransferListAPI(action) {
  const ibanTranferListData = yield select(state => state.transfer.ibanTranferList.data);
  yield getCommonSaga({
    apiUrl: "iban_transfers_list",
    payload: action.data,
    successCallback: true,
    success: data => {
      if (Object.keys(ibanTranferListData).length > 0) {
        store.dispatch(transferIbanListSuccess({
          iban_transfers: [...ibanTranferListData.iban_transfers, ...data.iban_transfers],
          total: data.total
        }))
      }
      else {
        store.dispatch(transferIbanListSuccess(data))
      }
    },
    failure: transferIbanListFailure,
  });
}

function* ibanTransferViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban_transfers_view",
    payload: action.data,
    success: transferIbanViewSuccess,
    failure: transferIbanViewFailure,
  });
}

function* ibanCurrenciesListAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_iban_currencies",
    payload: action.data,
    success: getIbanCurrenciesSuccess,
    failure: getIbanCurrenciesFailure,
  });
}

function* ibanWalletListAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_iban_wallets",
    payload: action.data,
    success: getIbanWalletsListSuccess,
    failure: getIbanWalletsListFailure,
  });
}

export default function* TransferSaga() {
  yield all([
    yield takeLatest(ADD_BENEFICIARIES_START, addBeneficiariesAPI),
    yield takeLatest(DELETE_BENEFICIARIES_START, deleteBeneficiariesAPI),
    yield takeLatest(BENEFICIARIES_LIST_START, beneficiariesListAPI),
    yield takeLatest(SEND_IBAN_TRANSFER_START, sendIbanTransferAPI),
    yield takeLatest(IBAN_TRANSFER_LIST_START, ibanTransferListAPI),
    yield takeLatest(MORE_TRANSFER_IBAN_LIST_START, ibanTransferListAPI),
    yield takeLatest(IBAN_TRANSFER_VIEW_START, ibanTransferViewAPI),
    yield takeLatest(GET_IBAN_CURRENCIES_LIST_START, ibanCurrenciesListAPI),
    yield takeLatest(GET_IBAN_WALLETS_LIST_START, ibanWalletListAPI),
  ]);
}
