import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Container
} from "react-bootstrap";
import { connect } from "react-redux";
import AddAccountBank from "../Wallet/AddAccountBank";
import BankAccountIndex from "./BankAccountIndex";
import { useTranslation } from 'react-multi-lang';

const BankAccountListIndex = (props) => {

  const t = useTranslation("bank_account");
  const [modalShow, setModalShow] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !props.bankAccountSave.loading &&
      Object.keys(props.bankAccountSave.data).length > 0) {
      setModalShow(false)
    }
    setSkipRender(false);
  }, [props.bankAccountSave]);

  return (
    <>
      <div className="bank-account-sec">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="bank-account-top-sec">
                <h3>{t("heading")}</h3>
                <Button
                  type="button"
                  className="default-btn"
                  onClick={() => setModalShow(true)}
                >
                  {t("add_bank_account")}
                </Button>
              </div>
              <div className="bank-account-list-box">
                <BankAccountIndex />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <AddAccountBank
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  bankAccountSave: state.bankAccount.addBankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(BankAccountListIndex);