import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col } from "react-bootstrap";
import "./Swap.css";
import SwapCrypto from "./SwapCrypto";
import SwapFailure from "./SwapFailure";
import SwapProcessing from "./SwapProcessing";
import SwapScan from "./SwapScan";
import SwapSuccess from "./SwapSuccess";
import SwapWalletAddress from "./SwapWalletAddress";

const SwapIndexOld = (props) => {

    const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
  }, []);


  return (
    <>
      <div className="exchange-sec">
        <Container fluid>
        {step == 1 && (
                <div className="step-1">
                  <SwapCrypto setStep={setStep} />
                </div>
              )}
               {step == 2 && (
                <div className="step-2">
                  <SwapWalletAddress setStep={setStep} />
                </div>
              )}
              {step == 3 && (
                <div className="step-3">
                  <SwapScan setStep={setStep} />
                </div>
              )}
              {step == 4 && (
                <div className="step-4">
                  <SwapProcessing setStep={setStep} />
                </div>
              )}
              {step == 5 && (
                <div className="step-5">
                  <SwapFailure setStep={setStep} />
                </div>
              )}
              {step == 6 && (
                <div className="step-5">
                  <SwapSuccess setStep={setStep} />
                </div>
              )}
        </Container>
      </div>
    </>
  );
};

export default SwapIndexOld;
