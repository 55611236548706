import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";
import {
    ADD_BENEFICIARIES_START,
    ADD_BENEFICIARIES_SUCCESS,
    ADD_BENEFICIARIES_FAILURE,
    DELETE_BENEFICIARIES_START,
    DELETE_BENEFICIARIES_SUCCESS,
    DELETE_BENEFICIARIES_FAILURE,
    BENEFICIARIES_LIST_START,
    BENEFICIARIES_LIST_SUCCESS,
    BENEFICIARIES_LIST_FAILURE,
    SEND_IBAN_TRANSFER_START,
    SEND_IBAN_TRANSFER_SUCCESS,
    SEND_IBAN_TRANSFER_FAILURE,
    IBAN_TRANSFER_LIST_START,
    IBAN_TRANSFER_LIST_SUCCESS,
    IBAN_TRANSFER_LIST_FAILURE,
    MORE_TRANSFER_IBAN_LIST_START,
    IBAN_TRANSFER_VIEW_START,
    IBAN_TRANSFER_VIEW_SUCCESS,
    IBAN_TRANSFER_VIEW_FAILURE,
    GET_IBAN_CURRENCIES_LIST_START,
    GET_IBAN_CURRENCIES_LIST_SUCCESS,
    GET_IBAN_CURRENCIES_LIST_FAILURE,
    GET_IBAN_WALLETS_LIST_START,
    GET_IBAN_WALLETS_LIST_SUCCESS,
    GET_IBAN_WALLETS_LIST_FAILURE
} from "../actions/ActionConstant";


const initialState = {
    addBeneficiaries: getCommonInitialState(),
    deleteBeneficiaries: getCommonInitialState(),
    beneficiariesList: getCommonInitialState(),
    sendIbanTransfers: getCommonInitialState(),
    ibanTranferList: getCommonInitialState(),
    ibanTranferView: getCommonInitialState(),
    ibanCurrenciesList: getCommonInitialState(),
    ibanWalletList: getCommonInitialState(),
};

const TransferReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_BENEFICIARIES_START: return { ...state, addBeneficiaries: getCommonStart() };
        case ADD_BENEFICIARIES_SUCCESS: return { ...state, addBeneficiaries: getCommonSuccess(action.data) };
        case ADD_BENEFICIARIES_FAILURE: return { ...state, addBeneficiaries: getCommonFailure(action.error) };

        case DELETE_BENEFICIARIES_START: return { ...state, deleteBeneficiaries: getCommonStart() };
        case DELETE_BENEFICIARIES_SUCCESS: return { ...state, deleteBeneficiaries: getCommonSuccess(action.data) };
        case DELETE_BENEFICIARIES_FAILURE: return { ...state, deleteBeneficiaries: getCommonFailure(action.error) };

        case BENEFICIARIES_LIST_START: return { ...state, beneficiariesList: getCommonStart() };
        case BENEFICIARIES_LIST_SUCCESS: return { ...state, beneficiariesList: getCommonSuccess(action.data) };
        case BENEFICIARIES_LIST_FAILURE: return { ...state, beneficiariesList: getCommonFailure(action.error) };

        case SEND_IBAN_TRANSFER_START: return { ...state, sendIbanTransfers: getCommonStart() };
        case SEND_IBAN_TRANSFER_SUCCESS: return { ...state, sendIbanTransfers: getCommonSuccess(action.data) };
        case SEND_IBAN_TRANSFER_FAILURE: return { ...state, sendIbanTransfers: getCommonFailure(action.error) };

        case IBAN_TRANSFER_LIST_START: return { ...state, ibanTranferList: getCommonStart() };
        case IBAN_TRANSFER_LIST_SUCCESS: return { ...state, ibanTranferList: getCommonSuccess(action.data) };
        case IBAN_TRANSFER_LIST_FAILURE: return { ...state, ibanTranferList: getCommonFailure(action.error) };
        case MORE_TRANSFER_IBAN_LIST_START: return { ...state };

        case IBAN_TRANSFER_VIEW_START: return { ...state, ibanTranferView: getCommonStart() };
        case IBAN_TRANSFER_VIEW_SUCCESS: return { ...state, ibanTranferView: getCommonSuccess(action.data) };
        case IBAN_TRANSFER_VIEW_FAILURE: return { ...state, ibanTranferView: getCommonFailure(action.error) };

        case GET_IBAN_CURRENCIES_LIST_START: return { ...state, ibanCurrenciesList: getCommonStart() };
        case GET_IBAN_CURRENCIES_LIST_SUCCESS: return { ...state, ibanCurrenciesList: getCommonSuccess(action.data) };
        case GET_IBAN_CURRENCIES_LIST_FAILURE: return { ...state, ibanCurrenciesList: getCommonFailure(action.error) };

        case GET_IBAN_WALLETS_LIST_START: return { ...state, ibanWalletList: getCommonStart() };
        case GET_IBAN_WALLETS_LIST_SUCCESS: return { ...state, ibanWalletList: getCommonSuccess(action.data) };
        case GET_IBAN_WALLETS_LIST_FAILURE: return { ...state, ibanWalletList: getCommonFailure(action.error) };


        default:
            return state;
    }
};

export default TransferReducer;