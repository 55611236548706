import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const AntiMoneyLaundering = () => {
    return (
        <div className="static-pag-sec">
            <div className="static-page-header">
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="static-section-title">
                            <h1>Anti-Money Laundering</h1>
                            <h4>Effective Date: October 8, 2024</h4>
                        </div>
                    </Col>
                </Row>
            </div>
            <Container fluid>
                <div className="static-box">
                    <Row>
                        <Col md={12}>
                            <div className="static-card">
                                <h4>Anti-Money Laundering (AML) Management Measures for HeraldEX </h4>
                                <h4>1. Introduction</h4>
                                <p>HeraldEX ("we," "us," or "our") is committed to preventing money laundering and terrorist financing in compliance with applicable laws and regulations. This document outlines our Anti-Money Laundering (AML) Management Measures, designed to identify, assess, and mitigate risks associated with money laundering activities.</p>

                                <h4>2. Compliance Officer</h4>
                                <p>We have appointed a dedicated Compliance Officer responsible for implementing and overseeing our AML policies and procedures. The Compliance Officer will ensure compliance with all relevant laws, regulations, and guidelines.</p>

                                <h4>3. Customer Due Diligence (CDD)</h4>
                                <p>We implement Customer Due Diligence (CDD) measures to verify the identity of our customers. This includes:</p>
                                <ul>
                                    <li><strong>Identity Verification:</strong> Collecting and verifying personal information, such as name, date of birth, address, and identification documents (e.g., passport, driver’s license).</li>
                                    <li><strong>Risk Assessment:</strong> Assessing the risk level of each customer based on factors such as their transaction history, geographical location, and the nature of their business activities.</li>
                                    <li><strong>Enhanced Due Diligence (EDD):</strong> Applying enhanced measures for high-risk customers, which may include additional identity verification and ongoing monitoring.</li>
                                </ul>

                                <h4>Transaction Monitoring</h4>
                                <p>We monitor transactions on our platform to detect unusual or suspicious activities. This includes:</p>
                                <ul>
                                    <li><strong>Automated Monitoring Systems:</strong> Utilizing advanced software to flag transactions that deviate from typical patterns or exceed predefined thresholds.</li>
                                    <li><strong>Manual Reviews:</strong> Conducting manual reviews of flagged transactions to assess their legitimacy and compliance with AML regulations.</li>
                                </ul>

                                <h4>5. Reporting Suspicious Activities</h4>
                                <p>If we identify suspicious activities or transactions that may indicate money laundering or terrorist financing, we will:</p>
                                <ul>
                                    <li><strong>Internal Reporting:</strong> Report the findings to our Compliance Officer for further investigation.</li>
                                    <li><strong>External Reporting:</strong> File Suspicious Activity Reports (SARs) with the appropriate regulatory authorities when required by law.</li>
                                </ul>

                                <h4>6. Staff Training</h4>
                                <p>All employees of HeraldEX will receive regular training on AML policies and procedures, including:</p>
                                <ul>
                                    <li><strong>Recognizing Red Flags:</strong> Training on identifying signs of potential money laundering activities.</li>
                                    <li><strong>Compliance Obligations:</strong> Educating staff on their responsibilities under AML regulations and internal policies.</li>
                                </ul>

                                <h4>7. Record Keeping</h4>
                                <p>We maintain comprehensive records of customer identification, transactions, and AML-related activities. These records will be kept for a minimum of years to comply with regulatory requirements.</p>

                                <h4>8. Ongoing Review and Improvement</h4>
                                <p>We regularly review and update our AML policies and procedures to ensure their effectiveness and compliance with changing laws and regulations. This includes conducting periodic audits and assessments of our AML program.</p>

                                <h4>9. Cooperation with Authorities</h4>
                                <p>HeraldEX will cooperate fully with law enforcement and regulatory authorities in investigations related to money laundering and terrorist financing. We are committed to supporting efforts to combat financial crime.</p>

                                <h4>10. Contact Us</h4>
                                <p>If you have any questions or concerns regarding our Anti-Money Laundering Management Measures, please contact us at: <a href="mailto:support@herald.exchange">support@herald.exchange</a>.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default AntiMoneyLaundering