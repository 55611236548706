import React from 'react';
import { Image } from 'react-bootstrap';
import CustomLazyLoad from "../helper/CustomLazyLoad";

const NoPairData = () => {
  return (
    <div className="empty-data-wrapped">
    <div className="empty-data-frame">
    <div className="empty-data-avater">
    <CustomLazyLoad
        className="pair-empty-data-img"
        src={window.location.origin + "/img/phase_1/data/pair-empty.svg"}
      />
      </div>
    <div className="empty-data-info">
    No Pair Found
      </div>

    </div>
  </div>
  )
}

export default NoPairData;
