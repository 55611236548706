import {
    FETCH_STATIC_PAGE_START,
    FETCH_STATIC_PAGE_SUCCESS,
    FETCH_STATIC_PAGE_FAILURE,
    FETCH_FAQS_LIST_START,
    FETCH_FAQS_LIST_SUCCESS,
    FETCH_FAQS_LIST_FAILURE,
    CONTACT_FORM_START,
    CONTACT_FORM_SUCCESS,
    CONTACT_FORM_FAILURE,
  } from "./ActionConstant";
  
  export function fetchStaticPageStart(data) {
    return {
      type: FETCH_STATIC_PAGE_START,
      data,
    };
  }
  
  export function fetchStaticPageSuccess(data) {
    return {
      type: FETCH_STATIC_PAGE_SUCCESS,
      data,
    };
  }
  
  export function fetchStaticPageFailure(error) {
    return {
      type: FETCH_STATIC_PAGE_FAILURE,
      error,
    };
  }
  
  export function fetchFaqsListStart(data) {
    return {
      type: FETCH_FAQS_LIST_START,
      data,
    };
  }
  
  export function fetchFaqsListSuccess(data) {
    return {
      type: FETCH_FAQS_LIST_SUCCESS,
      data,
    };
  }
  
  export function fetchFaqsListFailure(error) {
    return {
      type: FETCH_FAQS_LIST_FAILURE,
      error,
    };
  }

  export function contactFormStart(data) {
    return {
      type: CONTACT_FORM_START,
      data,
    };
  }
  
  export function contactFormSuccess(data) {
    return {
      type: CONTACT_FORM_SUCCESS,
      data,
    };
  }
  
  export function contactFormFailure(error) {
    return {
      type: CONTACT_FORM_FAILURE,
      error,
    };
  }
  