import {
  BANK_LIST_START,
  BANK_LIST_SUCCESS,
  BANK_LIST_FAILURE,
  CREATE_RECIPIENT_START,
  CREATE_RECIPIENT_SUCCESS,
  CREATE_RECIPIENT_FAILURE,
  RECIPIENT_LIST_START,
  RECIPIENT_LIST_SUCCESS,
  RECIPIENT_LIST_FAILURE,
  SINGLE_RECIPIENT_START,
  SINGLE_RECIPIENT_SUCCESS,
  SINGLE_RECIPIENT_FAILURE,
  MOBILE_CODES_LIST_START,
  MOBILE_CODES_LIST_SUCCESS,
  MOBILE_CODES_LIST_FAILURE,
  VIRTUAL_ACCOUNT_SHOW_START,
  VIRTUAL_ACCOUNT_SHOW_SUCCESS,
  VIRTUAL_ACCOUNT_SHOW_FAILURE,
  VIRTUAL_ACCOUNT_WITHDRAW_START,
  VIRTUAL_ACCOUNT_WITHDRAW_SUCCESS,
  VIRTUAL_ACCOUNT_WITHDRAW_FAILURE,
} from "./ActionConstant";

export const bankListStart = (data) => ({
  type: BANK_LIST_START,
  data,
});
export const bankListSuccess = (data) => ({
  type: BANK_LIST_SUCCESS,
  data,
});
export const bankListFailure = (error) => ({
  type: BANK_LIST_FAILURE,
  error,
});

export const createRecipientStart = (data) => ({
  type: CREATE_RECIPIENT_START,
  data,
});
export const createRecipientSuccess = (data) => ({
  type: CREATE_RECIPIENT_SUCCESS,
  data,
});
export const createRecipientFailure = (error) => ({
  type: CREATE_RECIPIENT_FAILURE,
  error,
});

export const recipientListStart = (data) => ({
  type: RECIPIENT_LIST_START,
  data,
});
export const recipientListSuccess = (data) => ({
  type: RECIPIENT_LIST_SUCCESS,
  data,
});
export const recipientListFailure = (error) => ({
  type: RECIPIENT_LIST_FAILURE,
  error,
});

export const singleRecipientStart = (data) => ({
  type: SINGLE_RECIPIENT_START,
  data,
});
export const singleRecipientSuccess = (data) => ({
  type: SINGLE_RECIPIENT_SUCCESS,
  data,
});
export const singleRecipientFailure = (error) => ({
  type: SINGLE_RECIPIENT_FAILURE,
  error,
});

export const mobileCodesListStart = (data) => ({
  type: MOBILE_CODES_LIST_START,
  data,
});
export const mobileCodesListSuccess = (data) => ({
  type: MOBILE_CODES_LIST_SUCCESS,
  data,
});
export const mobileCodesListFailure = (error) => ({
  type: MOBILE_CODES_LIST_FAILURE,
  error,
});

export const virtualAccountShowStart = (data) => ({
  type: VIRTUAL_ACCOUNT_SHOW_START,
  data,
});
export const virtualAccountShowSuccess = (data) => ({
  type: VIRTUAL_ACCOUNT_SHOW_SUCCESS,
  data,
});
export const virtualAccountShowFailure = (error) => ({
  type: VIRTUAL_ACCOUNT_SHOW_FAILURE,
  error,
});

export const virtualAccountWithdrawStart = (data) => ({
  type: VIRTUAL_ACCOUNT_WITHDRAW_START,
  data,
});
export const virtualAccountWithdrawSuccess = (data) => ({
  type: VIRTUAL_ACCOUNT_WITHDRAW_SUCCESS,
  data,
});
export const virtualAccountWithdrawFailure = (error) => ({
  type: VIRTUAL_ACCOUNT_WITHDRAW_FAILURE,
  error,
});
