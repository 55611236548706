import { call, select, put, takeLatest, all, take } from "redux-saga/effects";
import {
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  fetchAllTransactionSuccess,
  fetchAllTransactionFailure,
  sendWithDrawRequestSuccess,
  sendWithDrawRequestFailure,
  fetchWithDrawalsSuccess,
  fetchWithDrawalsFailure,
  cancelWithDrawRequestSuccess,
  cancelWithDrawRequestFailure,
  fetchSingleWalletViewSuccess,
  fetchSingleWalletViewFailure,
  walletPaymentViewSuccess,
  walletPaymentViewFailure,
} from "../actions/WalletAction";
import {
  FETCH_WALLET_DETAILS_START,
  FETCH_SINGLE_WALLET_VIEW_START,
  FETCH_ALL_TRANSACTION_START,
  FETCH_MORE_ALL_TRANSACTION_START,
  SEND_WITHDRAW_REQUEST_START,
  FETCH_WITHDRAWALS_START,
  FETCH_MORE_WITHDRAWALS_START,
  CANCEL_WITHDRAW_REQUEST_START,
  WALLET_PAYMENT_VIEW_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import api from "../../components/Environment";
import { getCommonSaga } from "../sagaUtils";
import store from "..";

function* fetchWalletDetailsAPI() {
  try {
    const response = yield api.postMethod("wallets");
    if (response.data.success) {
      yield put(fetchWalletDetailsSuccess(response.data.data));
    } else {
      yield put(fetchWalletDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWalletDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleWalletViewAPI(action) {
  try {
    const response = yield api.postMethod("wallets/view", action.data);
    if (response.data.success) {
      yield put(fetchSingleWalletViewSuccess(response.data.data));
    } else {
      yield put(fetchSingleWalletViewFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleWalletViewFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchAllTransactionAPI(action) {
  const transactionData = yield select(state => state.wallet.allTransaction.data);
  yield getCommonSaga({
    apiUrl: "wallet_payments",
    payload: action.data,
    successNotify: true, 
    successCallback: true,
    success: data => {
      if (Object.keys(transactionData).length > 0) {
        store.dispatch(fetchAllTransactionSuccess({
          ...transactionData,
          user_wallet_payments: [...transactionData.user_wallet_payments, ...data.user_wallet_payments],
          total_payments: data.total_payments,
        }))
      }
      else {
        store.dispatch(fetchAllTransactionSuccess(data))
      }
    },
    failure: fetchAllTransactionFailure,
  });
}


function* sendWithDrawRequestAPI(action) {
  try {
    const response = yield api.postMethod("user_withdrawals/send", action.data);
    if (response.data.success) {
      yield put(sendWithDrawRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(sendWithDrawRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sendWithDrawRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchWithDrawAPI(action) {
  const withDrawalsData = yield select(state => state.wallet.withDrawals.data)
  yield getCommonSaga({
    apiUrl: "user_withdrawals",
    payload: action.data,
    successNotify: true,
    successCallback: true,
    success: data => {
      if (Object.keys(withDrawalsData).length > 0) {
        store.dispatch(fetchWithDrawalsSuccess({
          ...withDrawalsData,
          user_withdrawals: [...withDrawalsData.user_withdrawals, ...data.user_withdrawals],
          total_user_withdrawals: data.total_user_withdrawals,
        }))
      }
      else {
        store.dispatch(fetchWithDrawalsSuccess(data))
      }
    },
    failure: fetchWithDrawalsFailure,
  });
}

function* cancelWithDrawRequestAPI(action) {
  let withDrawalsData = yield select((state) => state.wallet.withDrawals.data);
  const walletData = yield select(state => state.wallet.singleWallet.data);
  try {
    const response = yield api.postMethod("user_withdrawals/cancel", action.data);

    if (response.data.success) {
      yield put(cancelWithDrawRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (Object.keys(walletData).length > 0){
        store.dispatch(fetchSingleWalletViewSuccess({
          ...walletData,
          user_wallet: response.data.data.user_wallet,
        }));
      }
      if (Object.keys(withDrawalsData).length > 0)
        yield put(fetchWithDrawalsSuccess({
          ...withDrawalsData,
          user_withdrawals: withDrawalsData.user_withdrawals.map((account) =>
            account.id === action.data.user_withdrawal_id ?
              response.data.data.user_withdrawal
              : account
          ),
        }));
    }
    else {
      yield put(cancelWithDrawRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(cancelWithDrawRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* walletPaymentViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_payments/view",
    payload: action.data,
    successNotify: false, 
    success: walletPaymentViewSuccess,
    failure: walletPaymentViewFailure,
  });
}

export default function* WalletSaga() {
  yield all([
    yield takeLatest(FETCH_WALLET_DETAILS_START, fetchWalletDetailsAPI),
    yield takeLatest(FETCH_SINGLE_WALLET_VIEW_START, fetchSingleWalletViewAPI),
    yield takeLatest(FETCH_ALL_TRANSACTION_START, fetchAllTransactionAPI),
    yield takeLatest(FETCH_MORE_ALL_TRANSACTION_START, fetchAllTransactionAPI),
    yield takeLatest(SEND_WITHDRAW_REQUEST_START, sendWithDrawRequestAPI),
    yield takeLatest(FETCH_WITHDRAWALS_START, fetchWithDrawAPI),
    yield takeLatest(FETCH_MORE_WITHDRAWALS_START, fetchWithDrawAPI),
    yield takeLatest(CANCEL_WITHDRAW_REQUEST_START, cancelWithDrawRequestAPI),
    yield takeLatest(WALLET_PAYMENT_VIEW_START, walletPaymentViewAPI),

  ])
}

