import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import {
  setTokenExchangeData,
  exchangeCryptoStart,
  exchangeCryptoSuccess,
  getAdminWalletStart,
} from "../../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { updateSwapData } from "../../store/actions/UserAction";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import configuration from "react-global-configuration";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import ButtonStepper from "./ButtonStepper";

const ExchangeWalletAddressSec = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const t = useTranslation("sell_index");

  const tokenExchangeData = useSelector((state) => state.buy.tokenExchangeData);
  const adminWallet = useSelector((state) => state.buy.adminWallet);
  const exchangeCrypto = useSelector((state) => state.buy.exchangeCrypto);
  const profile = useSelector((state) => state.user.profile);
  const updateTokenExchangeData = (data) => dispatch(setTokenExchangeData(data));

  const [transactionSuccess, setTransactionSuccess] = useState(false)
  

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    transaction_hash: Yup.string()
      .required(t("transaction.required"))
      .min(3, t("transaction.invalid"))
      // .max(66, "Max 66 characters allowed"),
  });

  useEffect(() => {
    dispatch(getAdminWalletStart({ network_type: !["USDT", "USDC"].includes(tokenExchangeData.from_currency) ? tokenExchangeData.from_currency : tokenExchangeData.from_network_type, user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values) => {
    dispatch(
      exchangeCryptoStart({
        // token: tokenExchangeData.from_amount,
        from_token: tokenExchangeData.from_currency,
        to_token: tokenExchangeData.to_currency,
        receiver_wallet_address: tokenExchangeData.receiver_wallet_address,
        transaction_hash: values.transaction_hash,
        // network_type: tokenExchangeData.network_type,
        from_network_type: tokenExchangeData.from_network_type,
        to_network_type: tokenExchangeData.to_network_type || "",
        from_token_amount: tokenExchangeData.from_amount,
        to_token_amount: tokenExchangeData.to_amount,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !exchangeCrypto.loading &&
      Object.keys(exchangeCrypto.data).length > 0
    ) {
      setTransactionSuccess(true)

      setTimeout(() => {
        if (exchangeCrypto.data.exchange.status == 5) {
          updateTokenExchangeData({
            ...tokenExchangeData,
            step: 3,
          })
        }
        else {
          navigate("/transactions")
        }
      }, 4000)


    }
    setSkipRender(false);
  }, [exchangeCrypto]);

  return (

    <>
      {adminWallet.loading ?
        <CommonCenterLoader />
        :
        Object.keys(adminWallet.data).length > 0 ? (
          <>
            <div className="bank-account-details-sec">
              <div className="bank-account-details out_space_mx_1_5">
                <div
                  className="back-arrow-btn-sec"
                  onClick={() =>
                    updateTokenExchangeData({ ...tokenExchangeData, step: 1 })
                  }
                >
                  <Image
                    src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
                    className="back-arrow-btn"
                  />
                  <h4>{t("exchange_crypto")} </h4>
                </div>
              </div>
              <div className="auth-qr-details-swap-frame">
                <div className="deposit-amt-details mb-0 p-0">
                  <div className="deposit-amt-details-info out_space_mx_1_5">
                    <div className="deposit-amt-pay" >
                      <span className="amt-pay-label">You Pay </span>
                      <span className="amt-pay-amt">{tokenExchangeData.from_amount} {tokenExchangeData.from_currency} 
                      {" "} {(["USDT", "USDC"].includes(tokenExchangeData.from_currency) && tokenExchangeData.from_network_type) ? `(${tokenExchangeData.from_network_type})` : ""}
                      </span>
                    </div>
                    <div className="deposit-amt-get">
                      <span className="amt-pay-label">You Get </span>
                      <span className="amt-pay-amt">{tokenExchangeData.to_currency_conversion_value} {tokenExchangeData.to_currency}
                      {" "} {(["USDT", "USDC"].includes(tokenExchangeData.to_currency) && tokenExchangeData.to_network_type) ? `(${tokenExchangeData.to_network_type})` : ""}
                      </span>
                    </div>
                  </div>
                  <div className="efi-swap-exchange-info efi-info-frame">
                    <p>
                      {" "}
                      <span>Estimated Rate </span>{" "}
                      <span>
                        1 {tokenExchangeData.from_currency}={!isNaN(tokenExchangeData.to_currency_conversion_value)?(tokenExchangeData.to_currency_conversion_value/tokenExchangeData.from_amount).toFixed(8):0.0000} {tokenExchangeData.to_currency}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="auth_deposit_qr_scan">
                  <div
                    className="admin-wallet-qr-frame"
                  >
                    {adminWallet.data?.admin_crypto_account?.qr_code  ?
                      <CustomLazyLoad
                        src={adminWallet.data?.admin_crypto_account.qr_code}
                        className="qr-code-payment-image"
                    
                      />
                       : (
                        <QRCode
                          size={135}
                          value={adminWallet.data?.admin_crypto_account.wallet_address}
                          viewBox={`0 0 256 256`}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                transaction_hash: "",
              }}
              validationSchema={validationSchema}
              innerRef={formRef}
              onSubmit={(values) => onSubmit(values)}
            >
              {({ values, touched, errors, setFieldValue }) => (
                <FORM>
                  <div className="deposit_modal_form">
                    <div className="sell_address_card  out_space_mx_1_5">
                      <div className="buy-data-card mb-3">
                        <h6>{t("wallet_address")}</h6>
                        <div className="auth_buy_code">
                          <div className="auth_deposit_code_card">
                            <span>
                              {" "}
                              {adminWallet.data?.admin_crypto_account?.wallet_address}
                            </span>{" "}
                          </div>

                          <CopyToClipboard
                            text={adminWallet.data?.admin_crypto_account?.wallet_address}
                            onCopy={(e) =>
                              getSuccessNotificationMessage("Wallet Address Copied!")
                            }
                          >
                            <Button className="action-btn overlay item_center_column p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#298BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="deposit_address_field">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            {t("transaction.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("transaction.placeholder")}
                            name="transaction_hash"
                            disabled={exchangeCrypto.buttonDisable}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="transaction_hash"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {exchangeCrypto.buttonDisable || (Object.keys(exchangeCrypto.data).length > 0 && transactionSuccess) ? <ButtonStepper status={exchangeCrypto.data.exchange?.status ? exchangeCrypto.data.exchange?.status : 0} /> :
                      <div className="deposit_amount_action item_flex_x_5">

                        <Button
                          className="action-btn primary w-100"
                          type="submit"
                          disabled={exchangeCrypto.buttonDisable}
                        >
                          {t("continue")}
                        </Button>
                      </div>}
                    <div className="deposit_bank_action">
                      <p className="out_space_mt_1 other_links">
                        {t("by_continuing_you_agree_to_our")}{" "}
                        <Link className="cookie-txt" to="/page/privacy-agreement">
                          {t("cookie_policy")}.
                        </Link>
                      </p>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="bank-account-details out_space_mx_1_5">
              <div
                className="back-arrow-btn-sec"
                onClick={() => updateTokenExchangeData({ ...tokenExchangeData, step: 1 })}
              >
                <Image
                  src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
                  className="back-arrow-btn"
                />
                <h4>{t("sell_crypto")} </h4>
              </div>
            </div>
            <div className="no-bank-details-sec exchange-btn-sec">
              <CustomLazyLoad
                src={window.location.origin + "/img/no-bank-found.png"}
                className="no-data-found-img"
              />
              <p>{t("no_wallet_details_found")}</p>
              <div>
                <Button
                  className="action-btn primary w-100"
                  onClick={() => dispatch(getAdminWalletStart({ network_type: !["USDT", "USDC"].includes(tokenExchangeData.from_currency) ? tokenExchangeData.from_currency : tokenExchangeData.network_type }))}
                >
                  {t("retry")}
                </Button>
              </div>
            </div>
          </>
        )
      }
    </>

  );
};

export default ExchangeWalletAddressSec;
