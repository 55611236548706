import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import '../Wallet/Wallet.css';
import * as Yup from "yup";
import { connect } from "react-redux";
import { useTranslation } from 'react-multi-lang';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { cancelWithDrawRequestStart } from '../../store/actions/WalletAction';

const CancelWithdrawalModal = (props) => {

  const t = useTranslation("cancel_withdraw");
  const [skipRender, setSkipRender] = useState(true);
  const { withdrawalId } = props;

  const validationSchema = Yup.object().shape({
    cancel_reason: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{5,})/,
      t("cancel_reason.invalid")).required(t("cancel_reason.required"))
  });

  useEffect(() => {
    if (!skipRender && !props.cancelWithDraw.loading &&
      Object.keys(props.cancelWithDraw.data).length > 0)
      props.onHide();
    setSkipRender(false);
  }, [props.cancelWithDraw]);

  const handleSubmit = (values) => {
    props.dispatch(cancelWithDrawRequestStart({
      ...values,
      user_withdrawal_id: withdrawalId,
    }))
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='withdrawwallet'
      >
        <Modal.Header  >
          <Modal.Title id="contained-modal-title-vcenter">
            {t("heading")}
          </Modal.Title>
          <div className="modal-close-btn-area">
            <Button onClick={props.onHide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#131615"
                  d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"
                  data-original="#000000"
                ></path>
              </svg>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              cancel_reason: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM className="exchange-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("cancel_reason.label")}</Form.Label>
                  <Field
                    name="cancel_reason"
                    className="form-control"
                    type="text"
                    autoFocus={true}
                    placeholder={t("cancel_reason.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="cancel_reason"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="bank-account-btn">
                  <Button
                    className='default-btn cancel'
                    onClick={() => {
                      setFieldValue("cancel_reason", "")
                    }}
                  >{t("reset")}
                  </Button>
                  <Button
                    type="submit"
                    className='default-btn save'
                    disabled={props.cancelWithDraw.buttonDisable}
                  >
                    {props.cancelWithDraw.buttonDisable
                      ? t("loading")
                      : t("cancel_withdraw")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
};

const mapStateToPros = (state) => ({
  cancelWithDraw: state.wallet.cancelWithDraw,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(CancelWithdrawalModal);
