import React from 'react';
import { Image } from 'react-bootstrap';
import CustomLazyLoad from "../helper/CustomLazyLoad";

const NoTransactionData = () => {
  return (
    <div className="empty-data-wrapped">
    <div className="empty-data-frame">
    <div className="empty-data-avater">
    <CustomLazyLoad
        className="empty-data-img"
        src={window.location.origin + "/img/phase_1/data/transaction-empty.svg"}
      />
      </div>
    <div className="empty-data-info">
    No Transaction Found
      </div>

    </div>
  </div>
  )
}

export default NoTransactionData;
