import { call,put, takeLatest, all } from "redux-saga/effects";
import api from "../../components/Environment";
import { 
  FETCH_STATIC_PAGE_START,
  FETCH_FAQS_LIST_START,
  CONTACT_FORM_START,
 } from "../actions/ActionConstant";
 import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/helper/ToastNotification";
import {
  fetchStaticPageSuccess,
  fetchStaticPageFailure,
  fetchFaqsListSuccess,
  fetchFaqsListFailure,
  contactFormSuccess,
  contactFormFailure,
} from "../actions/PageAction";

function* fetchStaticPage(action) {
  try {
    const response = yield api.postMethod(
      "static_pages_view", action.data
    );
    if (response.data.success) {
      yield put(fetchStaticPageSuccess(response.data.data));
    } else {
      yield put(fetchStaticPageFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchStaticPageFailure(error));
  }
}

function* fetchFaqsListAPI(action) {
  try {
    const response = yield api.postMethod("faqs_list", action.data);

    if (response.data.success) {
      yield put(fetchFaqsListSuccess(response.data.data));
    } else {
      yield put(fetchFaqsListFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchFaqsListFailure(error));
  }
}

function* contactFormAPI(action) {
  try {
    const response = yield api.postMethod("contact", action.data);

    if (response.data.success) {
      yield put(contactFormSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(contactFormFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(contactFormFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_STATIC_PAGE_START, fetchStaticPage),
    yield takeLatest(FETCH_FAQS_LIST_START, fetchFaqsListAPI),
    yield takeLatest(CONTACT_FORM_START, contactFormAPI),
  ]);
}
