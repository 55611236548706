import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Upcomingevent = () => {
  return (
    <>
    <div className="upcoming-wrapper">
    <Image
          className="upcoming-wrapper-avater"
          src={window.location.origin + "/img/phase_1/icons/upcoming.svg"}
        />
        <div className="upcoming-wrapper-info">
            <h3>Coming Soon..!</h3>
        </div>
    </div>
    </>
  )
}

export default Upcomingevent;
