import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav, Image } from "react-bootstrap";
import { buyTokensViewStart } from "../../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import CustomLazyLoad from "../../helper/CustomLazyLoad";
import NoDataFound from "../../helper/NoDataFound";

const BuyTransactionViewModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const t = useTranslation("transactions");
  const buyTokensView = useSelector(
    (state) => state.buy.buyTokensView
  );

  useEffect(() => {
    dispatch(
      buyTokensViewStart({
        buy_transaction_id: transactionId,
      })
    );
  }, []);

  const formatNumber = (number) => {
    if (number) {
      return number
        .replace(/\s+/g, "")
        .split("")
        .reduce((seed, next, index) => {
          if (index !== 0 && index % 4 === 0) {
            seed += " ";
          }
          return seed + next;
        }, "");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Buy Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {buyTokensView.loading ? (
            <>
              {/* <div className="trans-view-grid-layout">
                <Skeleton height={270} />
                <Skeleton height={270} />
              </div> */}
              <Skeleton height={200} className="mb-3" />
              <Skeleton height={200} className="mb-3" />
            </>
          ) : Object.keys(buyTokensView.data).length > 0 ? (
            <div className="transaction-view-body">
              <div className="transaction-basic-info-wrap">
                <div className="transaction-basic-info-head">
                  <div className="transaction-basic-info-head-titles">
                    <h3>
                      {buyTokensView.data.buy_transaction.to_amount_formatted}
                    </h3>
                    <p>Tokens Transferred</p>
                  </div>
                  {/* <div className="transaction-basic-info-head-titles">
                  <Button className="action-btn overlay">
                    {" "}
                    View Transaction Hash{" "}
                  </Button>
                </div> */}
                </div>
                <div className="transaction-space"></div>
                <div className="transaction-basic-info-content">
                  <div className="transaction-basic-content-card">
                    <p>Amount Paid</p>
                    <h5>
                      {buyTokensView.data.buy_transaction.from_amount_formatted}{" "}
                    </h5>
                  </div>
                  <div className="transaction-basic-content-card">
                    <p>Status</p>
                    {/* <div className="pay-badge succes">Success</div> */}
                    {/* <p
                      className={`status ${buyTokensView.data.buy_transaction.status == 1
                        ? "succes"
                        : buyTokensView.data.buy_transaction.status == 2 ? "denied" : "pending"
                        } `}
                    >
                      {buyTokensView.data.buy_transaction.status_formatted}
                    </p> */}
                       <div
                        className={`table_badge  ${
                          buyTokensView.data.buy_transaction.status == 1 ? "success" :buyTokensView.data.buy_transaction.status==0? "pending":"fail"
                        }`}
                      >
                        {" "}
                        {buyTokensView.data.buy_transaction.status_formatted}
                      </div>
                  </div>
                  
                </div>
              </div>
              <div className="transaction-basic-tab-wrap out_space_mt_1">
                <Tab.Container
                  id="transaction-basic-tab"
                  defaultActiveKey="transaction"
                >
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="transaction" className="view_tab_btn">
                        Transaction Details
                      </Nav.Link>
                    </Nav.Item>
                    {buyTokensView.data.buy_transaction.payment_type == "bank" ? <Nav.Item>
                      <Nav.Link eventKey="bank" className="view_tab_btn">
                        Bank Details
                      </Nav.Link>
                    </Nav.Item> : null}
                    {buyTokensView.data.buy_transaction.payment_file ? <Nav.Item>
                      <Nav.Link eventKey="proof" className="view_tab_btn">
                        Payment Proof
                      </Nav.Link>
                    </Nav.Item> : null}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="transaction">
                      <div className="transaction-tab-frame">
                        <div className="transaction-tab-info-card">
                          <p>Exchange Rate</p>
                          <h4>{buyTokensView.data.buy_transaction.adjusted_exchange_rate_formatted}</h4>
                        </div>
                        <div className="transaction-tab-info-card">
                          <p>Transaction Date</p>
                          <h4>
                            {buyTokensView.data.buy_transaction.created_at_formatted}{" "}
                          </h4>
                        </div>
                        {buyTokensView.data.buy_transaction
                          .received_transaction_hash ? (
                          <div className="transaction-tab-info-card">
                            <p>Transaction ID</p>
                            <h4 className="copy-view-card">
                              {" "}
                              <span>
                                { buyTokensView.data.buy_transaction.received_transaction_hash.length > 10
                                ? `${ buyTokensView.data.buy_transaction.received_transaction_hash.slice(
                                    0,
                                    5
                                  )}...${ buyTokensView.data.buy_transaction.received_transaction_hash.slice(-5)}`
                                :  buyTokensView.data.buy_transaction.received_transaction_hash}
                              </span>{" "}
                              <CopyToClipboard text={buyTokensView.data.buy_transaction.received_transaction_hash}>
                                <Button className="action-btn copy-btn item_center" onClick={()=> getSuccessNotificationMessage("Transaction ID Copied")}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h4>
                          </div>
                        ) : null}
                        {buyTokensView.data.buy_transaction
                          .payment_id ? (
                          <div className="transaction-tab-info-card">
                            <p>Payment ID</p>
                            <h4 className="copy-view-card">
                              {" "}
                              <span>
                                { buyTokensView.data.buy_transaction.payment_id.length > 10
                                ? `${ buyTokensView.data.buy_transaction.payment_id.slice(
                                    0,
                                    5
                                  )}...${ buyTokensView.data.buy_transaction.payment_id.slice(-5)}`
                                :  buyTokensView.data.buy_transaction.payment_id}
                              </span>{" "}
                              <CopyToClipboard text={buyTokensView.data.buy_transaction.payment_id}>
                                <Button className="action-btn copy-btn item_center" onClick={()=> getSuccessNotificationMessage("Payment ID Copied")}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h4>
                          </div>
                        ) : null}
                        {/* <div className="transaction-tab-info-card">
                          <p>Order ID</p>
                          <h4>{buyTokensView.data.buy_transaction.order_id}</h4>
                        </div> */}
                        <div className="transaction-tab-info-card">
                          <p>Transaction Type</p>
                          <h4>Buy</h4>
                        </div>
                        <div className="transaction-tab-info-card">
                          <p>Payment Type</p>
                          <h4>{buyTokensView.data.buy_transaction.payment_type.toUpperCase()}</h4>
                        </div>
                        {buyTokensView.data.buy_transaction.cancel_reason ? <div className="transaction-tab-info-card">
                          <p>Cancel Reason</p>
                          <h4>{buyTokensView.data.buy_transaction.cancel_reason}</h4>
                        </div> : null}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="bank">
                      <div className="bank-tab-info-wrap">
                        <div className="bank-tab-card-info out_space_mx_1">
                          <div className="bank-tab-card-wrap">
                            <div className="bank-tab-card-wrap-icons">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                  data-original="#000000"
                                ></path>
                              </svg>
                            </div>
                            <div className="bank-tab-card-wrap-info">
                              <h4>{buyTokensView.data.buy_transaction.admin_bank_account.account_holder_name}</h4>
                              <p>{buyTokensView.data.buy_transaction.admin_bank_account.bank_name}</p>
                            </div>
                          </div>
                          <h5>{buyTokensView.data.buy_transaction.admin_bank_account.account_number}</h5>
                        </div>
                        <div className="bank-tab-card-other-info out_space_mx_1">
                          <div className="bank-tab-card-other-info-titles">
                            <h3>Bank Details</h3>
                          </div>
                          <div className="bank-tab-card-other-info-content">
                            <div className="bank-tab-card-other-wrap">
                              <p>Account Type</p>
                              <h4>{buyTokensView.data.buy_transaction.admin_bank_account.account_type}</h4>
                            </div>
                            <div className="bank-tab-card-other-wrap">
                              <p>Currency Type</p>
                              <h4>{buyTokensView.data.buy_transaction.admin_bank_account.currency_type}</h4>
                            </div>
                            <div className="bank-tab-card-other-wrap">
                              <p>Address</p>
                              <h4>{buyTokensView.data.buy_transaction.admin_bank_account.bank_address}</h4>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bank-tab-card-other-info">
                        <div className="bank-tab-card-other-info-titles">
                          <h3>Correspondent</h3>
                        </div>
                        <div className="bank-tab-card-other-info-content">
                          <div className="bank-tab-card-other-wrap">
                            <p>Swift Code</p>
                            <h4>HBRY443332Y</h4>
                          </div>
                          <div className="bank-tab-card-other-wrap">
                            <p>Account Number</p>
                            <h4>7328 3283 3823 3732</h4>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </Tab.Pane>
                   {buyTokensView.data.buy_transaction.payment_file ?<Tab.Pane eventKey="proof">
                      <div className="bank-tab-info-wrap">
                        <div className="item_center text-center">
                        <Image src={buyTokensView.data.buy_transaction.payment_file} className="qr-tab-view" />
                        </div>
                      </div>
                    </Tab.Pane> : null}
                  </Tab.Content>
                </Tab.Container>
              </div> 
            </div>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyTransactionViewModal;
