import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import '../Wallet/Wallet.css';
import * as Yup from "yup";
import { connect } from "react-redux";
import { useTranslation } from 'react-multi-lang';
import { deleteBankAccountStart } from "../../store/actions/BankAccountAction";

const DeleteBankAccountModal = (props) => {

  const t = useTranslation("delete_bank");
  const [skipRender, setSkipRender] = useState(true);
  const { accountId } = props;

  const handleSubmit = (values) => {
    props.dispatch(deleteBankAccountStart({
      ...values,
      user_bank_account_id: accountId,
    }))
  };

  useEffect(() => {
    if (!skipRender && !props.bankAccountDelete.loading &&
      Object.keys(props.bankAccountDelete.data).length > 0)
      props.onHide();
    setSkipRender(false);
  }, [props.bankAccountDelete])

  return (
    <>
     <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-3'>
        <h4>{t("heading")}</h4>
        <p className='text-center'>{t("default_confirmation")}</p>
        <div className="new-action-frame layout-element">
            <Button
              className='new-overlay-btn'
              onClick={props.onHide}
            >{t("cancel")}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              className='new-primary-btn'
              disabled={props.bankAccountDelete.buttonDisable}
            >
              {props.bankAccountDelete.buttonDisable
                ? t("loading")
                : t("delete")}
            </Button>
          </div>
          </div>
      </Modal.Body>
    </Modal>
    </>
  )
};

const mapStateToPros = (state) => ({
  bankAccountDelete: state.bankAccount.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(DeleteBankAccountModal);
