import {
    ADD_BENEFICIARIES_START,
    ADD_BENEFICIARIES_SUCCESS,
    ADD_BENEFICIARIES_FAILURE,
    DELETE_BENEFICIARIES_START,
    DELETE_BENEFICIARIES_SUCCESS,
    DELETE_BENEFICIARIES_FAILURE,
    BENEFICIARIES_LIST_START,
    BENEFICIARIES_LIST_SUCCESS,
    BENEFICIARIES_LIST_FAILURE,
    SEND_IBAN_TRANSFER_START,
    SEND_IBAN_TRANSFER_SUCCESS,
    SEND_IBAN_TRANSFER_FAILURE,
    IBAN_TRANSFER_LIST_START,
    IBAN_TRANSFER_LIST_SUCCESS,
    IBAN_TRANSFER_LIST_FAILURE,
    MORE_TRANSFER_IBAN_LIST_START,
    IBAN_TRANSFER_VIEW_START,
    IBAN_TRANSFER_VIEW_SUCCESS,
    IBAN_TRANSFER_VIEW_FAILURE,
    GET_IBAN_CURRENCIES_LIST_START,
    GET_IBAN_CURRENCIES_LIST_SUCCESS,
    GET_IBAN_CURRENCIES_LIST_FAILURE,
    GET_IBAN_WALLETS_LIST_START,
    GET_IBAN_WALLETS_LIST_SUCCESS,
    GET_IBAN_WALLETS_LIST_FAILURE
} from "./ActionConstant"

export const addBeneficiariesStart = data => ({ type: ADD_BENEFICIARIES_START, data })
export const addBeneficiariesSuccess = data => ({ type: ADD_BENEFICIARIES_SUCCESS, data })
export const addBeneficiariesFailure = error => ({ type: ADD_BENEFICIARIES_FAILURE, error })

export const deleteBeneficiariesStart = data => ({ type: DELETE_BENEFICIARIES_START, data })
export const deleteBeneficiariesSuccess = data => ({ type: DELETE_BENEFICIARIES_SUCCESS, data })
export const deleteBeneficiariesFailure = error => ({ type: DELETE_BENEFICIARIES_FAILURE, error })

export const beneficiariesListStart = data => ({ type: BENEFICIARIES_LIST_START, data })
export const beneficiariesListSuccess = data => ({ type: BENEFICIARIES_LIST_SUCCESS, data })
export const beneficiariesListFailure = error => ({ type: BENEFICIARIES_LIST_FAILURE, error })

export const sendIbanTransferStart = data => ({ type: SEND_IBAN_TRANSFER_START, data })
export const sendIbanTransferSuccess = data => ({ type: SEND_IBAN_TRANSFER_SUCCESS, data })
export const sendIbanTransferFailure = error => ({ type: SEND_IBAN_TRANSFER_FAILURE, error })

export const transferIbanListStart = data => ({ type: IBAN_TRANSFER_LIST_START, data })
export const transferIbanListSuccess = data => ({ type: IBAN_TRANSFER_LIST_SUCCESS, data })
export const transferIbanListFailure = error => ({ type: IBAN_TRANSFER_LIST_FAILURE, error })
export const moreTransferIbanListStart = data => ({ type: MORE_TRANSFER_IBAN_LIST_START, data })

export const transferIbanViewStart = data => ({ type: IBAN_TRANSFER_VIEW_START, data })
export const transferIbanViewSuccess = data => ({ type: IBAN_TRANSFER_VIEW_SUCCESS, data })
export const transferIbanViewFailure = error => ({ type: IBAN_TRANSFER_VIEW_FAILURE, error })

export const getIbanCurrenciesStart = data => ({ type: GET_IBAN_CURRENCIES_LIST_START, data })
export const getIbanCurrenciesSuccess = data => ({ type: GET_IBAN_CURRENCIES_LIST_SUCCESS, data })
export const getIbanCurrenciesFailure = error => ({ type: GET_IBAN_CURRENCIES_LIST_FAILURE, error })

export const getIbanWalletsListStart = data => ({ type: GET_IBAN_WALLETS_LIST_START, data })
export const getIbanWalletsListSuccess = data => ({ type: GET_IBAN_WALLETS_LIST_SUCCESS, data })
export const getIbanWalletsListFailure = error => ({ type: GET_IBAN_WALLETS_LIST_FAILURE, error })

