import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../helper/NoDataFound';
import DeleteBeneficiaryAccount from './DeleteBeneficiaryAccount';
import { useDispatch, useSelector } from 'react-redux';
import { beneficiariesListStart } from '../../store/actions/TransferAction';
import { useTranslation } from 'react-multi-lang';

const BeneficiaryAccountIndex = (props) => {

  const [accountId, setAccountId] = useState(null);
  const t = useTranslation("beneficiary_account");
  const [deleteBankAccount, setDeleteBankAccount] = useState(false);
  const dispatch = useDispatch();
  const beneficiariesList = useSelector(state => state.transfer.beneficiariesList);

  useEffect(() => {
    dispatch(beneficiariesListStart());
  }, []);

  const deleteBankAccountConfirmation = (accout_id) => {
    setAccountId(accout_id);
    setDeleteBankAccount(true);
  }

  return (
    <>
      {beneficiariesList.loading ? (
        [...Array(3)].map((i, key) => <Skeleton key={key} height={220} />)
      ) : Object.keys(beneficiariesList.data).length > 0 &&
        beneficiariesList.data.beneficiaries.length > 0 ?
        beneficiariesList.data.beneficiaries.map((account, i) => (
          <div className="bank-account-list-card">
            <div className="bank-account-list-item">
              <div className="bank-account-list-header-sec">
                <div className="bank-account-list-header-left-sec">
                  <h4>{account.account_holder_name}</h4>
                  <p>{account.bank_name}</p>
                </div>
                <div className="bank-account-list-header-right-sec">
                  <Button
                    onClick={() => deleteBankAccountConfirmation(account.beneficiary_id)}
                    className="delete-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="none"
                      stroke="#FF6A6A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="feather feather-trash-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 6L5 6 21 6"></path>
                      <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                      <path d="M10 11L10 17"></path>
                      <path d="M14 11L14 17"></path>
                    </svg>
                  </Button>
                </div>
              </div>
              <div className="bank-account-list-body-sec">
                <div className="bank-account-list-account-number-sec">
                  <h4>XXXX XXXX XXXX {account.iban.slice(-4)}</h4>
                </div>
                <div className="bank-account-list-footer-sec">
                  <div className="bank-account-list-ifsc-code-sec">
                    <svg
                      width="22"
                      height="22"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11V7.83L12 2.06 2 7.83V11h2v8H2v2h20v-2h-2v-8zM4 9l8-4.62L20 9H4zm2 10v-8h2v8zm4 0v-8h4v8zm8 0h-2v-8h2z"></path>
                    </svg>
                    <span className="bank-account-country">{account.country}</span>
                  </div>
                  <div className="bank-account-list-type-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 16 17"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                      ></path>
                    </svg>
                    <span className='account-type'>{account.account_type} {t("account")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
        :
        <React.Fragment>
          <div></div>
          <NoDataFound />
        </React.Fragment>
      }
      {deleteBankAccount && (
        <DeleteBeneficiaryAccount
          accountId={accountId}
          show={deleteBankAccount}
          onHide={() => setDeleteBankAccount(false)}
        />
      )}
    </>
  )
}

export default BeneficiaryAccountIndex;
