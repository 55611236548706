import React, { useEffect, useState } from "react";
import { Nav, Tab, Button } from "react-bootstrap";
import BuyCryptoForm from "./BuyCryptoForm";
import SellCryptoForm from "./SellCryptoForm";
import { useTranslation } from "react-multi-lang";
import TokenBuy from "./TokenBuySec";
import BuySuccess from "./BuySuccess";
import CardIndex from "./CardIndex";
import { useSelector } from "react-redux";
import SwapWalletAddressSec from "./SwapWalletAddressSec";
import SellSuccess from "./SellSuccess";
import SwapCryptoPayment from "./SwapCryptoPayment";
import LoginIndex from "./LoginIndex";

const SwapField = () => {
  const t = useTranslation("buy_index");
  const [activeTab, setActiveTab] = useState("buy_crypto");
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const login = useSelector((state) => state.user.login);

  useEffect(() => {
    if (!login.loading && Object.keys(login.data).length > 0) {
      setIsLoggedIn(true);
    }
  }, [login]);

  return (
    <div className="landing-swap-box">
      <Tab.Container id="left-tabs-example" defaultActiveKey="but-crypto">
        {isLoggedIn && (
          <div
            className="widget-header-sec"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Nav variant="pills" className="d-flex">
              <Nav.Item>
                <Nav.Link
                  eventKey="but-crypto"
                  onClick={() => setActiveTab("buy_crypto")}
                >
                  {t("buy_crypto")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="sell-crypto"
                  onClick={() => setActiveTab("sell_crypto")}
                >
                  {t("sell_crypto")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Button
              type="button"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                boxShadow: "none",
                padding: "0",
                width: "2em",
                height: "2em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="widget-close-btn"
              onClick={() => {
                localStorage.removeItem("userId");
                localStorage.removeItem("token");
                setIsLoggedIn(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  d="M21.486 18.14h-1.922a.427.427 0 00-.336.161 8.927 8.927 0 01-.612.684 9.665 9.665 0 01-3.08 2.118 9.475 9.475 0 01-3.77.776 9.475 9.475 0 01-3.77-.776 9.665 9.665 0 01-3.08-2.118 9.872 9.872 0 01-2.078-3.14A9.959 9.959 0 012.078 12c0-1.337.257-2.629.76-3.846a9.827 9.827 0 012.077-3.14 9.663 9.663 0 013.081-2.117 9.473 9.473 0 013.77-.776c1.309 0 2.577.26 3.77.776a9.663 9.663 0 013.08 2.118c.216.22.418.45.612.684a.431.431 0 00.336.162h1.922a.222.222 0 00.183-.344C19.573 2.191 15.904-.01 11.736 0 5.186.017-.065 5.445 0 12.123.066 18.695 5.309 24 11.766 24c4.157 0 7.81-2.2 9.903-5.517.093-.148-.01-.343-.183-.343zm2.43-6.316L20.037 8.7c-.145-.118-.355-.012-.355.175v2.121h-8.583c-.12 0-.219.1-.219.224v1.562c0 .123.098.224.219.224h8.583v2.12c0 .188.213.294.355.176l3.88-3.125A.221.221 0 0024 12a.228.228 0 00-.084-.176z"
                ></path>
              </svg>
            </Button>
          </div>
        )}
        <Tab.Content>
          {isLoggedIn ? (
            <React.Fragment>
              <Tab.Pane eventKey="but-crypto">
                {activeTab == "buy_crypto" &&
                  (tokenBuyData.step == 1 ? (
                    <div className="step-1">
                      <BuyCryptoForm />
                    </div>
                  ) : tokenBuyData.step == 2 ? (
                    <div className="step-2">
                      <TokenBuy />
                    </div>
                  ) : tokenBuyData.step == 3 ? (
                    <div className="step-3">
                      <BuySuccess />
                    </div>
                  ) : tokenBuyData.step >= 4 ? (
                    <div className="step-4">
                      <CardIndex />
                    </div>
                  ) : null)}
              </Tab.Pane>
              <Tab.Pane eventKey="sell-crypto">
                {activeTab == "sell_crypto" &&
                  (tokenSellData.step === 1 ? (
                    <div className="step-1">
                      <SellCryptoForm />
                    </div>
                  ) : tokenSellData.step === 2 ? (
                    <div className="step-2">
                      <SwapWalletAddressSec />
                    </div>
                  ) : tokenSellData.step === 3 ? (
                    <div className="step-3">
                      <SellSuccess />
                    </div>
                  ) : tokenSellData.step >= 4 ? (
                    <div className="step-4">
                      <SwapCryptoPayment />
                    </div>
                  ) : null)}
              </Tab.Pane>
            </React.Fragment>
          ) : (
            <LoginIndex />
          )}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default SwapField;
