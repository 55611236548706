import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleRecipientStart } from "../../store/actions/RecipientAction";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import { countriesData } from "./CountriesList";
import { useTranslation } from "react-multi-lang";

const ViewRecipientDetail = () => {
  const t = useTranslation("recipient.recipient_view");
  const dispatch = useDispatch();
  const singleRecipient = useSelector(
    (state) => state.recipient.singleRecipient
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [recipient, setRecipient] = useState([]);
  const params = useParams();

  useEffect(() => {
    dispatch(singleRecipientStart({ beneficiary_account_id: params.id }));
  }, [params]);

  useEffect(() => {
    if (
      !singleRecipient.loading &&
      Object.keys(singleRecipient.data).length > 0
    ) {
      setRecipient(singleRecipient.data.beneficiary_account);
    }
  }, [singleRecipient]);

  return (
    <>
      <div className="wallet-full-transaction-table">
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8}>
              {singleRecipient.loading ? (
                <>
                  <div className="create-recipient-form-back">
                    <Skeleton height={30} width={200} borderRadius={5} />
                  </div>
                  <div className="recipient-detail-box">
                    <Skeleton height={600} width={900} borderRadius={10} />
                  </div>
                </>
              ) : Object.keys(singleRecipient.data).length > 0 ? (
                <>
                  <div className="create-recipient-form-back">
                    <Link onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="#171717"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                          d="M9.57 5.93L3.5 12l6.07 6.07M20.5 12H3.67"
                        ></path>
                      </svg>
                    </Link>
                    <h2>Bank Account Details</h2>
                  </div>

                  <Row>
                    <Col md={12} lg={12} xl={12}>
                      <div className="bank-details-frame">
                        <div className="bank-details-info-card">
                          <div className="bank-details-info-head">
                            <div className="bank-details-name-card">
                              <div className="bank-details-icons-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="32"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                    data-original="#000000"
                                  ></path>
                                </svg>
                              </div>
                              <div className="bank-details-name-info">
                                <h3>
                                  {singleRecipient.data.beneficiary_account.first_name} {singleRecipient.data.beneficiary_account.last_name}
                                </h3>
                                <p>{singleRecipient.data.beneficiary_account.bank_id}</p>
                              </div>
                            </div>
                            <div className="bank-details-type-card">
                              <p className="bank-type">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 16 17"
                                >
                                  <path
                                    stroke="#252525"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                                  ></path>
                                </svg>
                                <span>{singleRecipient.data.beneficiary_account.account_type}</span>
                              </p>
                              <h5> {singleRecipient.data.beneficiary_account.account_number}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="bank-personal-details-card">
                          <div className="personal-user-id">
                            <h4>
                              User Id :<strong>{singleRecipient.data.beneficiary_account.unique_id}</strong>
                            </h4>
                          </div>
                          <div className="personal-user-titles">
                            <h2>Beneficiary</h2>
                          </div>
                          <div className=" benefi-user-info-card">
                            <div className="user-info-card">
                              <h3>
                                {" "}
                                {/* {singleRecipient.data.beneficiary_account.ifsc_code == null
                                  ? t("iban_code")
                                  : t("ifsc_code")} */}
                                  Bank Code
                              </h3>
                              <h5>
                                {singleRecipient.data.beneficiary_account.ifsc_code == null
                                  ? singleRecipient.data.beneficiary_account.code
                                  : singleRecipient.data.beneficiary_account.code}
                              </h5>
                            </div>
                            {/* <div className="user-info-card">
                              <h3>{t("recipient_country")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.recipient_country}</h5>
                            </div> */}
                            <div className="user-info-card">
                              <h3>{t("receiving_currency")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.receiving_currency}</h5>
                            </div>
                          </div>

                          <div className="user-info-space"></div>

                          <div className="personal-user-titles">
                            <h2>Personal Details</h2>
                          </div>
                          <div className="personal-user-info-card">
                            <div className="user-info-card">
                              <h3>{t("email")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.email}</h5>
                            </div>
                            <div className="user-info-card">
                              <h3>{t("phone_number")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.mobile}</h5>
                            </div>
                            <div className="user-info-card">
                              <h3>{t("country")}</h3>
                              <h5>
                                {" "}
                                {
                                  countriesData.find(
                                    (country) =>
                                      country.code === singleRecipient.data.beneficiary_account.additional_details.country
                                  ).name
                                }
                              </h5>
                            </div>
                            <div className="user-info-card">
                              <h3>{t("state")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.additional_details.state}</h5>
                            </div>
                            <div className="user-info-card">
                              <h3>{t("city")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.additional_details.city}</h5>
                            </div>
                            <div className="user-info-card">
                              <h3>{t("zip_code")}</h3>
                              <h5>{singleRecipient.data.beneficiary_account.additional_details.postal_code}</h5>
                            </div>
                          </div>
                          <div className="user-info-space"></div>
                          <div className="personal-user-info-address">
                            <div className="user-info-card">
                              <h3>{t("full_address")}</h3>
                              <h5>
                                {singleRecipient.data.beneficiary_account.additional_details.primary_address},{" "}
                                {singleRecipient.data.beneficiary_account.additional_details.secondary_address},{singleRecipient.data.beneficiary_account.additional_details.city},{" "}
                                {singleRecipient.data.beneficiary_account.additional_details.state}, {singleRecipient.data.beneficiary_account.additional_details.postal_code}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewRecipientDetail;
