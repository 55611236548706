import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const HowItsWork = (props) => {

  const t = useTranslation("how_its_work")

  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container fluid>
        <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2 data-aos="fade-up">What We Provide</h2>
                        <p data-aos="fade-up">We offer secure and easy ways to buy, sell, exchange, and trade cryptocurrencies within the platform.</p>
                    </div>
                </Col>
            </Row>
          <div className="how-its-work-box" data-aos="fade-up">
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/buy-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Buy Crypto Instantly</h4>
                <p>Easily convert USD into a wide range of cryptocurrencies. With fast and secure transactions, you can purchase crypto using your USD balance directly within the platform. The crypto is deposited into your HeraldEX wallet for safe storage or immediate use in trading.
                </p>
                <div className="learn-more-btn-sec">
                  <Link to="/buy" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/sell-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Sell Crypto Effortlessly</h4>
                <p>Sell your cryptocurrency directly within the platform and convert it into real money. The proceeds are deposited into your wallet, where you can choose to withdraw in your preferred local currency from over 49 countries. This provides a quick and secure access to funds.</p>
                <div className="learn-more-btn-sec">
                  <Link to="/sell" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/swap-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Exchange Crypto Seamlessly</h4>
                <p>Quickly swap one cryptocurrency for another at real-time market rates. The process is seamless, secure, and doesn't require external wallets, making it easy to manage your crypto portfolio directly within the platform.</p>
                <div className="learn-more-btn-sec">
                  <Link to="/exchange" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/trade-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Trade Crypto Professionally</h4>
                <p>Trade digital assets at real-time market prices with advanced features. Enjoy a secure, seamless experience with various crypto pairs, allowing you to monitor trends, set limits, and make informed trades directly within your account.</p>
                <div className="learn-more-btn-sec">
                  <Link to="/trading" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HowItsWork;
