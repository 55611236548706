import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav } from "react-bootstrap";
import { exchangeTokensViewStart } from "../../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import CustomLazyLoad from "../../helper/CustomLazyLoad";
import NoDataFound from "../../helper/NoDataFound";

const ExchangeTransactionViewModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const t = useTranslation("transactions");
  const exchangeTokensView = useSelector(
    (state) => state.buy.exchangeTokensView
  );

  useEffect(() => {
    dispatch(
      exchangeTokensViewStart({
        exchange_id: transactionId,
      })
    );
  }, []);

  const formatNumber = (number) => {
    if (number) {
      return number
        .replace(/\s+/g, "")
        .split("")
        .reduce((seed, next, index) => {
          if (index !== 0 && index % 4 === 0) {
            seed += " ";
          }
          return seed + next;
        }, "");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Exchange Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {exchangeTokensView.loading ? (
            <>
              {/* <div className="trans-view-grid-layout">
                <Skeleton height={270} />
                <Skeleton height={270} />
              </div> */}
              <Skeleton height={200} className="mb-3" />
              <Skeleton height={200} className="mb-3" />
            </>
          ) : Object.keys(exchangeTokensView.data).length > 0 ? (
            <div className="transaction-view-body">
              <div className="transaction-basic-info-wrap">
                <div className="transaction-basic-info-head">
                  <div className="transaction-basic-info-head-titles">
                    <h3>
                      {exchangeTokensView.data.exchange.to_token_amount.slice(
                        0,
                        10
                      )}{" "}
                      {exchangeTokensView.data.exchange.to_token}
                    </h3>
                    <p>Tokens Transferred</p>
                  </div>
                  {/* <div className="transaction-basic-info-head-titles">
                  <Button className="action-btn overlay">
                    {" "}
                    View Transaction Hash{" "}
                  </Button>
                </div> */}
                </div>
                <div className="transaction-space"></div>
                <div className="transaction-basic-info-content">
                  <div className="transaction-basic-content-card">
                    <p>Amount Paid</p>
                    <h5>
                      {exchangeTokensView.data.exchange.from_token_amount.slice(
                        0,
                        10
                      )}{" "}
                      {exchangeTokensView.data.exchange.from_token}{" "}
                    </h5>
                  </div>
                  <div className="transaction-basic-content-card">
                    <p>Status</p>
                    {/* <div className="pay-badge succes">Success</div> */}
                    {/* <p
                      className={`status ${
                        exchangeTokensView.data.exchange.status == 1
                          ? "succes"
                          : exchangeTokensView.data.exchange.status == 2? "denied":"pending"
                      } `}
                    >
                      {exchangeTokensView.data.exchange.status_formatted}
                    </p> */}
                    <div
                      className={`table_badge  ${
                        exchangeTokensView.data.exchange.status == 1 ||
                        exchangeTokensView.data.exchange.status == 5
                          ? "success"
                          : exchangeTokensView.data.exchange.status == 0
                          ? "pending"
                          : "fail"
                      }`}
                    >
                      {" "}
                      {exchangeTokensView.data.exchange.status_formatted}
                    </div>
                  </div>
                </div>
              </div>
              <div className="transaction-basic-tab-wrap out_space_mt_1">
                <Tab.Container
                  id="transaction-basic-tab"
                  defaultActiveKey="transaction"
                >
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="transaction" className="view_tab_btn">
                        Transaction Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="history" className="view_tab_btn">
                        History
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="transaction">
                      <div className="transaction-tab-frame">
                        <div className="transaction-tab-info-card">
                          <p>Exchange Value</p>
                          <h4>
                            {
                              exchangeTokensView.data.exchange
                                .adjusted_exchange_rate_formatted
                            }
                          </h4>
                        </div>
                        <div className="transaction-tab-info-card">
                          <p>Transaction Date</p>
                          <h4>
                            {exchangeTokensView.data.exchange.created_at}{" "}
                          </h4>
                        </div>
                        {/* <div className="transaction-tab-info-card">
                          <p>Commission Rate(%)</p>
                          <h4>{exchangeTokensView.data.exchange.commission_rate}</h4>
                        </div> */}
                        {(exchangeTokensView.data.exchange.service_fee && exchangeTokensView.data.exchange.commission > 0) ? (
                          <div className="transaction-tab-info-card">
                            <p>Service Fee</p>
                            <h4>
                              {exchangeTokensView.data.exchange.commission}
                              {exchangeTokensView.data.exchange.service_fee}
                            </h4>
                          </div>
                        ) : null}
                        {exchangeTokensView.data.exchange
                          .received_transaction_hash ? (
                          <div className="transaction-tab-info-card">
                            <p>Transaction Hash</p>
                            <h4 className="copy-view-card">
                              {" "}
                              <span>
                                {exchangeTokensView.data.exchange
                                  .received_transaction_hash.length > 10
                                  ? `${exchangeTokensView.data.exchange.received_transaction_hash.slice(
                                      0,
                                      5
                                    )}...${exchangeTokensView.data.exchange.received_transaction_hash.slice(
                                      -5
                                    )}`
                                  : exchangeTokensView.data.exchange
                                      .received_transaction_hash}
                              </span>{" "}
                              <CopyToClipboard
                                text={
                                  exchangeTokensView.data.exchange
                                    .received_transaction_hash
                                }
                                onCopy={() =>
                                  getSuccessNotificationMessage(
                                    "Transaction Hash Copied"
                                  )
                                }
                              >
                                <Button className="action-btn copy-btn item_center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h4>
                          </div>
                        ) : null}
                        {exchangeTokensView.data.exchange.transaction_hash ? (
                          <div className="transaction-tab-info-card">
                            <p>Transaction ID</p>
                            <h4 className="copy-view-card">
                              {" "}
                              <span>
                                {exchangeTokensView.data.exchange
                                  .transaction_hash.length > 10
                                  ? `${exchangeTokensView.data.exchange.transaction_hash.slice(
                                      0,
                                      5
                                    )}...${exchangeTokensView.data.exchange.transaction_hash.slice(
                                      -5
                                    )}`
                                  : exchangeTokensView.data.exchange
                                      .transaction_hash}
                              </span>{" "}
                              <CopyToClipboard
                                text={
                                  exchangeTokensView.data.exchange
                                    .transaction_hash
                                }
                              >
                                <Button
                                  className="action-btn copy-btn item_center"
                                  onClick={() =>
                                    getSuccessNotificationMessage(
                                      "Transaction ID Copied"
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h4>
                          </div>
                        ) : null}
                        <div className="transaction-tab-info-card">
                          <p>Receiver Wallet Address</p>
                          <h4 className="copy-view-card">
                            {" "}
                            <span>
                              {exchangeTokensView.data.exchange
                                .receiver_wallet_address.length > 10
                                ? `${exchangeTokensView.data.exchange.receiver_wallet_address.slice(
                                    0,
                                    5
                                  )}...${exchangeTokensView.data.exchange.receiver_wallet_address.slice(
                                    -5
                                  )}`
                                : exchangeTokensView.data.exchange
                                    .receiver_wallet_address}
                            </span>
                            <CopyToClipboard
                              text={
                                exchangeTokensView.data.exchange
                                  .receiver_wallet_address
                              }
                              onCopy={() =>
                                getSuccessNotificationMessage("Copied")
                              }
                            >
                              <Button className="action-btn copy-btn item_center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#298BFF"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                  <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </h4>
                        </div>
                        <div className="transaction-tab-info-card">
                          <p>Type</p>
                          <h4>Exchange</h4>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="history">
                      <div className="bank-tab-info-wrap">
                        <div className="transaction-tab-status-wrap">
                          <div className="transaction-tab-status-titles">
                            Transaction Status
                          </div>
                          <div className="transaction-tab-status-card">
                            {exchangeTokensView.data.exchange.history.length >
                              0 &&
                              exchangeTokensView.data.exchange.history.map(
                                (item) => (
                                  <div className="transaction-tab-status-info">
                                    {item}
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExchangeTransactionViewModal;
