import { all, fork } from "redux-saga/effects";
import WalletConnectorSaga from "./WalletConnectorSaga";
import UserSaga from "./UserSaga";
import PageSaga from "./PageSaga";
import BankAccountSaga from "./BankAccountSaga";
import BuySaga from "./BuySaga";
import WalletSaga from "./WalletSaga";
import TransferSaga from "./TransferSaga";
import RecipientSaga from "./RecipientSaga";

export default function* rootSaga() {
  yield all([
    fork(WalletConnectorSaga),
    fork(UserSaga),
    fork(PageSaga),
    fork(BankAccountSaga),
    fork(BuySaga),
    fork(WalletSaga),
    fork(TransferSaga),
    fork(RecipientSaga),
  ]);
}
