import React from "react";
import NoDataFound from "../../helper/NoDataFound";

const ProfitStock = (props) => {
  return (
    <>
      <div className="trade-stock-list-table">
        <div className="stock-list-table-head">
          <div className="stock-list-table-heading">
            Price({props?.currencyPair?.currency2})
          </div>
          <div className="stock-list-table-heading">
            Amount({props?.currencyPair?.currency1})
          </div>
          {/* <div className="stock-list-table-heading">Time</div> */}
        </div>
        {props?.buyTrade?.length > 0 ?
        <div className="trade-stock-list-box trade-h-a profit-bg">
          {props?.buyTrade?.length > 0 &&
            props.buyTrade.map((data) => (
              <div className="trade-stock-row">
                <div className="trade-stock-col profit">{data.price} </div>
                <div className="trade-stock-col">{data.value} </div>
                {/* <div className="trade-stock-col">15:30:13 </div> */}
              </div>
            ))}
        </div>:<NoDataFound/>}
      </div>
      {/* <div className="trade-level">
        <div className="trade-level-profit">
          <span>B</span>
          <div className="trade-level-profit-info">65.06% </div>
        </div>
        <div className="trade-level-loss">
          <div className="trade-level-profit-info">35.94%</div>
          <span>S</span>
        </div>
      </div> */}
    </>
  );
};

export default ProfitStock;
