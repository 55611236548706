import React, { useEffect, useRef, useState } from "react";
import {
  InputGroup,
  Table,
  Button,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { recipientListStart } from "../../store/actions/RecipientAction";
import NoDataFound from "../helper/NoDataFound";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import NoBankAccountData from "../helper/NoBankAccountData";

const RecipientList = () => {
  const formRef = useRef();
  const t = useTranslation("recipient.recipient_list");
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const recipientList = useSelector((state) => state.recipient.recipientList);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
  });

  useEffect(() => {
    dispatch(recipientListStart(filter));
  }, [filter]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  return (
    <>
      <div className="wallet-full-transaction-table">
        <Container fluid>
          <Row>
            <Col md={12}>
              {recipientList.loading ? (
                <>
                  <div className="recipient-table-head">
                    <div className="recipient-table-heading">
                      <Skeleton height={30} width={150} />
                    </div>
                    <div className="recipient-table-action">
                      <Skeleton height={45} width={350} borderRadius={5} />
                      <Skeleton height={48} width={150} borderRadius={5} />
                    </div>
                  </div>
                  <div className="wallet_table">
                    {[...Array(8)].map(() => (
                      <Skeleton height={70} borderRadius={10} />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="recipient-table-head">
                    <div className="recipient-table-heading">
                      <h3>{t("content")}</h3>
                    </div>
                    <div className="recipient-table-action">
                      {((Object.keys(recipientList.data).length > 0 &&
                        recipientList.data?.total_beneficiary_accounts > 0) ||
                        filter.search_key !== "") && (
                        <Formik
                          initialValues={{
                            search_key: searchParams.get("search_key")
                              ? searchParams.get("search_key")
                              : "",
                          }}
                          validationSchema={validationSchema}
                          onSubmit={(val) => {
                            setFilter({
                              ...filter,
                              search_key: val.search_key,
                            });

                            navigate(
                              searchParams.get("sort_by")
                                ? `/recipient-list?search_key=${
                                    val.search_key
                                  }&search_key=${searchParams.get(
                                    "search_key"
                                  )}`
                                : `/recipient-list?search_key=${val.search_key}`
                            );
                          }}
                          innerRef={formRef}
                        >
                          {({
                            values,
                            setFieldValue,
                            resetForm,
                            submitForm,
                          }) => (
                            <FORM className="form transaction-form">
                              <InputGroup className="mb-0">
                                <Field
                                  name="search_key"
                                  onChange={(e) => {
                                      e.target.value == "" && submitForm();
                                      formRef.current.setFieldValue(
                                        "search_key",
                                        e.target.value
                                      );
                                  }}
                                  placeholder={t("search")}
                                  className="form-control"
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      event.preventDefault();
                                      formRef.current.handleSubmit();
                                    }
                                  }}
                                />
                                <InputGroup.Text id="basic-addon2">
                                  {searchParams.get("search_key") ? (
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setFieldValue("search_key", "");
                                        setFilter({
                                          ...filter,
                                          search_key: "",
                                        });
                                        navigate(
                                          searchParams.get("sort_by")
                                            ? `/recipient-list?sort_by=${searchParams.get(
                                                "search_key"
                                              )}`
                                            : `/recipient-list`
                                        );
                                      }}
                                    >
                                      <svg
                                        height="24"
                                        width="24"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                        focusable="false"
                                        fill="#333"
                                      >
                                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                      </svg>
                                    </Button>
                                  ) : (
                                    <Button type="submit">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#171717"
                                          d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75zM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z"
                                        ></path>
                                      </svg>
                                    </Button>
                                  )}
                                </InputGroup.Text>
                              </InputGroup>
                            </FORM>
                          )}
                        </Formik>
                      )}
                      <Button
                        className="action-btn primary item_flex_x_5"
                        onClick={() =>
                          profile.data.kyc_verified == 1
                            ? navigate("/create-personal-recipient")
                            : getErrorNotificationMessage("Kyc is not verified")
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#111"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 6a1 1 0 00-1 1v4H7a1 1 0 000 2h4v4a1 1 0 002 0v-4h4a1 1 0 000-2h-4V7a1 1 0 00-1-1z"></path>
                        </svg>
                        <span>{t("heading")}</span>
                      </Button>
                    </div>
                  </div>
                  {Object.keys(recipientList.data).length > 0 &&
                  recipientList.data?.total_beneficiary_accounts > 0 ? (
                    <div className="wallet_table">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t("id")}</th>
                            <th>{t("name")}</th>
                            <th>Bank Country</th>
                            <th>Bank Currency</th>
                            <th>Account Number</th>
                            <th>Created</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        {recipientList.data.beneficiary_accounts.map(
                          (recipient, index) => (
                            <tbody>
                              <tr>
                                <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.unique_id}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.first_name + " " + recipient.last_name}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.recipient_country}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.receiving_currency}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.account_number}
                                    </p>
                                  </div>
                                </td>
                                {/* <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.city}
                                    </p>
                                  </div>
                                </td> */}
                                <td>
                                  <div className="table_value">
                                    <p className="table-bold-text">
                                      {recipient.created_at_formatted}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    className="action-btn link-btn"
                                    onClick={() =>
                                      navigate(`/recipient-detail/${recipient.id}`)
                                    }
                                  >
                                    {t("view")}
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </Table>
                    </div>
                  ) : (
                   <NoBankAccountData />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RecipientList;
