import React, { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateBuyData } from "../store/actions/UserAction";
import { useTranslation } from "react-multi-lang";
import {
  getAdminBankAccountStart,
  tokenBuyStart,
} from "../store/actions/BuyAction";
import CommonCenterLoader from "../components/helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import { setTokenBuyData } from "../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../components/helper/ToastNotification";

const TokenBuy = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("buy_index");
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const buyData = useSelector(state => state.user.buyData);
  const setBuyData = (data) => dispatch(updateBuyData(data));
  const adminBankAccount = useSelector((state) => state.buy.adminBankAccount);
  const updateTokenBuyData = (data) => dispatch(setTokenBuyData(data));
  const tokenBuy = useSelector((state) => state.buy.tokenBuy);

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    payment_id: Yup.string().required(t("payment_id.required"))
      .min(3, t("payment_id.invalid")),
  });

  useEffect(() => {
    dispatch(getAdminBankAccountStart());
  }, []);


  useEffect(() => {
    if (
      adminBankAccount.data &&
      Object.keys(adminBankAccount.data).length > 0
    ) {
      setBuyData({
        ...buyData,
        admin_bank_account_id: adminBankAccount.data.admin_bank_account.admin_bank_account_id,
      });
      updateTokenBuyData({
        ...tokenBuyData,
        admin_bank_account_id: adminBankAccount.data.admin_bank_account.admin_bank_account_id,
      })
    }
  }, [adminBankAccount]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => setFiles([])}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <Image
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const onSubmit = (values) => {
    if (files.length > 0) {
      dispatch(tokenBuyStart({
        ...tokenBuyData,
        payment_id: values.payment_id,
        payment_file: files[0],
      }))
    }
    else {
      getErrorNotificationMessage(t("please_upload_payment_screenshot"))
    }
  };

  useEffect(() => {
    if (!skipRender && !tokenBuy.loading && Object.keys(tokenBuy.data).length > 0) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false)
  }, [tokenBuy]);


  return (
    <>
      <div className="single-page-header">

      </div>
      {adminBankAccount.loading ? (
        <CommonCenterLoader />
      ) : Object.keys(adminBankAccount.data).length > 0 ? (
        <div>
          <div className="back-arrow-btn-sec" onClick={() => updateTokenBuyData({ ...tokenBuyData, step: 1 })}>
            <Image
              src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
              className="back-arrow-btn"
            />
            <h4>{t("bank_account_details")}</h4>
          </div>
          <hr />
          <div className="bank-details-full-card">
            <div className="bank-details-full-card-top">
              <div className="bank-details-card-top-img">
                <Image
                  src={window.location.origin + "/img/bank/hdfc-bank.svg"}
                  className="bank-logo-frame"
                />
              </div>
              <div className="bank-details-card-top-info">
                <h4> {adminBankAccount.data.admin_bank_account.account_holder_name}</h4>
                <p> {adminBankAccount.data.admin_bank_account.bank_name}</p>
              </div>
            </div>
            <div className="bank-details-full-card-middle">
              <h4>
                {t("account_number")}: &nbsp;
                {adminBankAccount.data.admin_bank_account.account_number}
              </h4>
              <h4>
                {t("ifsc_code")}: &nbsp;
                {adminBankAccount.data.admin_bank_account.ifsc_code}
              </h4>
            </div>
            <div className="bank-details-full-card-bottom">
              <div className="bank-details-card-top-ifsc-code-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span> {adminBankAccount.data.admin_bank_account.bank_name}</span>
              </div>
              <div className="bank-details-card-top-ifsc-code-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 16 17"
                >
                  <path
                    stroke="#252525"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                  ></path>
                </svg>
                <span>{t("savings_account")}</span>
              </div>
            </div>
          </div>
          <hr />
          <Formik
            initialValues={{
              payment_id: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ touched, errors }) => (
              <FORM className="exchange-form">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("payment_id.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("payment_id.placeholder")}
                    name="payment_id"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="payment_id"
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("payment_picture")}</Form.Label>
                  <div
                    {...getRootProps()}
                    style={{ border: "1px dashed #f7f7f7" }}
                  >
                    <input {...getInputProps()} />
                    {files.length <= 0 && (
                      <div className="add-product-upload-box">
                        <Image
                          src={window.location.origin + "/img/upload.png"}
                          className="upload-icon"
                        />
                        <p>
                          {t("upload_your_payment_screenshot_here")}
                          <br /> <span> {t("accept_only_jpeg_jpg_png")}</span>
                        </p>
                      </div>
                    )}
                    <div>{images}</div>
                  </div>
                </Form.Group>
                <div className="exchange-btn-sec">
                  <Button
                    className="default-btn"
                    type="submit"
                    disabled={tokenBuy.buttonDisable}
                  >
                    {tokenBuy.buttonDisable ? t("loading") : t("continue")}
                  </Button>

                </div>
              </FORM>
            )}
          </Formik>
        </div>
      ) : (
        <div className="no-bank-details-sec exchange-btn-sec">
          <Image
            src={window.location.origin + "/img/no-bank-found.png"}
            className="no-data-found-img"
          />
          <p>{t("no_bank_details_found")}</p>
          <Button
            className="default-btn w-100"
            onClick={() => dispatch(getAdminBankAccountStart())}
          >
            {t("retry")}
          </Button>
        </div>
      )}
    </>
  );
};

export default TokenBuy;
