import React, { useState } from "react";

const EmptyLayout = (props) => {

  return (
    <>
    {props.children}
    </>
  );
};

export default EmptyLayout;
