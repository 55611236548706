import React from "react";
import { Link } from "react-router-dom";
import { Form, InputGroup, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { swapTokenStart, updateSwapData } from "../../store/actions/UserAction";
import Processing from "../Common/Processing";
import SwapSuccess from "./SwapSuccess";
import SwapFailure from "./SwapFailure";
import { useState } from "react";
import configuration from "react-global-configuration";
import { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";
import { useTranslation } from "react-multi-lang";

const SwapCryptoPayment = () => {

  const dispatch = useDispatch();
  const t = useTranslation("exchange_index");

  const swapToken = useSelector((state) => state.user.swapToken);
  const swapData = useSelector((state) => state.user.swapData);
  const connectedWallet = useSelector((state) => state.walletConnector.connectedWallet);
  const setSwapData = (data) => dispatch(updateSwapData(data));

  const [isLoading, setIsLoading] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  
  const constants = configuration.get("configData");

  useEffect(() => {
    setSkipRender(false);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      swapTokenStart({
        token_type: swapData.token_type.symbol,
        swap_token_type: swapData.swap_token_type.symbol,
        swap_tokens: swapData.swap_token,
        receiver_wallet_address: swapData.receiver_wallet_address,
        swap_transaction_hash: swapData.swap_transaction_hash,
      })
    );
    setSwapData({
      ...swapData,
      step: swapData.step + 1,
    });
  };

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} t("was_copied_to_clipboard")`);
  };

  return (
    <>
      {swapData.step === 4 ? (
        <Form className="exchange-form" onSubmit={onSubmit}>
          <div className="receive-token-sec">
            <h5>{t("crypto_payment")}</h5>
          </div>
          <div className="swap-scan-item">
            <div className="swap-scan-box">
              <Image
                src={window.location.origin + "/img/sell/scan-qr-code.png"}
                className="swap-scan-icon"
              />
              <p>{t("scar_qr_code")}</p>
            </div>
          </div>
          <InputGroup className="mb-4">
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={constants.admin_wallet_address}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={constants.admin_wallet_address}
                onCopy={(e) => onCopy(e, t("wallet_address"))}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                </svg>
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t("paste_your_transaction_hash")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="0xf8cd220e2687c03f3627a59023e57d57fc93434c8cc"
              value={swapData.swap_transaction_hash}
              disabled={isLoading}
              onChange={(e) =>
                setSwapData({
                  ...swapData,
                  swap_transaction_hash: e.target.value,
                })
              }
            />
          </Form.Group>
          <div className="exchange-btn-sec">
            {swapData.swap_transaction_hash ? (
              <Button className="default-btn" type="submit">
                {t("continue")}
              </Button>
            ) : null}
            <p>
              {t("by_continuing_you_agree_to_our")}{" "}
              <Link to="/page/privacy-agreement"> {t("cookie policy")}.</Link>
            </p>
          </div>
        </Form>
      ) : (
        <>
          {swapToken.loading ? (
            <Processing />
          ) : Object.keys(swapToken.data).length > 0 ? (
            <SwapSuccess />
          ) : (
            <SwapFailure />
          )}
        </>
      )}
    </>
  );
};

export default SwapCryptoPayment;
