import React, { useState, useEffect } from "react";
import { Image, Button, Form } from "react-bootstrap";
import "../NewLanding/HomeBanner.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { setTokenBuyData } from "../../store/actions/BuyAction";
import { getCurrenciesListStart } from "../../store/actions/BuyAction";
import { setUserSocket } from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import {
  createKycApplicantStart,
  profileStart,
} from "../../store/actions/UserAction";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import configuration from "react-global-configuration";
import NoDataFound from "../helper/NoDataFound";

const BuyCryptoForm = ({ redirect = false }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("buy_crypto_form");

  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const currencies = useSelector((state) => state.buy.currencies);
  const profile = useSelector((state) => state.user.profile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const kycStatusUpdate = useSelector((state) => state.user.updateKYC);
  const socket = useSelector((state) => state.user.socket);

  const [skipRender, setSkipRender] = useState(true);
  const [toCurrencyValue, setToCurrencyValue] = useState(0);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [forexOptions, setForexOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);
  const [kyc, setKyc] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [validDecimalValue, setValidDecimalValue] = useState();
  const [invalidAmount, setInvalidAmount] = useState({
    min: false,
    max: false
  });

  const updateTokenBuyData = (data) => dispatch(setTokenBuyData(data));

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
  }, []);

  useEffect(() => {
    if (
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {

      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item?.currency_code,
        value: item.id,
      }));

      setCategoriesOptions(categories);

      const selected_crypto = tokenBuyData.token_type
        ? currencies.data.crypto_currencies.find(
          (category) => category?.currency_code == tokenBuyData.token_type
        )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenBuyData.token_type
          ? categories.find(
            (category) => category.label == tokenBuyData.token_type
          )
          : categories[0]
      );

      const forexCurrency = currencies.data?.buy_forex_currencies?.map((forex) => ({
        label: forex.currency_code,
        value: forex.id,
      }));

      setForexOptions(forexCurrency);

      const selected_forex = tokenBuyData?.buy_via_currency
        ? currencies.data?.buy_forex_currencies?.find(
          (forex) => forex.currency_code == tokenBuyData?.buy_via_currency
        )
        : currencies.data?.buy_forex_currencies?.[0];

      setSelectedForex(
        tokenBuyData.buy_via_currency
          ? forexCurrency.find(
            (forex) => forex.label == tokenBuyData.buy_via_currency
          )
          : forexCurrency?.[0]
      );
      updateTokenBuyData({
        ...tokenBuyData,
        minAmount:
          parseInt(
            currencies.data?.user_commissions?.min_buy_amount
          ) <= 0
            ? 0.0000001
            : parseInt(
              currencies.data?.user_commissions?.min_buy_amount
            ).toFixed(8),
        maxAmount:
          parseInt(
            currencies.data?.user_commissions?.max_buy_amount
          ) <= 0
            ? 100000
            : parseInt(
              currencies.data?.user_commissions?.max_buy_amount
            ).toFixed(8),
        token_type: selected_crypto?.currency_code,
        crypto_exchange_rate: selected_crypto?.exchange_rate,
        crypto_source_currency: selected_crypto?.source_currency,
        buy_via_currency: selected_forex?.currency_code,
        forex_exchange_rate: selected_forex?.exchange_rate,
        forex_source_currency: selected_forex?.source_currency,
        buy_commission: selected_forex?.buy_commission,
      });

      const cryptoExchangeUrl = configuration.get("configData.socket_url") != ""
        ? configuration.get("configData.socket_url")
        : "wss://cms-proexchange.rare-able.com:3091";

      const socket = new WebSocket(`${cryptoExchangeUrl}?key=${profile.data.user_unique_id}`);

      socket.onopen = () => {
        const subscribeMessage = JSON.stringify({
          type: "tradable-order-book",
          pair: selected_crypto?.currency_code + "-USD",
        });
        socket.send(subscribeMessage);
        dispatch(setUserSocket(socket));
      };

      socket.onmessage = (event) => {
        try {

          const parsedData = JSON.parse(event.data);
          // console.log(parsedData)
          if (parsedData.buy.length > 0) {
            setExchangeRate(parsedData.buy[0][0]);
          }

        } catch (error) { }
      };
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
    setSkipRender(false);
  }, [currencies]);

  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find(
      (crypto) => crypto.id == selectedOption.value
    );
    updateTokenBuyData({
      ...tokenBuyData,
      crypto_exchange_rate: crypto.exchange_rate,
      crypto_source_currency: crypto.source_currency,
      token_type: selectedOption.label,
    });
    setSelectedCrypto(selectedOption);
    let pairUnsubscribe = JSON.stringify({
      type: "tradable-order-book",
      pair: tokenBuyData.token_type + "-USD",
      action: 'unsubscribe'
    });
    socket.send(pairUnsubscribe);
    const subscribeMessage = JSON.stringify({
      type: "tradable-order-book",
      pair: selectedOption.label + "-USD",
    });
    socket.send(subscribeMessage)
  };

  useEffect(() => {
    if (
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      let decimal = (currencies.data.balance_steps?.find(item => item.currency_code == selectedForex?.label)?.balance_step);
      if (decimal) {
        decimal = decimal.slice(decimal.indexOf('.') + 1, decimal.indexOf('1') + 1).length
        setValidDecimalValue(decimal)
      }
    }
    setSkipRender(false);
  }, [currencies, selectedForex]);

  const onCryptoTokenChange = (value) => {
    let decimal = value.slice(value.indexOf('.') + 1).length;
    if (value.includes('.')) {
      if (decimal <= validDecimalValue) {
        if (Number(value) == value) {
          updateTokenBuyData({
            ...tokenBuyData,
            from_amount: value,
          });
        }
      } else {
        getErrorNotificationMessage(`You can input upto ${validDecimalValue} decimal places.`)
      }
    } else {
      if (Number(value) == value) {
        updateTokenBuyData({
          ...tokenBuyData,
          from_amount: value,
        });
      }
    }
  };

  const onFiatChange = (selectedOption) => {
    const forex = currencies.data.buy_forex_currencies.find(
      (forex) => forex.id == selectedOption.value
    );

    updateTokenBuyData({
      ...tokenBuyData,
      minAmount:
        parseInt(
          currencies.data?.user_commissions?.min_buy_amount
        ) <= 0
          ? 0.0000001
          : parseInt(
            currencies.data?.user_commissions?.min_buy_amount
          ).toFixed(8),
      maxAmount:
        parseInt(
          currencies.data?.user_commissions?.max_buy_amount
        ) <= 0
          ? 100000
          : parseInt(
            currencies.data?.user_commissions?.max_buy_amount
          ).toFixed(8),
      forex_exchange_rate: forex.exchange_rate,
      forex_source_currency: forex.source_currency,
      buy_via_currency: selectedOption.label,
      buy_commission: forex.buy_commission,
    });
    setSelectedForex(selectedOption);
  };

  const onComplete = () => {
    if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      navigate("/login");
    } else if (
      Object.keys(profile.data).length > 0 &&
      ((profile.data.user_type == 1 && profile.data.kyc_verified == 0) || (profile.data.user_type == 2 && profile.data.kyb_status != 3))
    ) {
      profile.data.user_type == 1 ? dispatch(createKycApplicantStart()) : navigate("/kyb-verification");
    } else if (redirect && !tokenBuyData.from_amount) {
      if (tokenBuyData.step == 6) {
        updateTokenBuyData({
          ...tokenBuyData,
          from_amount: tokenBuyData.from_amount,
          token_type: tokenBuyData.token_type,
          buy_via_currency: tokenBuyData.buy_via_currency,
          to_currency_conversion_value: toCurrencyValue,
        });
      }
      navigate("/buy");
    } else if (redirect && tokenBuyData.from_amount) {
      updateTokenBuyData({ ...tokenBuyData, step: 2, to_currency_conversion_value: toCurrencyValue, });
      navigate("/buy");
    } else {
      tokenBuyData.from_amount > 0
        ? updateTokenBuyData({ ...tokenBuyData, step: tokenBuyData.step + 1, to_currency_conversion_value: toCurrencyValue, })
        : getErrorNotificationMessage("Please enter valid amount");
    }
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 99999999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999999999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#7D7D7D",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      kycApplicant.data?.link && kycApplicant.data?.link !== ""
        ? window.open(kycApplicant.data?.link, "_blank")
        : dispatch(profileStart());
    }
    setSkipRender(false);
  }, [kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
      window.location.reload();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    setToCurrencyValue(!isNaN(
      tokenBuyData.from_amount * (1 / exchangeRate)) && exchangeRate != 0 ?
      (tokenBuyData.from_amount * (1 / exchangeRate)).toFixed(8) : 0.00
    );

    setInvalidAmount({
      min: tokenBuyData.from_amount < tokenBuyData.minAmount || tokenBuyData.from_amount == "" || tokenBuyData.from_amount <= 0,
      max: parseFloat(tokenBuyData.from_amount) > tokenBuyData.maxAmount
    })
  }, [exchangeRate, tokenBuyData]);

  return (
    <>
      {currencies.loading ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton count={1} borderRadius={5} height={177} />
              ))}
            </div>
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton
                  count={1}
                  className="mt-3"
                  borderRadius={5}
                  height={177}
                />
              ))}
            </div>
            <div className="efi-swap-icons">
              <Skeleton count={1} circle={true} width={70} height={70} />
            </div>
          </div>

          <Skeleton count={1} height={50} borderRadius={10} />

          <div className="efi-swap-action">
            <Skeleton count={1} height={50} borderRadius={10} />
          </div>
          <div className="d-flex justify-content-center align-item-center ">
            <Skeleton count={1} height={22} width={100} borderRadius={10} />
          </div>
        </div>
      ) : Object.keys(currencies.data).length > 0 && currencies.data?.buy_forex_currencies?.length > 0 && currencies.data?.crypto_currencies?.length > 0 ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("send")}</div>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenBuyData.from_amount}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        onCryptoTokenChange(newValue <= 100000 ? newValue : tokenBuyData.from_amount);
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>
              {currencies.data.buy_forex_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenBuyData.buy_via_currency
                          ? currencies.data?.buy_forex_currencies?.find(
                            (item) =>
                              item.currency_code ==
                              tokenBuyData.buy_via_currency
                          ).picture
                          : currencies.data?.buy_forex_currencies?.[0]?.picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenBuyData.buy_via_currency}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop half-sized"
                    options={forexOptions}
                    styles={customStyles}
                    isSearchable={false}
                    value={selectedForex}
                    onChange={(selectedOption) => onFiatChange(selectedOption)}
                  />
                </div>
              )}
            </div>
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("recieve")}</div>
                <div className="efi-receive-text">
                  {tokenBuyData.from_amount > 0
                    ?
                    toCurrencyValue
                    : "0.0000"}
                </div>
              </div>
              {currencies.data.crypto_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenBuyData.token_type
                          ? currencies.data?.crypto_currencies?.find(
                            (item) =>
                              item.currency_code == tokenBuyData.token_type
                          ).picture
                          : currencies.data?.crypto_currencies?.[0]?.picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenBuyData.token_type}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop half-sized"
                    isSearchable={false}
                    options={categoriesOptions}
                    styles={customStyles}
                    value={selectedCrypto}
                    onChange={(selectedOption) => {
                      setExchangeRate(0)
                      onCryptoChange(selectedOption)
                    }}
                  />
                </div>
              )}
            </div>

            <div className={`efi-swap-icons`}>
              <Image
                src={window.location.origin + "/img/icon/swap.svg"}
                className="swap-icon"
              />
            </div>
          </div>
          {invalidAmount.min && (
            <p className="error-msg text-danger select-date buy-form">
              {t("invalid", { value: tokenBuyData.minAmount.toFixed(8) })}
            </p>
          )}
          {invalidAmount.max && (
            <p className="error-msg text-danger select-date buy-form">
              {t("max_invalid", { value: tokenBuyData.maxAmount })}
            </p>
          )}

          {/* {(exchangeRate > 0) ? */}
          <div className="efi-swap-exchange-info efi-info-frame">
            <p>
              {" "}
              <span> {t("estimated_rate")} </span>{" "}
              <span>
                {1}{" "}
                {currencies.data.buy_forex_currencies.length > 0
                  ? tokenBuyData.buy_via_currency
                  : "N/A"}
                =
                {exchangeRate != 0 ? (1 / exchangeRate).toFixed(8) : 0.00}{" "}
                {tokenBuyData.token_type}
              </span>

            </p>
          </div>
          {/* : null} */}

          <div className="efi-swap-caution-part">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 32 32"
            >
              <g id="Layer_20" data-name="Layer 20">
                <path fill="#fbd63b" d="M28 28H4L16 6z"></path>
                <path fill="#f8be31" d="m16 6 12 22H16z"></path>
                <path fill="#333" d="m16 21-2-7h4z"></path>
                <circle cx="16" cy="25" r="1" fill="#333"></circle>
                <path d="M16 14h2l-2 7zM16 24a1 1 0 0 1 0 2z"></path>
              </g>
            </svg>
            <div className="caution-info">
              {t('caution')}
            </div>
          </div>
          <div className="efi-swap-action">
            <Button
              className="action-btn primary w-100 item_flex_x_5 justify-content-center"
              disabled={
                invalidAmount.min || invalidAmount.max ||
                kycApplicant.buttonDisable || !(exchangeRate > 0)
              }
              onClick={onComplete}
            >
              {redirect ? t("exchange") : t("continue")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#171717"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M18.01 19.99A9.964 9.964 0 0112 22c-5.52 0-8.89-5.56-8.89-5.56m0 0h4.52m-4.52 0v5M22 12c0 1.82-.49 3.53-1.34 5M6.03 3.97A9.921 9.921 0 0112 2c6.67 0 10 5.56 10 5.56m0 0v-5m0 5h-4.44M2 12c0-1.82.48-3.53 1.33-5"
                ></path>
              </svg>
            </Button>
          </div>
          <div className="efi-swap-exchange-info">
            <p>{t("no_extra_fees")}</p>
          </div>
        </div>
      ) : <NoDataFound />}
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
    </>
  );
};

export default BuyCryptoForm;
