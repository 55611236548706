import api from "../components/Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../components/helper/ToastNotification";
import { logoutSuccess } from "./actions/UserAction";
import store from './index';


export const getCommonSaga = async ({
  apiUrl,
  payload,
  success,
  failure,
  successCallback = false,
  failureCallback = false,
  successNotify = true,
  errorNotify = true,
}) => {
  try {
    const response = await api.postMethod(apiUrl, payload);
    if (response.data.success) {
      if (successCallback) success(response.data.data);
      else store.dispatch(success(response.data.data));
      if (successNotify) getSuccessNotificationMessage(response.data.message);
    } else {
      if (response.data.error_code == 401) {
        store.dispatch(logoutSuccess({ success: true }))
        return
      }
      else if (failureCallback) failure(response.data.error);
      else store.dispatch(failure(response.data.error));
      if (errorNotify) getErrorNotificationMessage(response.data.error);
    }
  } catch (error) {
    if (failureCallback) failure(error);
    else store.dispatch(failure(error));
    if (errorNotify) getErrorNotificationMessage(error.message);
  }
}