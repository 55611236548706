import React, { useState, useEffect } from "react";
import { Image, Button, Form } from "react-bootstrap";
import "../NewLanding/HomeBanner.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import {
  getCurrenciesListStart,
  sellTokenStart,
  setTokenSellData,
  getForexExchangeRateStart,
} from "../../store/actions/BuyAction";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import {
  createKycApplicantStart,
  profileStart,
  setUserSocket,
} from "../../store/actions/UserAction";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import configuration from "react-global-configuration";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import NoDataFound from "../helper/NoDataFound";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { networkOption } from "../helper/NetworkOption";
import ButtonStepper from "../Buy/ButtonStepper";

const SellCryptoForm = ({ redirect = false }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("sell_crypto_form");

  const currencies = useSelector((state) => state.buy.currencies);
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const forexExchangeRate = useSelector((state) => state.buy.forexExchangeRate);
  const socket = useSelector((state) => state.user.socket);
  const profile = useSelector((state) => state.user.profile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const kycStatusUpdate = useSelector((state) => state.user.updateKYC);
  const wallet = useSelector((state) => state.wallet.walletData);
  const tokenSell = useSelector((state) => state.buy.tokenSell);
  const updateTokenSellData = (data) => dispatch(setTokenSellData(data));

  const [skipRender, setSkipRender] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [toCurrencyValue, setToCurrencyValue] = useState(0);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [forexOptions, setForexOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);
  const [kyc, setKyc] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);
  const [commission, setCommission] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token") || sessionStorage.getItem("token") ? true : false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [validDecimalValue, setValidDecimalValue] = useState();

  const invalidAmount =
    tokenSellData.from_amount < tokenSellData.minAmount ||
    tokenSellData.from_amount == "" ||
    tokenSellData.from_amount <= 0;

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    dispatch(
      getCurrenciesListStart({
        user_id: profile.data?.user_id ? profile.data?.user_id : "",
      })
    );
  }, []);

  const onTokenChange = (value) => {
    let decimal = value.slice(value.indexOf('.') + 1).length;
    if (value.includes('.')) {
      if (decimal <= validDecimalValue) {
        if (Number(value) == value) {
          updateTokenSellData({
            ...tokenSellData,
            from_amount: value,
          });
        }
      } else {
        getErrorNotificationMessage(`You can input upto ${validDecimalValue} decimal places.`)
      }
    } else {
      if (Number(value) == value) {
        updateTokenSellData({
          ...tokenSellData,
          from_amount: value,
        });
      }
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !tokenSell.loading &&
      Object.keys(tokenSell.data).length > 0
    ) {
      setTransactionSuccess(true);
      setTimeout(() => {
        updateTokenSellData({
          ...tokenSellData,
          step: 3,
        });
        navigate("/sell");
      }, 3000);
    }

    setSkipRender(false);
  }, [tokenSell]);

  useEffect(() => {
    if (
      !skipRender &&
      !forexExchangeRate.loading &&
      Object.keys(forexExchangeRate.data).length > 0
    ) {
      updateTokenSellData({
        ...tokenSellData,
        to_exchange_rate:
          forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate,
      });
    }

    setSkipRender(false);
  }, [forexExchangeRate]);

  const onComplete = () => {
    if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      navigate("/login");
    } else if (
      Object.keys(profile.data).length > 0 &&
      ((profile.data.user_type == 1 && profile.data.kyc_verified == 0) ||
        (profile.data.user_type == 2 && profile.data.kyb_status != 3))
    ) {
      profile.data.user_type == 1
        ? dispatch(createKycApplicantStart())
        : navigate("/kyb-verification");
    } else if (redirect && !tokenSellData.from_amount) {
      if (tokenSellData.step == 6) {
        updateTokenSellData({
          ...tokenSellData,
          tokens: tokenSellData.from_amount,
          from_currency: tokenSellData.from_currency,
          to_currency: tokenSellData.to_currency,
        });
      }
      navigate("/sell");
    } else if (redirect && tokenSellData.from_amount) {
      if (tokenSellData.sell_type == 1) {
        dispatch(
          sellTokenStart({
            ...tokenSellData,
            admin_crypto_account_id: "1",
          })
        );
      } else {
        updateTokenSellData({
          ...tokenSellData,
          step: 2,
          to_currency_conversion_value: toCurrencyValue,
        });
        navigate("/sell");
      }
    } else {
      if (tokenSellData.sell_type == 1 && tokenSellData.from_amount > 0) {
        updateTokenSellData({
          ...tokenSellData,
          to_currency_conversion_value: toCurrencyValue,
          exchange_rate: tokenSellData.to_exchange_rate * (exchangeRate),
        })
        dispatch(
          sellTokenStart({
            ...tokenSellData,
            admin_crypto_account_id: "1",
          })
        );
      } else {
        tokenSellData.from_amount > 0
          ? updateTokenSellData({
            ...tokenSellData,
            step: tokenSellData.step + 1,
            to_currency_conversion_value: toCurrencyValue,
            exchange_rate: tokenSellData.to_exchange_rate * (exchangeRate),
          })
          : getErrorNotificationMessage("Please enter valid amount");
      }
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item.currency_code,
        value: item.id,
      }));
      setCategoriesOptions(categories);
      const selected_crypto = tokenSellData.token_type
        ? currencies.data.crypto_currencies.find(
          (category) => category.currency_code == tokenSellData.token_type
        )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenSellData.from_currency
          ? categories.find(
            (category) => category.label == tokenSellData.from_currency
          )
          : categories[0]
      );

      const forexCurrency = currencies.data.forex_currencies
        .filter((currency) => currency.id !== selected_crypto?.id)
        .map((currency) => ({
          label: currency.currency_code,
          value: currency.id,
        }));

      const selected_currency = tokenSellData.token_type
        ? currencies.data.forex_currencies.find(
          (category) => category.currency_code == tokenSellData.token_type
        )
        : currencies.data.forex_currencies.filter(
          (currency) => currency.id != selected_crypto?.id
        )[0];

      setForexOptions(forexCurrency);
      setSelectedForex(
        tokenSellData.to_currency
          ? forexCurrency.find(
            (forex) => forex.label == tokenSellData.to_currency
          )
          : forexCurrency[0]
      );
      tokenSellData.from_currency == "" &&
        updateTokenSellData({
          ...tokenSellData,
          minAmount:
            parseInt(currencies.data?.user_commissions?.min_sell_amount) <= 0
              ? 0.00000001
              : parseInt(
                currencies.data?.user_commissions?.min_sell_amount
              ).toFixed(2),
          maxAmount:
            parseInt(currencies.data?.user_commissions?.max_sell_amount) <= 0
              ? 100000
              : parseInt(
                currencies.data?.user_commissions?.max_sell_amount
              ).toFixed(2),
          from_currency: selected_crypto?.currency_code,
          to_currency: selected_currency?.currency_code,
          from_exchange_rate: selected_crypto?.exchange_rate,
          from_source_currency: selected_crypto?.source_currency,
          to_exchange_rate: selected_currency?.exchange_rate,
          to_source_currency: selected_currency?.source_currency,
          commission: currencies.data.user_commissions.sell_commission,
        });
      dispatch(
        getForexExchangeRateStart({
          currency: selected_currency?.currency_code,
          user_id: profile.data?.user_id ? profile.data?.user_id : "",
        })
      );

      const cryptoExchangeUrl =
        configuration.get("configData.socket_url") != ""
          ? configuration.get("configData.socket_url")
          : "wss://cms-proexchange.rare-able.com:3091";

      const socket = new WebSocket(`${cryptoExchangeUrl}?key=${profile.data.user_unique_id}`);

      socket.onopen = () => {
        const subscribeMessage = JSON.stringify({
          type: "tradable-order-book",
          pair: selected_crypto?.currency_code + "-USD",
        });
        socket.send(subscribeMessage);
        dispatch(setUserSocket(socket));
      };

      socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          // console.log(parsedData, parsedData.sell[0][0])
          if (parsedData.sell.length > 0) {
            setExchangeRate(parsedData.sell[0][0]);
          }

        } catch (error) { }
      };
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    const currencyValue = tokenSellData.from_amount * exchangeRate * tokenSellData.to_exchange_rate;
    const serviceFee = !isNaN(((tokenSellData.commission * currencyValue) / 100)) ? ((tokenSellData.commission * currencyValue) / 100) : 0.00;

    setCommission(serviceFee);
    setToCurrencyValue(!isNaN(
      currencyValue - serviceFee) || exchangeRate != 0 ?
      (currencyValue - serviceFee).toFixed(2) : 0.00
    )
  }, [exchangeRate, tokenSellData]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#010101",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  const customStylesone = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#010101",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      // minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.8em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      // justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find((crypto) => crypto.id == selectedOption.value);

    if (Object.keys(wallet.data).length > 0) {
      setWalletBalance(
        wallet.data?.crypto_wallets.find(
          (data) => data.currency_code == selectedOption.label
        )?.remaining_original
      );
      const percentage =
        wallet.data?.crypto_wallets.find(
          (data) => data.currency_code == selectedOption.label
        )?.remaining_original > 0 &&
        (parseFloat(tokenSellData.from_amount) /
          wallet.data?.crypto_wallets.find(
            (data) => data.currency_code == selectedOption.label
          )?.remaining_original) *
        100;

      setRangeValue(
        isNaN(percentage) ? 0 : Math.min(percentage, 100).toFixed(2)
      );
    }

    let pairUnsubscribe = JSON.stringify({
      type: "tradable-order-book",
      pair: tokenSellData.from_currency + "-USD",
      action: 'unsubscribe'
    });
    socket.send(pairUnsubscribe)
    const subscribeMessage = JSON.stringify({
      type: "tradable-order-book",
      pair: selectedOption.label + "-USD",
    });
    // console.log(subscribeMessage, "message")
    socket.send(subscribeMessage)

    updateTokenSellData({
      ...tokenSellData,
      minAmount: parseInt(currencies.data?.user_commissions?.min_sell_amount) <= 0 ? 0.0000001 : parseInt(currencies.data?.user_commissions?.min_sell_amount).toFixed(2),
      maxAmount: parseInt(currencies.data?.user_commissions?.max_sell_amount) <= 0 ? 100000 : parseInt(currencies.data?.user_commissions?.max_sell_amount).toFixed(2),
      from_currency: selectedOption.label,
      from_exchange_rate: crypto.exchange_rate,
      from_source_currency: crypto.source_currency,
      network_type: "",
      from_amount: 1,
    });
    setSelectedCrypto(selectedOption);
  };

  const onCurrencyChange = (selectedOption) => {
    const forex = currencies.data.currencies.find(
      (forex) => forex.id == selectedOption.value
    );
    if (selectedOption?.label == "USD")
      updateTokenSellData({
        ...tokenSellData,
        to_exchange_rate: forex.exchange_rate,
        to_source_currency: forex.source_currency,
        to_currency: selectedOption.label,
      });
    else {
      updateTokenSellData({
        ...tokenSellData,
        to_source_currency: forex.source_currency,
        to_currency: selectedOption.label,
      });
      dispatch(
        getForexExchangeRateStart({
          currency: selectedOption?.label,
          user_id: profile.data?.user_id ? profile.data?.user_id : "",
        })
      );
    }
    setSelectedForex(selectedOption);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      kycApplicant.data?.link && kycApplicant.data?.link !== ""
        ? window.open(kycApplicant.data?.link, "_blank")
        : dispatch(profileStart());
    }
    setSkipRender(false);
  }, [kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
      window.location.reload();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      !wallet.loading &&
      Object.keys(currencies.data).length > 0 &&
      Object.keys(wallet.data).length > 0
    ) {
      const selected_crypto = tokenSellData.token_type
        ? currencies.data.crypto_currencies.find(
          (category) => category.currency_code == tokenSellData.token_type
        )
        : currencies.data.crypto_currencies[0];
      setWalletBalance(
        wallet.data?.crypto_wallets.find(
          (data) => data.currency_code == selected_crypto?.currency_code
        )?.remaining_original
      );
    }
    setSkipRender(false);
  }, [currencies, wallet]);

  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token"))
      dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    if (tokenSellData.sell_type == 2 && !tokenSellData.network_type) {
      updateTokenSellData({
        ...tokenSellData,
        network_type: networkOption[0].value,
      });
    }
  }, [tokenSellData]);

  useEffect(() => {
    if (
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      let decimal = (currencies.data.balance_steps.find(item => item.currency_code == selectedCrypto?.label)?.balance_step);
      if (decimal) {
        decimal = decimal.slice(decimal.indexOf('.') + 1, decimal.indexOf('1') + 1).length
        setValidDecimalValue(decimal)
      }
      if (selectedCrypto && (selectedCrypto?.label != tokenSellData.from_currency)) {
        // updateTokenSellData({
        //   ...tokenSellData,
        //   from_amount: 1,
        // });
      }
    }
    setSkipRender(false);
  }, [currencies, selectedCrypto]);

  return (
    <>
      {currencies.loading ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton count={1} borderRadius={5} height={160} />
              ))}
            </div>
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton
                  count={1}
                  className="mt-3"
                  borderRadius={5}
                  height={160}
                />
              ))}
            </div>
            <div className="efi-swap-icons">
              <Skeleton count={1} circle={true} width={70} height={70} />
            </div>
          </div>
          <Skeleton count={1} height={50} />
          <div className="efi-swap-action">
            <Skeleton count={1} height={50} borderRadius={10} />
          </div>
          <div className="d-flex justify-content-center align-item-center ">
            <Skeleton count={1} height={22} width={100} borderRadius={5} />
          </div>
        </div>
      ) : Object.keys(currencies.data).length > 0 &&
        currencies.data.forex_currencies.length > 0 &&
        currencies.data.crypto_currencies.length > 0 ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("send")}</div>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenSellData.from_amount}
                      disabled={tokenSell.buttonDisable || forexExchangeRate.buttonDisable}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const remainingOriginal = walletBalance || 0;
                        const percentage = (parseFloat(newValue) / remainingOriginal) * 100;
                        setRangeValue(isNaN(percentage) ? 0 : Math.min(percentage, 100).toFixed(2));
                        onTokenChange(newValue <= 100000 ? newValue : tokenSellData.from_amount);
                      }}
                    />
                  </Form.Group>
                  {(localStorage.getItem("token") ||
                    sessionStorage.getItem("token")) &&
                    tokenSellData.sell_type == 1 && (
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="trade-percent-range mt-2 mb-2"
                      >
                        <div className="range-label">
                          <span>{rangeValue}%</span>
                          <span>100%</span>
                        </div>
                        <Form.Range
                          disabled={!walletBalance || walletBalance <= 0 || tokenSell.buttonDisable || forexExchangeRate.buttonDisable}
                          min="0"
                          max="100"
                          value={rangeValue}
                          onChange={(e) => {
                            const data =
                              (parseFloat(e.target.value) / 100) *
                              (wallet.data?.user_wallets ? walletBalance : 0);
                            setRangeValue(e.target.value);
                            onTokenChange(data);
                          }}
                        />
                        <div className="trade-range-value">
                          {rangeValue} % / 100 %
                        </div>
                      </Form.Group>
                    )}
                  {(localStorage.getItem("token") ||
                    sessionStorage.getItem("token")) &&
                    tokenSellData.sell_type == 1 && (
                      <div className="trade-stock-max">
                        <div className="trade-stock-max-info-sec">
                          <div className="trade-stock-max-info">
                            {t("available")}:
                            <span>{(walletBalance && !isNaN(walletBalance)) ? parseFloat(walletBalance) : 0.0}</span>
                          </div>
                        </div>
                      </div>
                    )}
                </Form>
              </div>
              {currencies.data.crypto_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenSellData.from_currency
                          ? currencies.data.crypto_currencies.find(
                            (item) =>
                              item.currency_code ==
                              tokenSellData.from_currency
                          ).picture
                          : currencies.data.crypto_currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenSellData.from_currency
                        ? currencies.data.crypto_currencies.find(
                          (item) =>
                            item.currency_code == tokenSellData.from_currency
                        ).name
                        : currencies.data.crypto_currencies[0].name}
                    </span>
                  </div>
                  <div className="sell-crypto-grid">
                    <Select
                      className="fillert-drop"
                      isSearchable={false}
                      options={categoriesOptions?.filter((data) => data?.value != selectedForex?.value)}
                      styles={customStyles}
                      value={selectedCrypto}
                      onChange={(selectedOption) => {
                        setExchangeRate(0);
                        onCryptoChange(selectedOption);
                      }}
                      isDisabled={tokenSell.buttonDisable || forexExchangeRate.buttonDisable}
                    />
                    {(["USDT", "USDC"].includes(tokenSellData.from_currency)) &&
                      tokenSellData.sell_type != 1 ? (
                      <div className="efi-swap-select-frame">
                        <Select
                          // menuPlacement="top"
                          className="fillert-drop"
                          options={networkOption}
                          onChange={(select) => {
                            setErrorMessage(false);
                            updateTokenSellData({
                              ...tokenSellData,
                              network_type: select.value,
                            });
                          }}
                          onBlur={() => {
                            tokenSellData.network_type == "" &&
                              setErrorMessage(true);
                          }}
                          value={networkOption.find(
                            (item) => item.value == tokenSellData.network_type
                          )}
                          styles={customStylesone}
                          isDisabled={tokenSell.buttonDisable}
                        // placeholder="Select Network"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("recieve")}</div>
                <div className="efi-receive-text">
                  {!forexExchangeRate.buttonDisable &&
                    tokenSellData.from_amount > 0
                    ? toCurrencyValue
                    : "0.0000"}
                </div>
              </div>
              {currencies.data.currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenSellData.to_currency
                          ? currencies.data.currencies.find(
                            (item) =>
                              item.currency_code == tokenSellData.to_currency
                          ).picture
                          : currencies.data.currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenSellData.to_currency
                        ? currencies.data.currencies.find(
                          (item) =>
                            item.currency_code == tokenSellData.to_currency
                        ).name
                        : currencies.data.currencies[0].name}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop half-sized"
                    isSearchable={false}
                    options={
                      selectedCrypto
                        ? forexOptions.filter(
                          (data) => data.value != selectedCrypto.value
                        )
                        : forexOptions.filter(
                          (data) => data.value != categoriesOptions[0].value
                        )
                    }
                    styles={customStyles}
                    value={selectedForex}
                    onChange={(selectedOption) => {
                      setExchangeRate(0);
                      onCurrencyChange(selectedOption)
                    }}
                    isDisabled={tokenSell.buttonDisable || forexExchangeRate.buttonDisable}
                  />
                </div>
              )}
            </div>
            <div
              className={`efi-swap-icons ${isLoggedIn && tokenSellData.sell_type == 1 ? " top-space " : ""
                }`}
            >
              <Image
                src={window.location.origin + "/img/icon/swap.svg"}
                className="swap-icon"
              />
            </div>
          </div>
          {isLoggedIn && invalidAmount && parseFloat(walletBalance) > 0 && (
            <p className="error-msg text-danger select-date buy-form error-text">
              {t("invalid", { value: tokenSellData.minAmount.toFixed(2) })}
            </p>
          )}
          {isLoggedIn &&
            tokenSellData.from_amount > parseFloat(walletBalance) &&
            parseFloat(walletBalance) < tokenSellData.maxAmount &&
            parseFloat(walletBalance) > 0 &&
            tokenSellData.sell_type == 1 && (
              <p className="error-msg text-danger select-date buy-form error-text">
                {" "}
                {t("max_invalid", { value: walletBalance })}
              </p>
            )}
          {isLoggedIn &&
            parseFloat(walletBalance) <= 0 &&
            tokenSellData.sell_type == 1 && (
              <p className="error-msg text-danger select-date buy-form error-text">
                {t("wallet_balance_is_low")}
              </p>
            )}

          {isLoggedIn &&
            parseFloat(tokenSellData.from_amount) > tokenSellData.maxAmount &&
            parseFloat(walletBalance) > 0 && (
              <p className="error-msg text-danger select-date buy-form error-text">
                {" "}
                {t("max_invalid", { value: tokenSellData.maxAmount })}
              </p>
            )}
          {/* {(exchangeRate > 0) ? */}
          <div className="efi-swap-exchange-info efi-info-frame">
            <p>
              {" "}
              <span> {t("estimated_rate")} </span>{" "}
              {(!forexExchangeRate.buttonDisable) ? (
                <span>
                  {" "}
                  1 {""}
                  {tokenSellData.from_currency}= {""}
                  {!isNaN(
                    tokenSellData.to_exchange_rate * (exchangeRate)
                  )
                    ? (
                      tokenSellData.to_exchange_rate * (exchangeRate)
                    ).toFixed(2)
                    : 0.0}
                  {""} {tokenSellData.to_currency}
                </span>
              ) : (
                <div className="preloading-sec"></div>
              )}
            </p>
          </div>
          {/* : null} */}

          {(!forexExchangeRate.buttonDisable && !isNaN(tokenSellData.from_amount * commission) && (tokenSellData.from_amount * commission).toFixed(2) > 0) ? <div className="commission-based-landing-card">
            <div className="commission-box">
              <div className="commission-card">
                <h5> {t("service_fee")}:</h5>
                <h5>
                  {(tokenSellData.from_amount * commission).toFixed(2)}
                  {tokenSellData.to_currency}
                </h5>
              </div>
            </div>
          </div> : null}

          <div className="efi-swap-check-wrap">
            <div className="efi-swap-check-frame">
              <Radio
                name="sell"
                value={2}
                checked={tokenSellData.sell_type == 2}
                disabled={tokenSell.buttonDisable}
                onClick={(e) => {
                  updateTokenSellData({
                    ...tokenSellData,
                    sell_type: e.target.value,
                  });
                }}
              >
                {t("crypto")}
              </Radio>
              <Radio
                name="sell"
                value={1}
                checked={tokenSellData.sell_type == 1}
                disabled={tokenSell.buttonDisable}
                onClick={(e) => {
                  isLoggedIn ? updateTokenSellData({
                    ...tokenSellData,
                    sell_type: e.target.value,
                  }) : navigate("/login")
                }}
              >
                {t("wallet")}
              </Radio>
            </div>
          </div>
          {errorMessage &&
            (["USDT", "USDC"].includes(tokenSellData.from_currency) ||
              ["USDT", "USDC"].includes(tokenSellData.toCurrency)) && (
              <p className=" d-flex justify-content-end  error-msg text-danger select-date buy-form error-text">
                {t("required")}
              </p>
            )}
          {tokenSell.buttonDisable ||
            (Object.keys(tokenSell.data).length > 0 && transactionSuccess) ? (
            <div className="mt-3">
              <ButtonStepper props={tokenSell} />
            </div>
          ) : (
            <div className="efi-swap-action">
              <Button
                className="action-btn primary w-100 item_flex_x_5 justify-content-center"
                onClick={onComplete}
                disabled={
                  !((exchangeRate > 0)) ||
                  tokenSell.buttonDisable ||
                  forexExchangeRate.buttonDisable ||
                  tokenSellData.to_amount < 0 ||
                  invalidAmount ||
                  (isLoggedIn &&
                    tokenSellData.from_amount > parseFloat(walletBalance) &&
                    tokenSellData.sell_type == 1) ||
                  parseFloat(tokenSellData.from_amount) >
                  tokenSellData.maxAmount ||
                  kycApplicant.buttonDisable ||
                  ((["USDT", "USDC"].includes(tokenSellData.from_currency) ||
                    ["USDT", "USDC"].includes(tokenSellData.toCurrency)) &&
                    tokenSellData.network_type == "" &&
                    tokenSellData.sell_type != 1)
                }
              >
                {redirect
                  ? t("exchange")
                  : tokenSell.buttonDisable
                    ? t("loading")
                    : t("continue")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#171717"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M18.01 19.99A9.964 9.964 0 0112 22c-5.52 0-8.89-5.56-8.89-5.56m0 0h4.52m-4.52 0v5M22 12c0 1.82-.49 3.53-1.34 5M6.03 3.97A9.921 9.921 0 0112 2c6.67 0 10 5.56 10 5.56m0 0v-5m0 5h-4.44M2 12c0-1.82.48-3.53 1.33-5"
                  ></path>
                </svg>
              </Button>
            </div>
          )}
        </div>
      ) : (
        <NoDataFound />
      )}
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
    </>
  );
};

export default SellCryptoForm;
