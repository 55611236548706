import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./Swap.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
const SwapWalletAddress = (props) => {
const t = useTranslation("swap_wallet")

    return (
        <>
            <div className="buy-crypto-sec">
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="exchange-box swap-exchange-box">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="swap-crypto">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="buy-crypto">{t("buy")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="swap-crypto">{t("swap")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            <Form className="exchange-form">
                                <div className="receive-token-sec">
                                    <h5>{t("token")}</h5>
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>{t("wallet.name")}</Form.Label>
                                    <Form.Control type="email" placeholder={t("wallet.placeholder")} />
                                </Form.Group>
                                <div className="swap-transfer-icon-sec">
                                    <span className="or-line">
                                        <span>
                                            {t("or")}
                                        </span>
                                    </span>
                                </div>
                                <div className="swap-connect-box">
                                {t("connect")}
                                </div>
                                <div className="exchange-btn-sec">
                                    <div className="connect-meta-btn">
                                        <Button>
                                            <Image
                                                src={window.location.origin + "/img/sell/connect-metamask-icon.png"}
                                                className="connect-meta-icon"
                                            />
                                            {t("connect_metamask")} 
                                        </Button>
                                    </div>
                                    <Button className="default-btn" onClick={() => props.setStep(3)}>
                                    {t("continue")}
                                    </Button>
                                    <p>{t("by_continuing_you_agree_to_our")}<Link to="#"> {t("cookie")}</Link></p>  
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SwapWalletAddress;
