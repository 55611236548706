import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import SwapField from "./SwapField";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";


const NewHomeBanner = () => {

  const t = useTranslation("home_banner")

  return (
    <>
      <div className="home-banner-sec">
        <Container fluid>
            <Row className="align-items-center vertical-center-1 ">
              <Col xl={6} lg={12} md={12} className="order-change">
                <div className="banner-new-content">
                  <h5>{t("revolutionizing_your_crypto_journey")}</h5>
                  <h1>
                    {t("empower_your_digital")} 
                    {/* <span> {t("assets_with")} {configuration.get("configData.site_name")} </span> */}
                  </h1>
                  {/* <p data-aos="fade-up" data-aos-easing="ease-in-sine">
                   {t("where_advanced_technology_para")}
                  </p> */}
                  {/* <div className="home-banner-btn-box">
                    <div className="banner-new-btn-sec">
                      <Link to="/buy" className="new-default-secondary-btn" data-aos="fade-up" data-aos-easing="ease-in-sine">
                      
                        Buy Crypto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="banner-new-btn-sec">
                      <Link to="/sell" className="new-default-secondary-btn" data-aos="fade-up" data-aos-easing="ease-in-sine">
                
                        Sell Crypto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="banner-new-btn-sec">
                      <Link to="/swap" className="new-default-secondary-btn" data-aos="fade-up" data-aos-easing="ease-in-sine">
                
                        Exchange Currencie
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="banner-new-btn-sec">
                      <Link to="/trading" className="new-default-secondary-btn" data-aos="fade-up" data-aos-easing="ease-in-sine">
                       
                        Trade Crypto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div> */}
                  <div className="banner-batch-frame">
                    <div className="banner-crypto-link">
                      <div className="banner-crypto-link-info">
                        <h3>Buy Crypto</h3>
                        <p> Real Money in a few easy steps</p>
                      </div>
                      <div className="banner-crypto-link-action">
                        <Link className="crypto-link" to="/buy">  Buy  </Link>
                        <Image
                          className="banner-crypto-icons"
                          src={window.location.origin + "/img/phase_1/icons/buy-crypto.svg"}
                          alt="buy crypto"
                        />
                      </div>
                    </div>
                    <div className="banner-crypto-link">
                      <div className="banner-crypto-link-info">
                      <h3> Sell Crypto</h3>
                      <p>Cash anytime, quickly and securely</p>
                      </div>
                      <div className="banner-crypto-link-action">
                        <Link className="crypto-link" to="/sell">  Sell  </Link>
                        <Image
                          className="banner-crypto-icons"
                          src={window.location.origin + "/img/phase_1/icons/sell-crypto.svg"}
                          alt="buy crypto"
                        />
                      </div>
                    </div>
                    <div className="banner-crypto-link">
                      <div className="banner-crypto-link-info">
                      <h3>   Exchange Crypto</h3>
                        <p>
                          Instantly, no hassle</p>
                      </div>
                      <div className="banner-crypto-link-action">
                        <Link className="crypto-link " to="/exchange">  Exchange </Link>
                        <Image
                          className="banner-crypto-icons"
                          src={window.location.origin + "/img/phase_1/icons/swap.svg"}
                          alt="buy crypto"
                        />
                      </div>
                    </div>
                    <div className="banner-crypto-link">
                      <div className="banner-crypto-link-info">
                      <h3>  Trade </h3>
                        <p>Real-time data and advanced tools</p>
                      </div>
                      <div className="banner-crypto-link-action">
                        <Link className="crypto-link" to="/trading">  Trade </Link>
                        <Image
                          className="banner-crypto-icons"
                          src={window.location.origin + "/img/phase_1/icons/trade.svg"}
                          alt="buy crypto"
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <SwapField />
              </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default NewHomeBanner;
