import { call, select, put, takeLatest, all, take } from "redux-saga/effects";
import {
  addBankAccountSuccess,
  addBankAccountFailure,
  getBankAccountSuccess,
  getBankAccountFailure,
  deleteBankAccountSuccess,
  deleteBankAccountFailure,
  makeDefaultBankAccountSuccess,
  makeDefaultBankAccountFailure,
  viewBankAccountSuccess,
  viewBankAccountFailure,
} from "../actions/BankAccountAction";
import {
  ADD_BANK_ACCOUNT_START,
  GET_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_START,
  MAKE_DEFAULT_BANK_ACCOUNT_START,
  VIEW_BANK_ACCOUNT_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import api from "../../components/Environment";

function* addBankAccountAPI(action) {
  let bankAccountData = yield select((state) => state.bankAccount.bankAccount.data);
  try {
    const response = yield api.postMethod("bank_accounts/store", action.data);

    if (response.data.success) {
      yield put(addBankAccountSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (Object.keys(bankAccountData).length > 0) {
        yield put(getBankAccountSuccess({
          ...bankAccountData,
          bank_accounts: action.data.user_bank_account_id ?
            bankAccountData.bank_accounts.map((account) => account.id == action.data.user_bank_account_id ?
              response.data.data.bank_account : account)
            :
            [response.data.data.bank_account, ...bankAccountData.bank_accounts]
        }));
      }
    } else {
      yield put(addBankAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addBankAccountFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* getBankAccountAPI(action) {
  try {
    const response = yield api.postMethod("bank_accounts", action.data);

    if (response.data.success) {
      yield put(getBankAccountSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(getBankAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getBankAccountFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* deleteAccountBankAccountAPI(action) {
  let bankAccountData = yield select((state) => state.bankAccount.bankAccount.data);
  try {
    const response = yield api.postMethod("bank_accounts/destroy", action.data);
    if (response.data.success) {
      yield put(deleteBankAccountSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (Object.keys(bankAccountData).length > 0) {
        yield put(getBankAccountSuccess({
          ...bankAccountData,
          total_bank_accounts: response.data.total_bank_accounts,
          bank_accounts: bankAccountData.bank_accounts.filter(account =>
            (account.id != action.data.user_bank_account_id)),
        }));
      }
    } else {
      yield put(deleteBankAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteBankAccountFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* makeDefaultBankAccountAPI(action) {
  let bankAccountData = yield select((state) => state.bankAccount.bankAccount.data);
  try {
    const response = yield api.postMethod("bank_accounts/default", action.data);
    if (response.data.success) {
      yield put(makeDefaultBankAccountSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (Object.keys(bankAccountData).length > 0) {
        yield put(getBankAccountSuccess({
          ...bankAccountData,
          bank_accounts: bankAccountData.bank_accounts.map(account =>
            account.id == response.data.data.bank_account.id
              ? { ...account, is_default: 1 } :
              { ...account, is_default: 0 },
          )
        }));
      }
    } else {
      yield put(makeDefaultBankAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteBankAccountFailure(error));
    yield call(makeDefaultBankAccountFailure, error.message);
  }
}
function* viewBankAccountAPI(action) {
  try {
    const response = yield api.postMethod("bank_accounts/show", action.data);

    if (response.data.success) {
      yield put(viewBankAccountSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(makeDefaultBankAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(viewBankAccountFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* BankAccountSaga() {
  yield all([
    yield takeLatest(ADD_BANK_ACCOUNT_START, addBankAccountAPI),
    yield takeLatest(GET_BANK_ACCOUNT_START, getBankAccountAPI),
    yield takeLatest(DELETE_BANK_ACCOUNT_START, deleteAccountBankAccountAPI),
    yield takeLatest(MAKE_DEFAULT_BANK_ACCOUNT_START, makeDefaultBankAccountAPI),
    yield takeLatest(VIEW_BANK_ACCOUNT_START, viewBankAccountAPI),
  ])
}

