import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Table, Button, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import {
  sellTokenTransactionsListStart,
  sellTokensExportStart,
} from "../../store/actions/BuyAction";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import Pagination from "../helper/Pagination";
import { useSelector, useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/ToastNotification";
import SellTransactionModal from "./SellTransactionModal";
import "./transaction.css";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import dayjs from "dayjs";
import SellTransactionViewModal from "./TransactionModals/SellTransactionViewModal";
import NoTransactionData from "../helper/NoTransactionData";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const SellTransaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const t = useTranslation("transactions");

  const options = [
    { value: "0", label: t("processing") },
    { value: "1", label: t("success") },
    { value: "2 ", label: t("failed") },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      width: "150px",
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      width: "150px",
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #e5eaf4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "48px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878e96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#878e96",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  const [modalShow, setModalShow] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [value, setValue] = useState([null, null]);
  const [skipRender, setSkipRender] = useState(true);
  const sellTokenTransactions = useSelector(
    (state) => state.buy.sellTokenTransactionsList
  );
  const sellTokensExport = useSelector((state) => state.buy.sellTokensExport);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
    from_date: "",
    to_date: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const calenderRefs = useRef(null);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      sellTokenTransactionsListStart({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
      })
    );
  }, [filter, page]);

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11)
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11)
          : ""
      );
      setDateValues({
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11)
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11)
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      });
    }
  };

  const handleExport = () => {
    dispatch(
      sellTokensExportStart({
        ...filter,
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      })
    );
  };

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  useEffect(() => {
    if (
      !skipRender &&
      !sellTokensExport.buttonDisable &&
      Object.keys(sellTokensExport.data).length > 0
    ) {
      window.open(sellTokensExport.data.url, "_blank", "noreferrer");
    }
    setSkipRender(false);
  }, [sellTokensExport]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  return (
    <>
      <div className="wallet_table_head item_line_between   out_space_my_1_5">
        {(filter.status !== "" ||
          filter.search_key !== "" ||
          filter.from_date !== "" ||
          filter.to_date !== "" ||
          (Object.keys(sellTokenTransactions.data).length > 0 &&
            sellTokenTransactions.data.total > 0 &&
            filter.status == "" &&
            filter.search_key == "" &&
            filter.from_date == "" &&
            filter.to_date == "")) && (
          <>
            <div className="table_head_titles">
              <h3>Sell Transactions</h3>

              <h5 className="wallet_count item_center">
                {sellTokenTransactions.data.total}
              </h5>
            </div>

            <div className="table_head_action">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                  sort_by: searchParams.get("sort_by")
                    ? searchParams.get("sort_by")
                    : filter.sort_by,
                  from_date: "",
                  to_date: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                    from_date: dateValues.from_date,
                    to_date: dateValues.to_date,
                  });

                  navigate(
                    searchParams.get("sort_by")
                      ? `/transactions?search_key=${
                          val.search_key
                        }&sort_by=${searchParams.get("sort_by")}`
                      : `/transactions?search_key=${val.search_key}`,
                    { state: { value: value } }
                  );
                }}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm, submitForm }) => (
                  <FORM>
                    <div className="wallet_sort_form ">
                      <div className="wallet_sort_search">
                        <InputGroup className="mb-0">
                          <Field
                            name="search_key"
                            onChange={(e) => {
                              {
                                e.target.value == "" && submitForm();
                                formRef.current.setFieldValue(
                                  "search_key",
                                  e.target.value
                                );
                              }
                            }}
                            placeholder={t("search")}
                            className="form-control"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                formRef.current.handleSubmit();
                              }
                            }}
                          />
                          <InputGroup.Text id="basic-addon2">
                            {searchParams.get("search_key") ? (
                              <Button
                                type="button"
                                className="action-btn link-btn"
                                onClick={() => {
                                  setFieldValue("search_key", "");
                                  setFilter({
                                    ...filter,
                                    search_key: "",
                                    from_date: dateValues.from_date,
                                    to_date: dateValues.to_date,
                                  });
                                  navigate(
                                    searchParams.get("sort_by")
                                      ? `/transactions?sort_by=${searchParams.get(
                                          "sort_by"
                                        )}`
                                      : `/transactions`
                                  );
                                }}
                              >
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#111"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                className="action-btn link-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#171717"
                                    d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75zM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z"
                                  ></path>
                                </svg>
                              </Button>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="wallet_sort_date">
                        <Form.Group>
                          <InputGroup className="mb-0">
                            <DatePicker
                              format="DD/MM/YYYY"
                              value={value}
                              style={{ padding: "1em" }}
                              placeholder="Select Date Range"
                              onChange={(date) => {
                                handleDateChange(date);
                              }}
                              range
                              numberOfMonths={2}
                              ref={calenderRefs}
                              maxDate={
                                new Date(new Date().setHours(23, 59, 59, 999))
                              }
                              editable={false}
                              onOpenPickNewDate={false}
                              render={!close && <InputIcon />}
                            >
                              <div className="new-action-frame layout-element p-2 m-0">
                                <Button
                                  className="action-btn secondary"
                                  onClick={() => {
                                    setValue([null, null]);
                                    calenderRefs.current.closeCalendar();
                                  }}
                                >
                                  {t("close")}
                                </Button>
                                <Button
                                  className="action-btn primary"
                                  onClick={() => {
                                    if (values.from_date || values.to_date) {
                                      setFilter({
                                        ...filter,
                                        skip: 12 * (page - 1),
                                        take: 12,
                                        from_date: dateValues.from_date,
                                        to_date: dateValues.to_date,
                                      });
                                      calenderRefs.current.closeCalendar();
                                      setClose(true);
                                    } else
                                      getErrorNotificationMessage(
                                        "Please select date"
                                      );
                                  }}
                                >
                                  {t("apply")}
                                </Button>
                              </div>
                            </DatePicker>
                            {close && (
                              <>
                                <button
                                  type="button"
                                  className=" date-cancel"
                                  onClick={() => {
                                    setDateValues({
                                      from_date: "",
                                      to_date: "",
                                    });
                                    setValue([null, null]);
                                    setClose(false);
                                    setFilter({
                                      ...filter,
                                      from_date: "",
                                      to_date: "",
                                    });
                                  }}
                                >
                                  <svg
                                    height="20"
                                    width="20"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    class="css-tj5bde-Svg"
                                  >
                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                  </svg>
                                </button>
                              </>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </div>
                      <div className="wallet_sort_filter">
                        <Select
                          className="fillert-drop"
                          options={options}
                          isClearable
                          styles={customStyles}
                          isSearchable={false}
                          value={filterStatus || null}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setFilter({
                                ...filter,
                                status: selectedOption.value,
                              });
                              setFilterStatus(selectedOption);
                              navigate(
                                filter.search_key
                                  ? `/transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                                  : `/transactions?status=${selectedOption.value}`
                              );
                            } else {
                              const newFilter = { ...filter };
                              delete newFilter.status;
                              setFilter(newFilter);

                              navigate(
                                filter.search_key
                                  ? `/transactions?search_key=${filter.search_key}`
                                  : "/transactions"
                              );
                              setFilterStatus(null);
                            }
                          }}
                          placeholder={
                            <div className="placeholder-flex">
                              {t("filter")}
                            </div>
                          }
                        />
                      </div>
                      <div className="wallet_sort_export">
                        <Button
                          type="submit"
                          className="action-btn gradient"
                          onClick={handleExport}
                          disabled={
                            sellTokensExport.buttonDisable ||
                            sellTokenTransactions.loading ||
                            !Object.keys(sellTokenTransactions.data).length >
                              0 ||
                            !sellTokenTransactions.data.sell_transactions
                              .length > 0
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#171717"
                              d="M11.88 14.99c-.19 0-.38-.07-.53-.22l-2.56-2.56a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.03 2.03 2.03-2.03c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.56 2.56c-.15.15-.34.22-.53.22z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M11.88 14.92c-.41 0-.75-.34-.75-.75V4c0-.41.34-.75.75-.75s.75.34.75.75v10.17c0 .41-.34.75-.75.75z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M12 20.93c-5.15 0-8.75-3.6-8.75-8.75 0-.41.34-.75.75-.75s.75.34.75.75c0 4.27 2.98 7.25 7.25 7.25s7.25-2.98 7.25-7.25c0-.41.34-.75.75-.75s.75.34.75.75c0 5.15-3.6 8.75-8.75 8.75z"
                            ></path>
                          </svg>
                          <span>{t("buy_transaction.export")}</span>
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>

      {sellTokenTransactions.loading ? (
        <Skeleton count={4} height={80} />
      ) : Object.keys(sellTokenTransactions.data).length > 0 &&
        sellTokenTransactions.data.sell_transactions.length > 0 ? (
        <React.Fragment>
          {sellTokenTransactions.data.total > 12 ? (
            <div className="new-billings-pagination-sec">
              <Pagination
                page={page}
                pageCount={Math.ceil(sellTokenTransactions.data.total / 12)}
                handlePageClick={handlePageClick}
              />
            </div>
          ) : null}
          <div className="wallet_table">
            <Table responsive>
              <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("buy_transaction.payment_id")}</th>
                    <th>{t("buy_transaction.payment_type")}</th>
                    <th>{t("buy_transaction.token")}</th>
              {/* <th>{t("buy_transaction.transaction_hash")}</th> */}
              {/* 
                    <th>{t("buy_transaction.exchange_value")}</th> */}
              {/* <th>{t("buy_transaction.amount")}</th> */}
              <th>{t("buy_transaction.from")}</th>
                    <th>{t("buy_transaction.to")}</th>
                    {/* <th>{t("buy_transaction.requested_date")}</th> */}
                    <th>{t("buy_transaction.status")}</th>
                    <th>{t("buy_transaction.action")}</th>
                  </tr>
                </thead>
              <tbody>
                {sellTokenTransactions.data.sell_transactions.map(
                  (transactions) => (
                    <tr>
                      <td>
                        <span className="wallet_shuffle_icon item_center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <g
                              fill="#000"
                              fillRule="evenodd"
                              clipRule="evenodd"
                            >
                              <path d="M15 3a1 1 0 011-1h5a1 1 0 011 1v5a1 1 0 11-2 0V4h-4a1 1 0 01-1-1z"></path>
                              <path d="M21.707 2.293a1 1 0 010 1.414l-17 17a1 1 0 01-1.414-1.414l17-17a1 1 0 011.414 0zM21 15a1 1 0 011 1v5a1 1 0 01-1 1h-5a1 1 0 110-2h4v-4a1 1 0 011-1z"></path>
                              <path d="M14.293 14.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414zM3.293 3.293a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414z"></path>
                            </g>
                          </svg>
                        </span>
                      </td>
                      <td>
                        {transactions.payment_id ? (
                          <h5 className="table_code">
                            <span>
                              {" "}
                              {transactions.payment_id.length > 10
                                ? `${transactions.payment_id.slice(
                                    0,
                                    5
                                  )}...${transactions.payment_id.slice(-5)}`
                                : transactions.payment_id}
                            </span>
                            <CopyToClipboard
                              text={transactions.payment_id}
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  t(
                                    "buy_transaction.payment_id_copied_to_clipboard"
                                  )
                                )
                              }
                            >
                              <Button className="action-btn copy-btn item_center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#298BFF"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                  <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </h5>
                        ) : (
                          <h5 className="table_code">{t("n_a")}</h5>
                        )}

                        <div className="transaction_token item_flex_x_5">
                          <p className="table_token">
                            {transactions.adjusted_exchange_rate_formatted}
                          </p>
                          <p className="table_amount">
                            {transactions.created_at_formatted}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="table_value mb-2">
                          {transactions.sell_type == 1 ? "Wallet" : "Crypto"}
                        </div>
                      </td>
                      <td>
                        <div className="table_value mb-2">
                          {transactions.to_currency}
                        </div>
                      </td>
                      <td>
                        <div className="table_value mb-2">
                          {transactions.from_amount_formatted}
                        </div>
                      </td>
                      <td>
                        <div className="table_value mb-2">
                          {transactions.to_amount_formatted}
                        </div>
                      </td>
                      <td>
                      <div
                        className={`table_badge  ${
                          transactions.status == 1 ? "success" :transactions.status==0? "pending":"fail"
                        }`}
                      >
                          {" "}
                          {transactions.status_formatted}
                        </div>
                      </td>
                      <td>
                        <Button
                          className="action-btn link-btn"
                          onClick={() => {
                            setModalShow(transactions);
                            setTransactionId(transactions.id);
                          }}
                        >
                          <span>{t("view")}</span>
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
          {sellTokenTransactions.data.total > 12 ? (
            <div className="new-billings-pagination-sec">
              <Pagination
                page={page}
                pageCount={Math.ceil(sellTokenTransactions.data.total / 12)}
                handlePageClick={handlePageClick}
              />
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <NoTransactionData />
      )}
      {/* {modalShow ? (
        <SellTransactionModal
          show={modalShow}
          transactionId={transactionId}
          onHide={() => setModalShow(false)}
        />
      ) : null} */}
      {modalShow ? (
        <SellTransactionViewModal
          show={modalShow}
          transactionId={transactionId}
          onHide={() => setModalShow(false)}
        />
      ) : null}
    </>
  );
};

export default SellTransaction;
