import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBeneficiariesStart } from '../../store/actions/TransferAction';

const DeleteBeneficiaryAccount = (props) => {

  const t = useTranslation("delete_account");
  const [skipRender, setSkipRender] = useState(true);
  const { accountId } = props;
  const dispatch = useDispatch();
  const deleteBeneficiaries = useSelector(state => state.transfer.deleteBeneficiaries);

  const handleSubmit = () => {
    dispatch(deleteBeneficiariesStart({
      beneficiary_id: accountId,
    }))
  };

  useEffect(() => {
    if (!skipRender && !deleteBeneficiaries.loading &&
      Object.keys(deleteBeneficiaries.data).length > 0)
      props.onHide();
    setSkipRender(false);
  }, [deleteBeneficiaries])

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='new-modal-custome'
      >
     <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <div className='p-3'>
          <h4> {t("heading")}</h4>
          <p className='text-center' >{t("delete_confirmation")}</p>
          <div className="new-action-frame layout-element">
            <Button
              className='new-overlay-btn'
              onClick={props.onHide}
            >{t("cancel")}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              className='new-primary-btn'
              disabled={deleteBeneficiaries.buttonDisable}
            >
              {deleteBeneficiaries.buttonDisable
                ? t("loading")
                : t("delete")}
            </Button>
          </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default DeleteBeneficiaryAccount;
