import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  getBankAccountStart,
  makeDefaultBankAccountStart,
} from "../../store/actions/BankAccountAction";
import { connect } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../helper/NoDataFound';
import EditAccountBank from '../Wallet/EditAccountBank';
import { useTranslation } from 'react-multi-lang';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DeleteBankAccountModal from './DeleteBankAccountModal';
import { viewBankAccountStart } from '../../store/actions/BankAccountAction';

const BankAccountIndex = (props) => {

  const t = useTranslation("bank_account");
  const [skipRender, setSkipRender] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [deleteBankAccount, setDeleteBankAccount] = useState(false);

  useEffect(() => {
    if (!skipRender && !props.bankAccountSave.loading &&
      Object.keys(props.bankAccountSave.data).length > 0) {
      setModalShow(false)
    }
    setSkipRender(false);
  }, [props.bankAccountSave]);

  useEffect(() => {
    props.dispatch(getBankAccountStart());
  }, []);

  const editBankAccount = (accout_id) => {
    setAccountId(accout_id);
    setModalShow(true);
    props.dispatch(viewBankAccountStart({
      user_bank_account_id: accout_id
    }));
  }

  const deleteBankAccountConfirmation = (accout_id) => {
    setAccountId(accout_id);
    setDeleteBankAccount(true);
  }


  return (
    <>
      {props.bankAccountList.loading ? (
        [...Array(3)].map((i, key) => <Skeleton key={key} height={220} />)
      ) : Object.keys(props.bankAccountList.data).length > 0 &&
        props.bankAccountList.data.bank_accounts.length > 0 ?
        props.bankAccountList.data.bank_accounts.map((account, i) => (

          <div className={`bank-account-list-card ${account.is_default ? "bank-account-list-primary-card" : ""}`}>
            <div className="bank-account-list-item">
              <div className="bank-account-list-header-sec">
                <div className="bank-account-list-header-left-sec">
                  <h4>{account.account_holder_name}</h4>
                  <p>{account.bank_name}</p>
                </div>
                <div className="bank-account-list-header-right-sec">
                  {!account.is_default &&
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <strong>{t("set_as_default_account")}</strong>{" "}
                        </Tooltip>
                      }>
                      <Button
                        onClick={() => props.dispatch(makeDefaultBankAccountStart({ user_bank_account_id: account.id }))}
                        disabled={props.bankAccountStatusUpdate.buttonDisable}
                        className="make-primary-btn">
                        {props.bankAccountStatusUpdate.buttonDisable &&
                          (account.id === props.bankAccountStatusUpdate.data.bank_account_id) ?
                          null
                          :
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 22 16"
                          >
                            <path
                              fill="#FFB743"
                              d="M1.971 11.422L.717 3.267a.68.68 0 011.08-.648l3.909 2.932a.934.934 0 001.338-.23l3.254-4.88a.869.869 0 011.445 0l3.255 4.88a.934.934 0 001.337.23l3.909-2.932a.68.68 0 011.08.648l-1.254 8.155H1.97zM19.126 15.947H2.914a.944.944 0 01-.943-.944V12.93H20.07v2.073a.944.944 0 01-.944.944z"
                            ></path>
                          </svg>
                        }
                      </Button>
                    </OverlayTrigger>
                  }
                  <Button
                    onClick={() => editBankAccount(account.id)}
                    className="edit-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="feather feather-edit-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 20h9M16.5 3.5a2.121 2.121 0 013 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                    </svg>
                  </Button>
                  <Button
                    onClick={() => deleteBankAccountConfirmation(account.id)}
                    className="delete-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="none"
                      stroke="#FF6A6A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="feather feather-trash-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 6L5 6 21 6"></path>
                      <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                      <path d="M10 11L10 17"></path>
                      <path d="M14 11L14 17"></path>
                    </svg>
                  </Button>
                </div>
              </div>
              <div className="bank-account-list-body-sec">
                <div className="bank-account-list-account-number-sec">
                  <h4>XXXX XXXX XXXX {account.account_number.slice(-4)}</h4>
                </div>
                <div className="bank-account-list-footer-sec">
                  <div className="bank-account-list-ifsc-code-sec">
                    <svg
                      width="22"
                      height="22"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11V7.83L12 2.06 2 7.83V11h2v8H2v2h20v-2h-2v-8zM4 9l8-4.62L20 9H4zm2 10v-8h2v8zm4 0v-8h4v8zm8 0h-2v-8h2z"></path>
                    </svg>
                    <span>{account.ifsc_code}</span>
                  </div>
                  <div className="bank-account-list-type-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 16 17"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                      ></path>
                    </svg>
                    <span>{account.account_type.charAt(0).toUpperCase() + account.account_type.slice(1)} Account</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
        :
        <React.Fragment>
          <div></div>
          <NoDataFound />
        </React.Fragment>
      }
      <EditAccountBank
        accountId={accountId}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {deleteBankAccount && (
        < DeleteBankAccountModal
          accountId={accountId}
          show={deleteBankAccount}
          onHide={() => setDeleteBankAccount(false)}
        />
      )}
    </>
  )
}

const mapStateToPros = (state) => ({
  bankAccountSave: state.bankAccount.addBankAccount,
  bankAccountList: state.bankAccount.bankAccount,
  bankAccountStatusUpdate: state.bankAccount.makeDefault,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(BankAccountIndex);
