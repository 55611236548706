import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { connect } from "react-redux";
import * as Yup from "yup";
import { profileUpdateStart } from "../../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-multi-lang";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { countryData } from "../helper/CountryList";

const ChangeMobileNumberModal = (props) => {
  const t = useTranslation("change_mobileNumber");
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef();
  const [defaultCountry, setDefaultCountry] = useState("AE");

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("user_mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("user_mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("user_mobile.required"));
      return false;
    }
  };
  const MobileNumberUpdateSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required"))
      .min(3, t("first_name.min_length_invalid"))
      .max(128, t("first_name.max_length_invalid")),
    first_name: Yup.string()
      .required(t("full_name.required"))
      .min(3, t("user_mobile.min_length_invalid"))
      .max(25, t("user_mobile.max_length_invalid")),
    last_name: Yup.string()
      .required(t("full_name.required"))
      // .min(3, t("user_mobile.min_length_invalid"))
      .max(25, t("user_mobile.max_length_invalid")),
    mobile: Yup.string()
      .test("valid-mobile", t("user_mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("user_mobile.required"))
      .matches(/^(?=.*[0-9])/, t("user_mobile.space_values_not_valid")),
  });

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );

      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      if (props.profile.data.onboarding_completed == 1) {
        props.dispatch(
          profileUpdateStart({
            ...values,
            email: props.profile.data.email,
            mobile: mobile,
            country_code: countryCode,
            title: props.profile.data.title,
            timezone: props.profile.data.timezone,
            country_code: props.profile.data.country_code,
            nationality: props.profile.data.nationality,
            dob: props.profile.data.dob,
            gender: props.profile.data.gender,
            billing_address_1: props.profile.data.billing_address_1,
            billing_address_2: props.profile.data.billing_address_2,
            billing_country: props.profile.data.billing_country,
            billing_state: props.profile.data.billing_state,
            billing_city: props.profile.data.billing_city,
            billing_postal_code: props.profile.data.billing_postal_code,
            delivery_address_1: props.profile.data.delivery_address_1,
            delivery_address_2: props.profile.data.delivery_address_2,
            delivery_country: props.profile.data.delivery_country,
            delivery_state: props.profile.data.delivery_state,
            delivery_city: props.profile.data.delivery_city,
            delivery_postal_code: props.profile.data.delivery_postal_code,
          })
        );
      } else {
        props.dispatch(
          profileUpdateStart({
            ...values,
            email: props.profile.data.email,
            mobile: mobile,
            country_code: countryCode,
            title: props.profile.data.title,
            timezone: props.profile.data.timezone,
            country_code: props.profile.data.country_code,
            nationality: props.profile.data.nationality,
            dob: "",
            gender: "",
            billing_address_1: "",
            billing_address_2: "",
            billing_country: "",
            billing_state: "",
            billing_city: "",
            billing_postal_code: "",
            delivery_address_1: "",
            delivery_address_2: "",
            delivery_country: "",
            delivery_state: "",
            delivery_city: "",
            delivery_postal_code: "",
          })
        );
      }
      props.setchangeMobileNumber(values.mobile);
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.updateProfile.loading &&
      Object.keys(props.updateProfile.data).length > 0
    ) {
      props.setchangeMobileNumber(false);
    }
    setSkipRender(false);
  }, [props.updateProfile]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="change-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="change-title out_space_mx_1_5">
            <h2>{t("heading")}</h2>
          </div>
          <Formik
            initialValues={{
              first_name: props.profile.data.first_name,
              last_name: props.profile.data.last_name,
              middle_name: props.profile.data.middle_name,
              email: props.profile.data.email,
              mobile: `+${props.profile.data.country_code}${props.profile.data.mobile}`,
            }}
            validationSchema={MobileNumberUpdateSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue, touched, errors }) => (
              <FORM>
                <div className="change_form out_space_mx_1_5">
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>
                      First Name<span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="first_name"
                      onKeyDown={(e) => {
                        if (
                          !/[A-Z]|[a-z]|Backspace|Tab/.test(e.key) &&
                          !(
                            (e.ctrlKey &&
                              (e.key === "v" ||
                                e.key === "c" ||
                                e.key === "x" ||
                                e.key === "a")) ||
                            e.code == "Space"
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder={t("full_name.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="first_name"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>Middle name</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="middle_name"
                      onKeyDown={(e) => {
                        if (
                          (!/[A-Z]|[a-z]|Backspace||Tab/.test(e.key) &&
                            !(
                              e.ctrlKey &&
                              (e.key === "v" ||
                                e.key === "c" ||
                                e.key === "x" ||
                                e.key === "a")
                            )) ||
                          e.code == "Space"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder={"Enter middle name"}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>
                      Last Name <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="last_name"
                      onKeyDown={(e) => {
                        if (
                          (!/[A-Z]|[a-z]|Backspace||Tab/.test(e.key) &&
                            !(
                              e.ctrlKey &&
                              (e.key === "v" ||
                                e.key === "c" ||
                                e.key === "x" ||
                                e.key === "a")
                            )) ||
                          e.code == "Space"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder={"Enter last name"}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="last_name"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>
                      {t("email.label")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="email"
                      className="form-control email-disable"
                      name="email"
                      placeholder={t("email.placeholder")}
                      disabled
                      maxLength={128}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="email"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>
                      {t("user_mobile.label")} <span>*</span>
                    </Form.Label>
                    <PhoneInput
                      countries={Object.keys(countryData)}
                      name="mobile"
                      defaultCountry={defaultCountry}
                      placeholder={t("user_mobile.placeholder")}
                      value={values.mobile}
                      onChange={(value) => {
                        setFieldValue("mobile", value);
                      }}
                      international
                      className={`${touched.mobile && errors.mobile ? "is-invalid" : ""
                        }`}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="mobile"
                      className="text-danger"
                    />
                  </Form.Group>
                </div>
                <div className="change_action item_center_x_5">
                  <Button
                    className="action-btn overlay w-100"
                    onClick={props.onHide}
                  >
                    {t("save_changes.cancel")}
                  </Button>
                  <Button
                    className="action-btn primary w-100"
                    type="submit"
                    disabled={
                      props.updateProfile.buttonDisable ||
                      values.mobile == props.profile.data.mobile
                    }
                  >
                    {props.updateProfile.buttonDisable
                      ? t("loading")
                      : t("save_changes.text")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateProfile: state.user.updateProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ChangeMobileNumberModal);
