import React, { useEffect, useState } from "react";
import "./Buy.css";
import { ButtonLoader } from "../helper/Loader";

const ButtonStepper = ({props}) => {
    
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      setCompleted(true);
    }
  }, [props]);

  useEffect(() => {
    return () => {
      setCompleted(false);
    }
  }, [])

  return (
    <>
    <div className="flow-stepper-wrap">
        <div className="flow-stepper-frame">
          <ul className="list-unstyled"
          >
            <li className={`${completed ? "completed" : ""}`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                {completed ? (
                 <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="24"
                 height="24"
                 enableBackground="new 0 0 512 512"
                 viewBox="0 0 20 20"
                 fill="#fff"
               >
                 <path
                   fillRule="evenodd"
                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                   clipRule="evenodd"
                   data-original="#000000"
                 ></path>
               </svg>
                ) : (
                  <div class="flow-loader"></div>
                )}
                </div>
                <span>
                Awaiting Transaction
                </span>
              </div>
            </li>
            <li   className={`${completed ? "completed" : ""}`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                {completed ? (
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                    data-original="#000000"
                  ></path>
                </svg>
                ) : (
                  <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="24"
                 height="24"
                 enableBackground="new 0 0 512 512"
                 viewBox="0 0 20 20"
                 fill="#e4e0e0"
               >
                 <path
                   fillRule="evenodd"
                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                   clipRule="evenodd"
                   data-original="#000000"
                 ></path>
               </svg>
                )}
                </div>
                <span>
                Transaction Successful
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
    
  );
};

export default ButtonStepper;
