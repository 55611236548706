import { combineReducers } from "redux";
import WalletConnectorReducer from "./WalletConnectorReducer";
import UserReducer from "./UserReducer";
import PageReducer from "./PageReducer";
import BankAccountReducer from "./BankAccountReducer";
import BuyReducer from "./BuyReducer";
import WalletReducer from "./WalletReducer";
import TransferReducer from "./TransferReducer";
import RecipientReducer from "./RecipientReducer";

export default combineReducers({
  walletConnector: WalletConnectorReducer,
  user: UserReducer,
  page: PageReducer,
  bankAccount: BankAccountReducer,
  buy: BuyReducer,
  wallet: WalletReducer,
  transfer: TransferReducer,
  recipient: RecipientReducer,
});
