import React, { useEffect, useRef, useState } from "react";
import { Container, Image } from "react-bootstrap";
import StockTableMain from "./StockTable/StockTableMain";
import StockTrading from "./StockTrading/StockTrading";
import "./trading.css";
import { useDispatch, useSelector } from "react-redux";
import {
  currencyPairStart,
  getCurrenciesListStart,
} from "../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";

const TradingGraph = () => {
  const container = useRef(null);
  const [currencyPair, setCurrencyPair] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const currencyPairList = useSelector((state) => state.buy.currencyPairList);
  const currencyPairs = useSelector((state) => state.buy.currencyPair);
  const currencies = useSelector((state) => state.buy.currencies);
  const profile = useSelector((state) => state.user.profile);

  const handleSubmit = (search) => {
    const searchValue = search.toLowerCase();
    if (searchValue === "") handleClear();
    else {
      const filteredPairs = currencyPairList.data.currency_pairs.filter(
        (pair) => {
          return (
            pair.currency1.toLowerCase().includes(searchValue) ||
            pair.currency2.toLowerCase().includes(searchValue) ||
            pair.unique_id.toLowerCase().includes(searchValue)
          );
        }
      );
      setCurrencyPair(filteredPairs);
    }
  };

  const handleClear = () => {
    setSearch("");
    setCurrencyPair(currencyPairList.data.currency_pairs);
  };

  useEffect(() => {
    if (
      !currencyPairList.loading &&
      Object.keys(currencyPairList.data).length > 0 &&
      Object.keys(currencyPairList.data.currency_pairs).length > 0
    ) {
      setCurrencyPair(currencyPairList.data.currency_pairs);
    }
  }, [currencyPairList]);

  useEffect(() => {
      dispatch(getCurrenciesListStart({user_id:profile.data?.user_id?profile.data?.user_id:""}))
  }, []);

  const timezoneMapping = {
    "Asia/Calcutta": "Asia/Kolkata",
    "Asia/Bombay": "Asia/Mumbai",
  };

  useEffect(() => {
    if (currencyPairs) {
      const containerCurrent = container.current;

      while (containerCurrent.firstChild) {
        containerCurrent.removeChild(containerCurrent.firstChild);
      }
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      let symbol =`${currencyPairs?.currency1}${currencyPairs?.currency2}`=="USDTIDR"?"USDIDR": `${currencyPairs?.currency1}${currencyPairs?.currency2}`;
      const rawTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezone = timezoneMapping[rawTimezone] || rawTimezone;
      script.innerHTML = JSON.stringify({
        autosize: true,
        symbol: symbol,
        interval: "1",
        timezone: timezone,
        theme: "light",
        style: "1",
        locale: "en",
        withdateranges: true,
        range: "ALL",
        hide_side_toolbar: true,
        allow_symbol_change: false,
        details: true,
        calendar: true,
        support_host: "https://www.tradingview.com",
      });

      containerCurrent.appendChild(script);
    }
  }, [currencyPairs]);

  return (
    // <Container fluid>
      <div className="trade-chart-sec">
        {/* <div className="trade-stock-pair-list">
          <div className="trade-header-stock-box">
            {currencyPairList.loading ? (
              <div className="trade-fav-stock-card">
                {[...Array(15)].map((_, index) => (
                  <div key={index} className="trade-header-stock-card">
                    <Skeleton height={40} width={360} />
                  </div>
                ))}
              </div>
            ) : currencyPair.length > 0 ? (
              currencyPair.map((currency, index) => (
                <div
                  key={index}
                  className="trade-fav-stock-card"
                  onClick={() => {
                    dispatch(currencyPairStart(currency));
                  }}
                >
                  <div
                    className={`trade-header-stock-card ${
                      currency?.unique_id === currencyPairs?.unique_id &&
                      "active"
                    }`}
                  >
                    <div className="stock-card-name grid-from_2">
                      <div className="stock-card-avater">
                        <Image
                          className="stock-icon"
                          src={
                            currency.image && currency.image !== ""
                              ? currency.image
                              : window.location.origin +
                                "/img/trading/crypto.png"
                          }
                          type="image/png"
                        />
                      </div>
                      <div className="stock-card-info">
                        <div className="stock-card-crypto font-8 font-bold-500">
                          {currency.unique_id}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoDataFound />
            )}
          </div>
        </div> */}
        <div className="trade-graph">
          <div className="tradingview-widget-container" ref={container}>
            {/* <div
              className="tradingview-widget-container__widget"
              style={{ height: "calc(100% - 32px)", width: "50%" }}
            ></div>
            <div className="tradingview-widget-copyright">
              <a
                href="https://www.tradingview.com/"
                rel="noopener nofollow"
                target="_blank"
              >
                <span className="blue-text">
                  Track all markets on TradingView
                </span>
              </a>
            </div> */}
          </div>
        </div>
        <div className="trade-stock-list">
          <StockTableMain />
        </div>
        <div className="stock-trading">
          <StockTrading />
        </div>
      </div>
    // </Container>
  );
};

export default TradingGraph;
