import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  cryptoWithdrawalRequestStart,
  getCurrenciesListStart,
  withdrawalRequestStart,
} from "../../store/actions/BuyAction";
import configuration, { set } from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
} from "../../store/actions/WalletAction";
import { useParams } from "react-router-dom";
import TransactionSuccess from "../helper/TransactionSuccess";
import ButtonStepper from "../Buy/ButtonStepper";
import Select from "react-select";
import { networkOption } from "../helper/NetworkOption";
import { getErrorNotificationMessage } from "../helper/ToastNotification";

const WithdrawCryptoModal = (props) => {
  const t = useTranslation("withdraw_modal");
  const dispatch = useDispatch();
  const params = useParams();
  const formRef = useRef();
  const cryptoWithdrawalRequest = useSelector((state) => state.buy.cryptoWithdrawalRequest);
  const [url, setUrl] = useState(null);
  const [skipRender, setSkipRender] = useState(true);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const currencies = useSelector((state) => state.buy.currencies);
  const [rangeValue, setRangeValue] = useState(0);
  const [buttonStepper, setButtonStepper] = useState(false);
  const [networkType, setNetworkType] = useState(null);
  const [minAmount, setMinAmount] = useState(0.00000001);
  const [maxAmount, setMaxAmount] = useState(1);
  const profile = useSelector((state) => state.user.profile);
  const [validDecimalValue, setValidDecimalValue] = useState();

  const validationSchema = Yup.object().shape({
    wallet_address: Yup.string().required(t("wallet_address.required")),
    network_type: Yup.string()
    .test("isNeed", t("required"), (value) => {
      if (props.currencyCode) {
        return !["USDT","USDC"].includes(props.currencyCode)||value?true:false;
      }
    }),
    amount: Yup.number()
      .required(t("amount.required"))
      .min(!isNaN(minAmount) ? minAmount?.toFixed(8) : 0, t("amount.invalid",{value:minAmount.toFixed(8)}))
      .max(maxAmount<parseFloat(props.show)?maxAmount:parseFloat(props.show).toFixed(8), t("amount.max_invalid",{value:maxAmount})),
  });

  const handleSubmit = (values) => {
    dispatch(
      cryptoWithdrawalRequestStart({
        ...values,
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !cryptoWithdrawalRequest.loading &&
      Object.keys(cryptoWithdrawalRequest.data).length > 0
    ) {
      setUrl(cryptoWithdrawalRequest.data.withdraw_transaction?.explorer_url);
      setButtonStepper(true);
      setTimeout(() => {
        setTransactionSuccess(true);
      }, 4000);
    }
    setSkipRender(false);
  }, [cryptoWithdrawalRequest]);

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
    dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: props.user_wallet_id,
      })
    );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#7d7d7d",
      fontSize: "0.8em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0 
    ) {
      setMinAmount(
        parseInt(
          currencies.data?.user_commissions?.min_withdraw_amount) <= 0
            ?0.00000001
            : parseInt(
              currencies.data?.user_commissions?.min_withdraw_amount
            ).toFixed(8)
        

      );
      setMaxAmount(
        parseInt(
          currencies.data?.user_commissions?.max_withdraw_amount) <= 0
            ?100000
            : parseInt(
              currencies.data?.user_commissions?.max_withdraw_amount
            ).toFixed(8)
        ) 
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    dispatch(getCurrenciesListStart({user_id:profile.data?.user_id?profile.data?.user_id:""}))
  }, []);

  useEffect(()=> {
    if(!networkType && formRef?.current){
      setNetworkType(networkOption[0]);
      formRef.current.setFieldValue("network_type", networkOption[0].value)
    }
  }, [networkOption]);
  
  useEffect(() => {
    if (
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      let decimal = (currencies.data.balance_steps.find(item => item.currency_code == props.currencyCode)?.balance_step);
      if(decimal){
        decimal = decimal.slice(decimal.indexOf('.')+1, decimal.indexOf('1')+1).length
        setValidDecimalValue(decimal)
      }
    }
    setSkipRender(false);
  }, [currencies, props.currencyCode]);

  const onAmountChange = (value, setFieldValue) => {
    let decimal = value.slice(value.indexOf('.')+1).length;
    if(value.includes('.')){
      if(decimal <= validDecimalValue){
        if (Number(value) == value) {
          setFieldValue("amount", value)
        }
      } else {
        getErrorNotificationMessage(`You can input upto ${validDecimalValue} decimal places.`)
      }
    } else {
      if (Number(value) == value) {
        setFieldValue("amount", value)
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="crypto_modal"
        backdrop="static"
      >
        <Modal.Body>
          {!transactionSuccess ? (
            <div className="crypto_modal_content">
              <Formik
                initialValues={{
                  wallet_address: "",
                  amount: "",
                  network_type:"",
                  token_type:props.currencyCode
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
              >
                {({ touched, errors, setFieldValue, values }) => (
                  <>
                    <div className="crypto_modal_titles out_space_mx_1_5">
                      <h4> {t("heading")}</h4>
                    </div>
                    <div className="crypto_modal_form">
                      <FORM>
                        <div className="crypto_amount_input out_space_mx_1_5">
                          <div className="crypto_amount_field margin-btm-sm">
                            <Field
                              type="number"
                              name="amount"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                const remainingOriginal = props.show || 0;
                                const percentage = (parseFloat(newValue) / remainingOriginal) * 100;
                                setRangeValue(isNaN(percentage) ? 0 : Math.min(percentage, 100).toFixed(2));
                                onAmountChange(newValue, setFieldValue)
                              }}
                              placeholder={`Enter the Amount in ${props.amount.slice(
                                -4
                              )}`}
                              className="form-control"
                              disabled={cryptoWithdrawalRequest.buttonDisable}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="amount"
                              className="text-danger text-center"
                            />
                          </div>
                          <Form.Group
                            controlId="exampleForm.ControlInput1"
                            className="trade-percent-range mt-2 mb-2"
                          >
                            <div className="range-label">
                              <span>{rangeValue}%</span>
                              <span>100%</span>
                            </div>
                            <Form.Range
                              disabled={!props.show || props.show <= 0 || cryptoWithdrawalRequest.buttonDisable}
                              min="0"
                              max="100"
                              value={rangeValue}
                              onChange={(e) => {
                                const data =
                                  (parseFloat(e.target.value) / 100) *
                                  props.show;
                                setRangeValue(e.target.value);
                                setFieldValue("amount", data.toFixed(8));
                              }}
                            />
                            <div className="trade-range-value">
                              {rangeValue} % / 100 %
                            </div>
                          </Form.Group>
                          <div className="crypto_amount-bls mt-2">
                            <h5>
                              Balance = <span> {props.amount}</span>
                            </h5>
                          </div>
                        </div>
                        {["USDT","USDC"].includes(props.currencyCode)&&
                        <div className="crypto_address_field">
                          <Form.Group className="mb-3">
                            <Form.Label>{t("network_type")} <span>*</span></Form.Label>
                            <Select
                              className="fillert-drop"
                              options={networkOption}
                              onChange={(select) => {
                                setFieldValue("network_type", select.value)
                                setNetworkType(select)
                              }}
                              value={networkType}
                              styles={customStyles}
                              placeholder="Select Network Type"
                              isDisabled={cryptoWithdrawalRequest.buttonDisable}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="network_type"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </div>
                        }
                        <div className="crypto_address_card out_space_mx_1_5">
                          <div className="crypto_address_field">
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {t("wallet_address.label")} <span>*</span>
                              </Form.Label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder={t("wallet_address.placeholder")}
                                name="wallet_address"
                                disabled={cryptoWithdrawalRequest.buttonDisable}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="wallet_address"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          </div>
                          <div className="commission-sec">
                            <div className="commission-box">
                              {(!isNaN(values.amount * (currencies.data?.user_commissions?.withdraw_commission /100)) &&  ((values.amount - values.amount * (currencies.data?.user_commissions?.withdraw_commission/ 100)).toFixed(8) > 0)) ? <div className="commission-card">
                                <h5>
                                  {t("service_fees", {
                                    value: props.currencyCode,
                                  })}
                                  :
                                </h5>
                                <h5>
                                    {!isNaN(
                                      values.amount *
                                        (currencies.data?.user_commissions?.withdraw_commission /
                                          100)
                                    )
                                      ? (
                                          values.amount *
                                          (currencies.data?.user_commissions?.withdraw_commission /
                                            100)
                                        ).toFixed(8)
                                      : "0.00"}
                                </h5>
                              </div> : null}
                              <div className="commission-card">
                                <h5>
                                  {t("user_receive_tokens", {
                                    value: props.currencyCode,
                                  })}:
                                </h5>
                                <h5>
                                    {!isNaN(
                                      values.amount -
                                        values.amount *
                                          (currencies.data?.user_commissions?.withdraw_commission/
                                            100)
                                    )
                                      ? (
                                          values.amount -
                                          values.amount *
                                            (currencies.data?.user_commissions?.withdraw_commission/
                                              100)
                                        ).toFixed(8)
                                      : "0.00"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        {cryptoWithdrawalRequest.buttonDisable ||
                        (Object.keys(cryptoWithdrawalRequest.data).length > 0 &&
                          buttonStepper) ? (
                          <div className="withdraw-stepper">
                            {" "}
                            <ButtonStepper
                              props={cryptoWithdrawalRequest}
                            />{" "}
                          </div>
                        ) : (
                          <div className="crypto_action item_flex_x_5">
                            <Button
                              className="action-btn overlay w-100"
                              onClick={props.onHide}
                              disabled={cryptoWithdrawalRequest.buttonDisable}
                            >
                              {t("cancel")}
                            </Button>
                            <Button
                              type="submit"
                              className="action-btn primary w-100"
                              disabled={cryptoWithdrawalRequest.buttonDisable}
                            >
                              {t("withdraw")}
                            </Button>
                          </div>
                        )}
                      </FORM>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          ) : (
            <TransactionSuccess
              message={
                cryptoWithdrawalRequest.data?.user_withdrawal?.status == 1
                  ? "Withdraw Completed Successfully"
                  : cryptoWithdrawalRequest.data?.user_withdrawal?.status ==
                      2 && "Withdraw Intiated Successfully"
              }
              onHide={onHide}
              url={url}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawCryptoModal;
