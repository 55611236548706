import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav } from "react-bootstrap";
import { swapTokensViewStart } from "../../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import "./transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";
import CustomLazyLoad from "../../helper/CustomLazyLoad";
import NoDataFound from "../../helper/NoDataFound";

const SwapTransactionViewModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const t = useTranslation("transactions");
  const swapTokensView = useSelector((state) => state.buy.swapTokensView);

  useEffect(() => {
    dispatch(
      swapTokensViewStart({
        swap_transaction_id: transactionId,
      })
    );
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" view-modal-transaction "
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Swap Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {swapTokensView.loading ? (
            <div className="transaction-view-body">
              <Skeleton height={210} borderRadius={15} />
              <div className="transaction-basic-tab-wrap out_space_mt_1">
                <Skeleton height={210} borderRadius={15} />
              </div>
            </div>
          ) : Object.keys(swapTokensView.data).length > 0 ? (
            <>
              <div className="transaction-view-body">
                <div className="transaction-basic-info-wrap">
                  <div className="transaction-basic-info-head">
                    <div className="transaction-basic-info-head-titles">
                      <h3>
                        {
                          swapTokensView.data.swap_transaction
                            .to_amount_formatted
                        }
                      </h3>
                      <p>Tokens Transferred</p>
                    </div>
                    {swapTokensView.data.swap_transaction.payment_type ==
                      "bank" && (
                      <div className="transaction-basic-info-head-titles">
                        <Button
                          onClick={() => setStep(2)}
                          className="action-btn  overlay"
                        >
                          {" "}
                          View Payment Proof{" "}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="transaction-space"></div>
                  <div className="transaction-basic-info-content">
                    <div className="transaction-basic-content-card">
                      <p>Amount Paid</p>
                      <h5>
                        {
                          swapTokensView.data.swap_transaction
                            .from_amount_formatted
                        }{" "}
                      </h5>
                    </div>
                    <div className="transaction-basic-content-card">
                      <p>Status</p>
                      {/* <div className={`pay-badge ${swapTokensView.data.swap_transaction.status ==
                                                1
                                                ? "succes"
                                                :swapTokensView.data.swap_transaction.status ==2? "denied":"pending"
                                                }`}>
                                                {
                                                    swapTokensView.data.swap_transaction
                                                        .status_formatted
                                                }
                                            </div> */}
                      <div
                        className={`table_badge  ${
                          swapTokensView.data.swap_transaction.status == 1
                            ? "success"
                            : swapTokensView.data.swap_transaction.status == 0
                            ? "pending"
                            : "fail"
                        }`}
                      >
                        {" "}
                        {swapTokensView.data.swap_transaction.status_formatted}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transaction-basic-tab-wrap out_space_mt_1">
                  <Tab.Container
                    id="transaction-basic-tab"
                    defaultActiveKey="transaction"
                  >
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="transaction"
                          className="view_tab_btn"
                        >
                          Transaction Details
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="transaction">
                        <div className="transaction-tab-frame">
                          <div className="transaction-tab-info-card">
                            <p>Exchange Value</p>
                            <h4>
                              {
                                swapTokensView.data.swap_transaction
                                  .adjusted_exchange_rate_formatted
                              }
                            </h4>
                          </div>
                          <div className="transaction-tab-info-card">
                            <p>Transaction Date</p>
                            <h4>
                              {
                                swapTokensView.data.swap_transaction
                                  .created_at_formatted
                              }{" "}
                            </h4>
                          </div>
                          <div className="transaction-tab-info-card">
                            <p>Transaction Id</p>
                            {swapTokensView.data.swap_transaction.order_id ? (
                              <>
                                <h4 className="copy-view-card">
                                  {" "}
                                  <span>
                                    {
                                      swapTokensView.data.swap_transaction
                                        .order_id
                                    }
                                  </span>{" "}
                                  <CopyToClipboard
                                    text={
                                      swapTokensView.data.swap_transaction
                                        .order_id
                                    }
                                    onCopy={() =>
                                      getSuccessNotificationMessage(
                                        t("payment_id_was_copied_to_clipboard")
                                      )
                                    }
                                  >
                                    <Button className="action-btn copy-btn item_center">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#298BFF"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                        <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                      </svg>
                                    </Button>
                                  </CopyToClipboard>
                                </h4>
                              </>
                            ) : (
                              "Transaction ref id not available"
                            )}
                          </div>
                          {swapTokensView.data.swap_transaction
                            .commission_rate ? (
                            <div className="transaction-tab-info-card">
                              <p>Commission (%)</p>
                              <h4>
                                {
                                  swapTokensView.data.swap_transaction
                                    .commission_rate
                                }
                              </h4>
                            </div>
                          ) : null}
                          {swapTokensView.data.swap_transaction.commission ? (
                            <div className="transaction-tab-info-card">
                              <p>Commission Rate</p>
                              <h4>
                                {
                                  swapTokensView.data.swap_transaction
                                    .commission
                                }
                              </h4>
                            </div>
                          ) : null}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SwapTransactionViewModal;
