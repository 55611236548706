import React from "react";
import { Image, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetSwapData } from "../store/actions/UserAction";
import { useTranslation } from "react-multi-lang";

const SwapFailure = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("exchange_index");

  return (
    <>
      <div className="buy-processing-img-sec">
        <Image
          src={window.location.origin + "/img/buy/payment-failure.gif"}
          className="buy-processing-img"
        />
      </div>
      <div className="payment-processing-countdown-card">
        <div className="payment-failure-text">
         {t("payment_failure")}
        </div>
      </div>
      <div className="exchange-btn-sec">
        <Button className="default-btn" onClick={() => dispatch(resetSwapData())}>
          {t("continue")}
        </Button>
      </div>
    </>
  );
};

export default SwapFailure;
