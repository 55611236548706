import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { useTranslation } from "react-multi-lang";
import useLogout from "../layouts/Header/useLogout";
import { logoutStart } from "../../store/actions/UserAction";
import { useSelector, useDispatch } from "react-redux";
import { ButtonLoader } from "../helper/Loader";
import { useNavigate } from "react-router-dom";

const LogoutModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("profile");
  const logout = useSelector((state) => state.user.logout);
  const [skipRender, setSkipRender] = useState(true);
  const onSubmit = () => {
    dispatch(logoutStart());
  };
  useEffect(() => {
    if (!skipRender && !logout.loading && Object.keys(logout.data).length > 0) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("lang", "en");
      navigate("/login");
    }
    setSkipRender(false);
  }, [logout]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="logout-icons">
          <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 24 24"
    >
      <g fill="#f9c201">
        <g fillRule="evenodd">
          <path
            d="M11.112.815a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm8.54 11.275c-.486-.55-1.2-.9-1.993-.9H15.75a2.658 2.658 0 00-2.658 2.659v6.678a2.658 2.658 0 002.658 2.658h1.908c.794 0 1.507-.35 1.993-.9a.75.75 0 00-1.124-.992 1.153 1.153 0 01-.87.392H15.75c-.64 0-1.158-.519-1.158-1.158v-6.678c0-.64.518-1.159 1.158-1.159h1.908c.346 0 .655.151.869.393a.75.75 0 001.124-.993z"
            data-original="#000000"
          ></path>
          <path
            d="M22.497 16.657a.75.75 0 010 1.06l-2 2a.75.75 0 01-1.061-1.06l2-2a.75.75 0 011.06 0z"
            data-original="#000000"
          ></path>
          <path
            d="M22.497 17.718a.75.75 0 000-1.06l-2-2a.75.75 0 00-1.061 1.06l2 2a.75.75 0 001.06 0z"
            data-original="#000000"
          ></path>
          <path
            d="M15.716 17.188a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75z"
            data-original="#000000"
          ></path>
        </g>
        <path
          d="M11.608 13.49c-.01.119-.015.238-.015.359v6.678c0 .746.196 1.445.54 2.05h-9.11a1.75 1.75 0 01-1.75-1.75v-3.671c0-.613.338-1.176.88-1.464a18.895 18.895 0 019.455-2.201z"
          data-original="#000000"
        ></path>
      </g>
    </svg>
          </div>
          <h4> {t("are_you_sure_you_want_to_loout")}</h4>
          <div className="new-action-frame layout-element">
            <Button className="new-overlay-btn" onClick={props.onHide}>
              {t("cancel")}
            </Button>
            <Button className="new-primary-btn" onClick={onSubmit}>
              {logout.buttonDisable ? <ButtonLoader /> : t("logout")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutModal;
