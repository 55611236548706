import React, { useEffect, useState, useRef } from "react";
import {
  Image,
  Offcanvas,
  Button,
  InputGroup,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import StockList from "./StockList";
import { useDispatch, useSelector } from "react-redux";
import {
  currencyPairStart,
  getCurrenciesListStart,
  getCurrencyPairListStart,
} from "../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./StockTrading/Arrow";
import "react-horizontal-scrolling-menu/dist/styles.css";
import NoPairData from "../helper/NoPairData";
import { useTranslation } from "react-multi-lang";

const TradeNav = () => {
  const t = useTranslation("trade");
  const dispatch = useDispatch();
  const currencyPairList = useSelector((state) => state.buy.currencyPairList);
  const currencyPairs = useSelector((state) => state.buy.currencyPair);
  const currencies = useSelector((state) => state.buy.currencies);
  const [show, setShow] = useState(false);
  const [currencyPair, setCurrencyPair] = useState([]);
  const [search, setSearch] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeCurrency, setActiveCurrency] = useState("")
  const profile = useSelector((state) => state.user.profile);

  const handleSubmit = (search) => {
    const searchValue = search.toLowerCase();
    if (searchValue == "") handleClear();
    else {
      const filteredPairs = currencyPairList.data.currency_pairs.filter(
        (pair) => {
          return (
            pair.currency1.toLowerCase().includes(searchValue) ||
            pair.currency2.toLowerCase().includes(searchValue) ||
            pair.unique_id.toLowerCase().includes(searchValue)
          );
        }
      );
      setCurrencyPair(filteredPairs);
    }
  };
  const handleClear = () => {
    setSearch("");
    setCurrencyPair(currencyPairList.data.currency_pairs);
  };

  useEffect(() => {
    if (
      !currencyPairList.loading &&
      Object.keys(currencyPairList.data).length > 0 &&
      Object.keys(currencyPairList.data.currency_pairs).length > 0
    ) {
      setCurrencyPair(currencyPairList.data.currency_pairs);
      setActiveCurrency(currencyPairList.data.currency_pairs[0])
    }
  }, [currencyPairList]);

  useEffect(() => {
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }))
  }, []);

  function onWheel(apiObj, ev) {
    const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isTouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  return (
    <>
      <div className="trading-header">
        <div className="trade-header-detail">
          <Link to="#" onClick={handleShow}>
            <div className="trade-header-heading grid-from">
              <div className="trade-header-icons">
                <Image
                  className="bitcoin-icon"
                  src={
                    currencyPairs?.image && currencyPairs?.image !== ""
                      ? currencyPairs.image
                      : window.location.origin + "/img/trading/crypto.png"
                  }
                  type="image/png"
                />
              </div>
              <div className="trading-head-info">
                <div className="trading-titles font-8_5 font-bold-600 line_block">
                  {currencyPairs?.unique_id}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#131615"
                    d="M15.4 9.88l-4.59-4.59a1 1 0 00-1.41 0 1 1 0 000 1.42l4.6 4.58a1 1 0 010 1.42l-4.6 4.58a1 1 0 001.41 1.42l4.59-4.59a3 3 0 000-4.24z"
                    data-original="#000000"
                  ></path>
                </svg>
              </div>
            </div>
          </Link>
          {/* <div className="horizontal-slider-cuurnecy-pair">  */}
          {/* </div>      */}
        </div>
        <div className="trading-scroll-bar-sec">
          <ScrollMenu
            // Header={<div>HEADER</div>}
            // Footer={<div>FOOTER</div>}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
          >
            {Object.keys(currencyPairList.data).length > 0
              ? currencyPairList.data.currency_pairs.map((currency, index) => (
                <div
                  className="trade-fav-stock-card"
                  onClick={() => {
                    setActiveCurrency(currency)
                    dispatch(currencyPairStart(currency));
                  }}
                >
                  <div className={`${activeCurrency == currency ? "trade-header-stock-card active" : "trade-header-stock-card"}`}>
                    <div className="stock-card-name grid-from_2">
                      <div className="stock-card-avater">
                        {/* <div className="fav-stock-check">
                            <input class="form-check-input" type="checkbox" value="" id={`fav_${index + 1}`} />
                            <div className="points-star" for={`fav_${index + 1}`}>
                            </div>
                          </div> */}
                        <Image
                          className="stock-icon"
                          src={
                            currency.image && currency.image !== ""
                              ? currency.image
                              : window.location.origin +
                              "/img/trading/crypto.png"
                          }
                        />
                      </div>
                      <div className="stock-card-info">
                        <div className="stock-card-crypto font-8 font-bold-500">
                          {currency.unique_id}
                        </div>
                        {/* <div className="stock-card-crypto-coin font-8 font-bold-500 font-gray">
                      Bitcoin
                    </div> */}
                      </div>
                    </div>
                    {/* <div className="stock-card-price">
                  <div className="stock-card-crypto-amount font-8 font-bold-500">
                  
                  </div>
                </div> */}
                    {/* <div className="stock-card-status">
                  <div className="profit font-8 font-bold-500">-0.876%</div>
                </div> */}
                  </div>
                </div> // NOTE: itemId is required for track items
              ))
              : null}
          </ScrollMenu>
        </div>
      </div>
      <Offcanvas
        className="trading-header-filter"
        show={show}
        onHide={handleClose}
        start
      >
        <Offcanvas.Header closeButton>
          {((Object.keys(currencyPairList.data).length > 0 &&
            Object.keys(currencyPairList.data.currency_pairs).length > 0) ||
            search) && (
              <div className="stock-search">
                <Form>
                  <InputGroup>
                    <Form.Control
                      placeholder={t("search.placeholder")}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        e.target.value == ""
                          ? handleClear()
                          : handleSubmit(e.target.value);
                      }}
                      value={search}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(search);
                        }
                      }}
                    />
                    {search == "" ? (
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => {
                          handleSubmit(search);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#cccccc"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                          <path d="M21 21l-6 -6" />
                        </svg>
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => {
                          handleClear();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512.015 512.015"
                          fill="#171717"
                        >
                          <path
                            d="M298.594 256.011L503.183 51.422c11.776-11.776 11.776-30.81 0-42.586s-30.81-11.776-42.586 0L256.008 213.425 51.418 8.836C39.642-2.94 20.608-2.94 8.832 8.836s-11.776 30.81 0 42.586l204.589 204.589L8.832 460.6c-11.776 11.776-11.776 30.81 0 42.586a30.034 30.034 0 0021.293 8.824c7.71 0 15.42-2.952 21.293-8.824l204.589-204.589 204.589 204.589a30.034 30.034 0 0021.293 8.824c7.71 0 15.42-2.952 21.293-8.824 11.776-11.776 11.776-30.81 0-42.586L298.594 256.011z"
                            data-original="#000000"
                          ></path>
                        </svg>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form>
              </div>
            )}
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {/* <StockList currencyPair={currencyPair} onHide={handleClose} /> */}
          <>
            <div className="trade-header-stock-box">
              {currencyPairList.loading ? (
                <div className="trade-fav-stock-card">
                  {[...Array(15)].map(() => (
                    <div className="trade-header-stock-card">
                      <Skeleton height={40} width={360} />
                    </div>
                  ))}
                </div>
              ) : currencyPair.length > 0 ? (
                currencyPair.map((currency, index) => (
                  <div
                    className="trade-fav-stock-card"
                    onClick={() => {
                      handleClose()
                      setActiveCurrency(currency)
                      dispatch(currencyPairStart(currency));
                    }}
                  >
                    <div className={`${activeCurrency == currency ? "trade-header-stock-card active" : "trade-header-stock-card"}`}>
                      <div className="stock-card-name grid-from_2">
                        <div className="stock-card-avater">
                          {/* <div className="fav-stock-check">
                                      <input class="form-check-input" type="checkbox" value="" id={`fav_${index + 1}`} />
                                      <div className="points-star" for={`fav_${index + 1}`}>
                                      </div>
                                    </div> */}
                          <Image
                            className="stock-icon"
                            src={
                              currency.image && currency.image !== ""
                                ? currency.image
                                : window.location.origin +
                                "/img/trading/crypto.png"
                            }
                          />
                        </div>
                        <div className="stock-card-info">
                          <div className="stock-card-crypto font-8 font-bold-500">
                            {currency.unique_id}
                          </div>
                          {/* <div className="stock-card-crypto-coin font-8 font-bold-500 font-gray">
                                Bitcoin
                              </div> */}
                        </div>
                      </div>
                      {/* <div className="stock-card-price">
                            <div className="stock-card-crypto-amount font-8 font-bold-500">
                            
                            </div>
                          </div> */}
                      {/* <div className="stock-card-status">
                            <div className="profit font-8 font-bold-500">-0.876%</div>
                          </div> */}
                    </div>
                  </div> // NOTE: itemId is required for track items
                ))
              ) : (
                <NoPairData />
              )}
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TradeNav;
