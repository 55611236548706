import React from 'react'
import TradingHeader from './TradingHeader'
import TradingGraph from './TradingGraph'
// import NewLandingHeader from "../NewLanding/NewLandingHeader";
import "./trading.css"
import HeaderIndex from '../layouts/Header/HeaderIndex';

const TradingIndex = () => {
  return (
    <div className="trading-sec">
      <HeaderIndex/>
      <TradingHeader/>
      <div className="trading-box-wrapped trade-bg-sec">
        <TradingGraph/>
      </div>
    </div>
  )
}

export default TradingIndex