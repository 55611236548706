import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Button } from "react-bootstrap";
import "../SwapOld/Swap.css";
import "./eth.svg";
import "./binance.svg";
import "./solana.svg";
import "./tether.svg";
import ExchangeRateSec from "../Buy/ExchangeRateSec";
import ExchangeRate from "../Swap/ExchangeRate";
import { useSelector, useDispatch } from "react-redux";
import FAQIndex from "./FAQIndex";
import { updateBuyData } from '../../store/actions/UserAction';
import { Link, useNavigate } from 'react-router-dom';
import { initialBuyData } from '../../store/reducers/UserReducer';
import { useTranslation } from "react-multi-lang";
import { getCurrenciesListStart } from '../../store/actions/BuyAction';
import Skeleton from "react-loading-skeleton";

const LandingPageIndex = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("landing_page");

  const setBuyData = data => dispatch(updateBuyData(data));
  const currencies = useSelector(state => state.buy.currencies);

  const [tab, setTab] = useState("buy");
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    dispatch(getCurrenciesListStart({user_id:profile.data?.user_id?profile.data?.user_id:""}));
  }, []);

  const redirectBuy = (token_type) => {
    setBuyData({
      ...initialBuyData,
      token: 0,
      token_type: token_type,
    })
    navigate("/buy");
  }

  return (
    <>
      <div className="landing-sec">
        <Container fluid>
          <div className="vertical-center">
            <Row className="align-items-center">
              <Col md={12} xl={6} lg={12}>
                <div className="banner-content">
                  <h1>{t("lighting_cryptocurrency")} <span>{t("exchange")}</span></h1>
                  <p>{t("learn_about_cryptocurrency_para")}</p>
                  {currencies.loading ?
                    <div className="banner-link-sec">
                      <ul className="list-unstyled banner-link">
                        {[...Array(3)].map((i, key) => <Skeleton key={key} width={175} height={55} />)}
                      </ul>
                    </div>
                    :
                    Object.keys(currencies.data).length > 0 &&
                      currencies.data.crypto_currencies.length > 0
                      ?
                      <div className="banner-link-sec">
                        <ul className="list-unstyled banner-link">
                          {currencies.data.crypto_currencies.map((crypto) => (
                            <li>
                              <a onClick={() => redirectBuy(crypto.currency_code)} >
                                <Image
                                  src={crypto.picture}
                                  className="banner-exchange-icon"
                                />
                                <span>{t("buy")} {crypto.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      :
                      null
                  }
                </div>
              </Col>
              <Col md={12} xl={6} lg={12}>
                <div className="landing-page-right-sec">
                  <div className="landing-swap-box">
                    <Tab.Container id="left-tabs-example" activeKey={tab}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="buy" onClick={() => setTab("buy")}>{t("buy_crypto")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="swap" onClick={() => setTab("swap")}>{t("swap_crypto")}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div>
                  <div className="landing-swap-exchange-box">
                    <div className="landing-swap-exchange-card">
                      {tab === "buy" ?
                        <ExchangeRateSec redirect={true} />
                        : <ExchangeRate redirect={true} />
                      }
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div >
      <FAQIndex />
    </>
  );
};

export default LandingPageIndex;
