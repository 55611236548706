import {
    ADD_BANK_ACCOUNT_START,
    ADD_BANK_ACCOUNT_SUCCESS,
    ADD_BANK_ACCOUNT_FAILURE,
    GET_BANK_ACCOUNT_START,
    GET_BANK_ACCOUNT_SUCCESS,
    GET_BANK_ACCOUNT_FAILURE,
    DELETE_BANK_ACCOUNT_START,
    DELETE_BANK_ACCOUNT_SUCCESS,
    DELETE_BANK_ACCOUNT_FAILURE,
    MAKE_DEFAULT_BANK_ACCOUNT_START,
    MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS,
    MAKE_DEFAULT_BANK_ACCOUNT_FAILURE,
    VIEW_BANK_ACCOUNT_START,
    VIEW_BANK_ACCOUNT_SUCCESS,
    VIEW_BANK_ACCOUNT_FAILURE,
  } from "../actions/ActionConstant";
  
  const initialState = {
    addBankAccount: {
      data: {},
      loading: true,
      error: false,
      loadingButtonContent: null,
      buttonDisable: false,
    },
    bankAccount: {
      data: {},
      loading: true,
      error: false,
    },
    deleteAccount: {
      data: {},
      loading: true,
      error: false,
    },
    makeDefault: {
      data: {},
      loading: true,
      error: false,
    },
    viewAccount: {
      data: {},
      loading: true,
      error: false,
    },
  };
  
  const BankAccountReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_BANK_ACCOUNT_START:
        return {
          ...state,
          addBankAccount: {
            data: {},
            loading: true,
            error: false,
            loadingButtonContent: "Loading... Please wait",
            buttonDisable: true,
          },
        };
      case ADD_BANK_ACCOUNT_SUCCESS:
        return {
          ...state,
          addBankAccount: {
            data: action.data,
            loading: false,
            error: false,
            loadingButtonContent: null,
            buttonDisable: false,
          },
        };
      case ADD_BANK_ACCOUNT_FAILURE:
        return {
          ...state,
          addBankAccount: {
            data: {},
            loading: true,
            error: action.error,
            loadingButtonContent: null,
            buttonDisable: false,
          },
        };
      case GET_BANK_ACCOUNT_START:
        return {
          ...state,
          bankAccount: {
            data: {},
            loading: true,
            error: false,
          },
        };
      case GET_BANK_ACCOUNT_SUCCESS:
        return {
          ...state,
          bankAccount: {
            data: action.data,
            loading: false,
            error: false,
          },
        };
      case GET_BANK_ACCOUNT_FAILURE:
        return {
          ...state,
          bankAccount: {
            data: {},
            loading:true,
            error: action.error,
          },
        };
      case DELETE_BANK_ACCOUNT_START:
        return {
          ...state,
          deleteAccount: {
            data: {},
            loading: true,
            error: false,
          },
        };
      case DELETE_BANK_ACCOUNT_SUCCESS:
        return {
          ...state,
          deleteAccount: {
            data: action.data,
            loading: false,
            error: false,
          },
        };
      case DELETE_BANK_ACCOUNT_FAILURE:
        return {
          ...state,
          deleteAccount: {
            data: {},
            loading: false,
            error: action.error,
          },
        };
      case MAKE_DEFAULT_BANK_ACCOUNT_START:
        return {
          ...state,
          makeDefault: {
            data: {},
            loading: true,
            error: false,
          },
        };
      case MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS:
        return {
          ...state,
          makeDefault: {
            data: action.data,
            loading: false,
            error: false,
          },
        };
      case MAKE_DEFAULT_BANK_ACCOUNT_FAILURE:
        return {
          ...state,
          makeDefault: {
            data: {},
            loading: false,
            error: action.error,
          },
        };
      case VIEW_BANK_ACCOUNT_START:
        return {
          ...state,
          viewAccount: {
            data: {},
            loading: true,
            error: false,
  
  
          },
        };
      case VIEW_BANK_ACCOUNT_SUCCESS:
        return {
          ...state,
          viewAccount: {
            data: action.data,
            loading: false,
            error: false,
  
          },
        };
      case VIEW_BANK_ACCOUNT_FAILURE:
        return {
          ...state,
          viewAccount: {
            data: {},
            loading: false,
            error: action.error,
          },
        };
      default:
        return state;
    }
  };
  export default BankAccountReducer;
  